import { QuickParticipation } from '@assembly-web/ui';

import { usePointsConfig } from '../../hooks/usePointsConfig';
import { CoreValue } from './CoreValue';
import { EditBadge } from './Editbadge';
import { OpenEnded } from './OpenEnded';
import { PersonSelector } from './PersonSelector';
import { PointsSelectorBlock } from './PointsSelectorBlock';

function PointsBlock() {
  const { showPointsBlock } = usePointsConfig();
  return showPointsBlock && <PointsSelectorBlock />;
}

export function Blocks() {
  return (
    <QuickParticipation.BlockList>
      <EditBadge />
      <PersonSelector />
      <PointsBlock />
      <CoreValue />
      <OpenEnded />
    </QuickParticipation.BlockList>
  );
}
