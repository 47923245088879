import { botImage, defaultAwardImage } from '@assembly-web/assets';
import type {
  FileDownloadRequest,
  FlowPostResponse,
  GlobalFilterOption,
  MemberDetails,
  OpenEndedBlockResponseDetails,
  ReactionDetails,
  SearchPayload,
  UserActivitySortType,
} from '@assembly-web/services';
import {
  BlockResponseState,
  BlockTypes,
  config,
  copyToClipboard,
  LegacyPostTypes,
  useRecognitionRoute,
  useUserDetails,
} from '@assembly-web/services';
import type {
  AnnouncementBannerProps,
  PostCardToolbarProps,
} from '@assembly-web/ui';
import {
  FlowPost,
  getRespondentForAnonymousFlowPost,
  PostCard,
  PostCardActions,
  PostCardUI,
  useAssemblyNavigate,
  useToastStore,
} from '@assembly-web/ui';
import { useQuery } from '@tanstack/react-query';
import {
  type MouseEvent,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
} from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { useContentTranslation } from '../hooks/useContentTranslation';
import { useIsRecognitionOnlyCustomer } from '../hooks/useIsRecognitionOnlyCustomer';
import { useNavigateToUserFeed } from '../hooks/useNavigateToUserFeed';
import { useOpenFlowsPreviewer } from '../hooks/useOpenFlowsPreviewer';
import { useReactionMutation } from '../hooks/useReactionMutation';
import { useReplies } from '../hooks/useReplies';
import { AnnouncementsContext } from '../modules/discover/hooks/useAnnouncements';
import { useDismissImportantActivityMutationQuery } from '../modules/discover/hooks/useDismissImportantActivityMutationQuery';
import type { GetUserFeedPayload } from '../modules/discover/queries/getUserFeedQuery';
import {
  getSortMethodValue,
  trackCardAction,
} from '../modules/discover/services/analytics';
import {
  getPostQuery,
  getPostRepliesQuery,
} from '../queries/getAssemblyPostQuery';
import { downloadFeedFile } from '../services/file';
import { getURLForAssemblyPostActions } from '../services/post';
import type { PostData } from '../types/postsAndReplies';
import { ContentTranslationButton } from './ContentTranslationButton';
import { PostCardLoader } from './PostCardLoader';

const messages = defineMessages({
  copiedToClipboardText: {
    defaultMessage: 'File Link Copied to clipboard',
    id: 'Hj9In1',
  },
  downloadingFileText: {
    defaultMessage: 'Downloading file',
    id: '2vpW3T',
  },
  fileDownloadedText: {
    defaultMessage: 'File downloaded successfully',
    id: 'griv1/',
  },
  errorDownloadingFileText: {
    defaultMessage: 'Error while downloading the file',
    id: 'hDCJX3',
  },
  translateThisPost: {
    defaultMessage: 'Translate this post',
    id: 'KPjAju',
  },
  celebrationBot: {
    defaultMessage: 'Celebration Bot',
    id: 'imtvA0',
  },
});

type RichPostPreviewProps = {
  analyticsProps?: {
    cardType: string;
    isNotification: boolean;
    searchTerm?: string;
    sortMethod?: UserActivitySortType;
    filter?: GlobalFilterOption;
  };
  flowId: string;
  isIFrameEnv?: boolean;
  responseId: string;
  urlPath: string;
  hasReplyDraft: boolean;
  postResponse?: FlowPostResponse;
  lazyLoadPostDetailsOnScroll: boolean;
  payload?: GetUserFeedPayload | SearchPayload;
  cardId?: string;
  className?: string;
  isReplyPreviewCard?: boolean;
  canHidePoints?: boolean;
  handleInternalLinkClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
} & PostCardToolbarProps;

export function RichPostPreview({
  analyticsProps,
  flowId,
  hasReplyDraft,
  isIFrameEnv = false,
  responseId,
  urlPath,
  lazyLoadPostDetailsOnScroll,
  onToolbarMenuItemClick,
  primaryToolbarItems,
  secondaryToolbarItems,
  cardId,
  className,
  isReplyPreviewCard,
  canHidePoints,
  handleInternalLinkClick,
  postResponse,
}: RichPostPreviewProps) {
  const isRecognitionRoute = useRecognitionRoute();
  const isRecognitionOnlyCustomer = useIsRecognitionOnlyCustomer();

  const { navigate: navigateToUserFeed } = useNavigateToUserFeed();
  const { formatMessage } = useIntl();
  const { showSuccessToast, showErrorToast, showInfoToast } = useToastStore();
  const { isPending: isUserDetailsLoading, data: userDetails } =
    useUserDetails();
  const { onReplyClicked } = useReplies();
  const { mutate: dismissCard } = useDismissImportantActivityMutationQuery();
  const openPreviewer = useOpenFlowsPreviewer();

  const { isInitialLoading: isPostCardDataLoading, data: postCardDetails } =
    useQuery({
      ...getPostQuery(
        {
          flowId,
          responseId,
          currencyName: userDetails?.assembly.currency.pluralName ?? '',
          asEmbed: true,
        },
        formatMessage
      ),
      enabled: lazyLoadPostDetailsOnScroll,
    });

  const currentUser = useMemo(() => {
    return userDetails
      ? ({
          memberID: userDetails.member.memberId,
          name: userDetails.member.profile.firstName,
          image: userDetails.member.profile.image,
          firstName: userDetails.member.profile.firstName,
          lastName: userDetails.member.profile.lastName,
          username: userDetails.member.profile.username,
          pointsGiven: userDetails.member.pointsGiven,
          totalPointsGiven: userDetails.member.totalPointsGiven,
          memberState: userDetails.member.memberState,
          role: userDetails.member.role,
          email: userDetails.member.email,
        } as MemberDetails)
      : undefined;
  }, [userDetails]);

  const {
    isLoading: isPostCardRepliesDataLoading,
    data: postCardRepliesDetails,
  } = useQuery(
    getPostRepliesQuery({
      flowId,
      responseId,
      enabled: lazyLoadPostDetailsOnScroll,
    })
  );

  const reactionMutate = useReactionMutation({
    flowId,
    responseId,
    currentUser,
  });

  const assemblyPostCardDetails = lazyLoadPostDetailsOnScroll
    ? postCardDetails
    : postResponse;

  const replySummaryDetails = lazyLoadPostDetailsOnScroll
    ? postCardRepliesDetails
    : postResponse?.replySummary;

  const originalFlowResponse: FlowPostResponse | undefined = useMemo(() => {
    return assemblyPostCardDetails
      ? {
          ...assemblyPostCardDetails,
          respondent:
            assemblyPostCardDetails.respondent ??
            getRespondentForAnonymousFlowPost(),
          instanceId:
            assemblyPostCardDetails.respondent === null
              ? ''
              : assemblyPostCardDetails.instanceId,
        }
      : undefined;
  }, [assemblyPostCardDetails]);

  const translationPayload = { flowId, responseId };

  const {
    getContent,
    showTranslatedContent,
    showOriginalContent,
    isTranslationInProgress,
    isShowingOriginalContent,
  } = useContentTranslation<NonNullable<typeof originalFlowResponse>>({
    contents: originalFlowResponse ? [originalFlowResponse] : [],
  });

  const flowResponse = getContent(translationPayload);
  const isShowingOriginalContentValue = flowResponse
    ? isShowingOriginalContent(translationPayload)
    : false;

  const navigate = useAssemblyNavigate();
  const slug = `${userDetails?.assembly.workspaceSlug.name}-${userDetails?.assembly.workspaceSlug.shortCode}`;

  const handleAssemblyPostRedirection = useCallback(
    ({ action, id }: { action: PostCardActions; id: string }) => {
      const redirectionURL = getURLForAssemblyPostActions({
        action,
        slug,
        id,
        flowId: flowResponse?.flow.flowId,
        responseId: flowResponse?.responseId,
      });
      if (redirectionURL) navigate(redirectionURL);
    },
    [flowResponse, navigate, slug]
  );

  const { openAnnouncementsInsightsModal } = useContext(AnnouncementsContext);

  useLayoutEffect(() => {
    if (
      isIFrameEnv &&
      flowResponse &&
      currentUser &&
      assemblyPostCardDetails &&
      !isUserDetailsLoading &&
      !isPostCardRepliesDataLoading
    ) {
      const element = document.getElementById(`${flowId}-${responseId}`);
      if (element) {
        window.parent.postMessage(
          {
            type: 'iframeHeight',
            height: element.clientHeight,
            urlPath,
          },
          '*'
        );
      }
    }
  }, [
    flowResponse,
    currentUser,
    postCardDetails,
    flowId,
    responseId,
    isUserDetailsLoading,
    isPostCardDataLoading,
    isPostCardRepliesDataLoading,
    urlPath,
    isIFrameEnv,
    assemblyPostCardDetails,
    lazyLoadPostDetailsOnScroll,
  ]);

  if (
    isUserDetailsLoading ||
    !flowResponse ||
    (isPostCardDataLoading && lazyLoadPostDetailsOnScroll) ||
    (isPostCardRepliesDataLoading && lazyLoadPostDetailsOnScroll) ||
    !userDetails?.assembly.currency ||
    !replySummaryDetails ||
    !currentUser
  ) {
    return (
      <section>
        <PostCardLoader />
      </section>
    );
  }

  const flowName = flowResponse.flow.name;

  const trackPostPreviewCardClick = (
    action:
      | 'postReactionAdded'
      | 'postReactionRemoved'
      | 'viewRepliesClicked'
      | 'memberClicked'
      | 'fileClicked'
  ) => {
    if (analyticsProps) {
      const { cardType, isNotification, searchTerm, sortMethod } =
        analyticsProps;

      trackCardAction(action, {
        cardType,
        flowId: flowId !== 'recognition' ? flowId : null,
        flowName,
        isNotification,
        searchQuery: searchTerm,
        isAnnouncement: Boolean(flowResponse.activeAnnouncement),
        sortMethod: sortMethod ? getSortMethodValue(sortMethod) : null,
        isRepliesDisabled: postResponse?.hideReplies ?? false,
        isReactionsDisabled: postResponse?.hideReactions ?? false,
      });
    }
  };

  const handleFileClick = (responseBlock: {
    blockId: string;
    fileName: string;
    fileType: string;
    location?: string;
  }) => {
    const { blockId, fileName, fileType, location } = responseBlock;

    openPreviewer({
      blockId,
      flowId,
      workspaceSlug: slug,
      fileName,
      responseId,
      fileType,
      memberID: flowResponse.respondent.memberID,
      memberName: flowResponse.respondent.name,
      memberImage: flowResponse.respondent.image,
      sharedIn: flowName,
      dateShared: flowResponse.createdAt,
      sharedInIcon: flowResponse.flow.icon,
      locationUrl: location,
    });

    trackPostPreviewCardClick('fileClicked');
  };

  const handleBlockToolbarItemClick = async (responseBlock: {
    blockId: string;
    fileName: string;
    itemId: string;
  }) => {
    const { blockId, itemId, fileName } = responseBlock;
    if (itemId === 'copyLink') {
      const baseUrl = config.domains.app;
      const urlToCopy = `${baseUrl}/a/${slug}/post/flow/flows?flowId=${flowId}&responseId=${responseId}`;
      await copyToClipboard(urlToCopy);
      showSuccessToast(formatMessage(messages.copiedToClipboardText));
    }
    if (itemId === 'downloadFile') {
      const request: FileDownloadRequest = {
        blockId: blockId,
        flowId: flowId,
        responseId: responseId,
        instanceId: flowResponse.instanceId,
        fileName: fileName,
      };
      try {
        showInfoToast(formatMessage(messages.downloadingFileText));
        await downloadFeedFile(request);
        showSuccessToast(formatMessage(messages.fileDownloadedText));
      } catch (e) {
        showErrorToast(formatMessage(messages.errorDownloadingFileText));
      }
    }
  };
  const handlePostCardClick = (action: PostCardActions, id: string) => {
    if (action === PostCardActions.PersonClicked) {
      trackPostPreviewCardClick('memberClicked');
    }

    handleAssemblyPostRedirection({ action, id });
  };

  const handleOnAnnouncementDescriptionHover = (
    args: AnnouncementBannerProps
  ) => {
    trackCardAction('descriptionOpened', {
      title: args.title,
      color: args.color,
      description: args.description,
    });
  };

  const handleReactionClick = (emoji: ReactionDetails) => {
    if (responseId !== '' && flowId !== '') {
      const hasCurrentUserReactedToTheSelectedEmoji =
        flowResponse.reactions.some(
          (reaction) =>
            reaction.name === emoji.name &&
            reaction.members.some(
              (member) => member.memberID === userDetails.member.memberId
            )
        );
      reactionMutate({
        payload: {
          displayName: emoji.displayName,
          name: emoji.name,
          type: emoji.type,
          value: emoji.type,
        },
        action: hasCurrentUserReactedToTheSelectedEmoji ? 'unset' : 'set',
      });

      trackPostPreviewCardClick(
        hasCurrentUserReactedToTheSelectedEmoji
          ? 'postReactionRemoved'
          : 'postReactionAdded'
      );
    }
  };

  const handleReplyButtonClick = () => {
    trackPostPreviewCardClick('viewRepliesClicked');
    if (cardId) {
      dismissCard(cardId);
    }

    const viewReplyPayload: PostData =
      flowId === 'recognition'
        ? {
            type: 'post',
            postId: responseId,
            commentId: 'viewLastReply',
            postType: flowResponse.postType,
          }
        : {
            type: 'flow',
            flowId,
            responseId,
            commentId: 'viewLastReply',
          };
    onReplyClicked(viewReplyPayload);
  };

  const responseBlock = (() => {
    const response = flowResponse.responses.find(
      (block) => block.type === BlockTypes.OpenEnded
    ) as OpenEndedBlockResponseDetails | undefined;
    if (response?.state === BlockResponseState.Skipped) {
      return undefined;
    }
    return response;
  })();

  const commonProps = {
    bodyProps: {
      onMemberClick: navigateToUserFeed,
      response: responseBlock?.response,
      handleInternalLinkClick,
      blockId: responseBlock?.blockId ?? '',
      onFileClick: handleFileClick,
      onToolbarItemClick: handleBlockToolbarItemClick,
    },
    headerProps: {
      createdAt: flowResponse.createdAt,
      flowIcon: flowResponse.flow.icon.value,
      flowId: flowResponse.flow.flowId,
      flowName: flowResponse.flow.name,
      isEdited: flowResponse.edited ?? false,
      onPostCardHeaderClick: handlePostCardClick,
      respondent: flowResponse.respondent,
      visibility: flowResponse.visibility,
      translationElement: (
        <ContentTranslationButton
          isTranslationInProgress={isTranslationInProgress}
          isShowingOriginalContent={isShowingOriginalContentValue}
          onClick={() => {
            isShowingOriginalContentValue
              ? showTranslatedContent(translationPayload)
              : showOriginalContent(translationPayload);
          }}
        />
      ),
    },
    metaProps: {
      coreValue: flowResponse.coreValue,
      hiddenPoints: canHidePoints,
      onPostCardHeaderClick: handlePostCardClick,
      recipients: (() => {
        const recipients = flowResponse.responses.find(
          (block) => block.type === BlockTypes.PersonSelector
        );
        if (!recipients || recipients.state === BlockResponseState.Skipped) {
          return undefined;
        }
        return 'recipients' in recipients.response
          ? recipients.response.recipients
          : [];
      })(),
    },
    reactionProps: {
      reactions: flowResponse.reactions,
      onReactionClick: handleReactionClick,
    },
    repliesProps: {
      repliesData: replySummaryDetails,
      onReplyButtonClick: handleReplyButtonClick,
      showDraft: lazyLoadPostDetailsOnScroll
        ? (postCardDetails?.hasReplyDraft ?? false)
        : hasReplyDraft,
    },
    toolbarProps: {
      primaryToolbarItems,
      secondaryToolbarItems,
      onToolbarMenuItemClick,
    },
    currentMember: currentUser,
    currency: userDetails.assembly.currency,
  };

  if (flowResponse.postType === LegacyPostTypes.Award) {
    return (
      <div id={`${flowId}-${responseId}`}>
        <PostCard
          postType={flowResponse.postType}
          awardBanner={{
            title: flowResponse.award?.name ?? '',
            description: flowResponse.award?.description,
            bannerImage: {
              src: flowResponse.award?.image?.location ?? defaultAwardImage,
              alt: '',
            },
          }}
          {...commonProps}
          metaProps={{
            ...commonProps.metaProps,
            points: flowResponse.points,
          }}
        />
      </div>
    );
  }

  if (flowResponse.postType === LegacyPostTypes.Recognition) {
    return (
      <div id={`${flowId}-${responseId}`}>
        <PostCard
          postType={flowResponse.postType}
          {...commonProps}
          metaProps={{
            ...commonProps.metaProps,
            coreValue: flowResponse.responses.find(
              (x) => x.type === BlockTypes.Dropdown
            )?.response?.value[0].value,
            points: flowResponse.responses.find(
              (x) => x.type === BlockTypes.GivePointsStack
            )?.response?.value,
          }}
        />
      </div>
    );
  }

  if (
    flowResponse.postType === LegacyPostTypes.Birthday ||
    flowResponse.postType === LegacyPostTypes.Anniversary ||
    flowResponse.postType === LegacyPostTypes.Welcome
  ) {
    return (
      <div id={`${flowId}-${responseId}`}>
        <PostCard
          postType={flowResponse.postType}
          {...commonProps}
          headerProps={{
            ...commonProps.headerProps,
            respondent: {
              ...flowResponse.respondent,
              firstName: formatMessage(messages.celebrationBot),
              name: formatMessage(messages.celebrationBot),
              image: botImage,
            },
          }}
          metaProps={{
            ...commonProps.metaProps,
            points: flowResponse.responses.find(
              (x) => x.type === BlockTypes.GivePointsStack
            )?.response?.value,
          }}
        />
      </div>
    );
  }

  if (!isReplyPreviewCard) {
    return (
      <div id={`${flowId}-${responseId}`}>
        <FlowPost
          {...commonProps}
          headerProps={{
            ...commonProps.headerProps,
            showFlowDetails: true,
          }}
          bodyProps={{
            flowResponse,
            onFileClick: handleFileClick,
            onMemberClick: navigateToUserFeed,
            onPostCardBodyClick: handlePostCardClick,
            canHidePoints,
            handleInternalLinkClick,
            onBlockToolbarItemClick: handleBlockToolbarItemClick,
          }}
        />
      </div>
    );
  }

  return (
    <div id={`${flowId}-${responseId}`}>
      <PostCardUI
        translationElement={
          <ContentTranslationButton
            isTranslationInProgress={isTranslationInProgress}
            isShowingOriginalContent={isShowingOriginalContentValue}
            onClick={() => {
              isShowingOriginalContentValue
                ? showTranslatedContent(translationPayload)
                : showOriginalContent(translationPayload);
            }}
          />
        }
        showFlowDetails={!isRecognitionRoute && !isRecognitionOnlyCustomer}
        additionalClassName={className}
        hasReplyDraft={
          lazyLoadPostDetailsOnScroll
            ? (postCardDetails?.hasReplyDraft ?? false)
            : hasReplyDraft
        }
        isReplyPreviewCard={isReplyPreviewCard}
        onMemberClick={navigateToUserFeed}
        repliesData={replySummaryDetails}
        flowResponse={flowResponse}
        reactions={flowResponse.reactions}
        currentMember={currentUser}
        currency={userDetails.assembly.currency}
        canShowAnnouncements={Boolean(flowResponse.activeAnnouncement)}
        onAnnouncementDescriptionHover={handleOnAnnouncementDescriptionHover}
        openAnnouncementsInsightsModal={openAnnouncementsInsightsModal}
        onPostCardHeaderClick={handlePostCardClick}
        onPostCardBodyClick={handlePostCardClick}
        onFileClick={handleFileClick}
        onBlockToolbarItemClick={handleBlockToolbarItemClick}
        primaryToolbarItems={primaryToolbarItems}
        secondaryToolbarItems={secondaryToolbarItems}
        onToolbarMenuItemClick={onToolbarMenuItemClick}
        onReactionClick={handleReactionClick}
        onReplyButtonClick={handleReplyButtonClick}
        canHidePoints={canHidePoints}
        handleInternalLinkClick={handleInternalLinkClick}
      />
    </div>
  );
}
