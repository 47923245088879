import {
  usePusherChannel,
  usePusherEvent,
  usePusherEvents,
} from '@assembly-web/pusher';
import { useUserDetails } from '@assembly-web/services';
import { useQueryClient } from '@tanstack/react-query';

export function useAwards() {
  const queryClient = useQueryClient();
  const { data: userDetails } = useUserDetails();

  const assembly = usePusherChannel(
    `private-assembly-${userDetails?.assembly.assemblyId}`
  );

  usePusherEvents(
    assembly,
    ['AWARD_CREATED', 'AWARD_EDITED', 'AWARD_STATUS_UPDATE', 'AWARD_DELETED'],
    async () => {
      queryClient.invalidateQueries({
        queryKey: ['awardsCatalog'],
      });
      queryClient.invalidateQueries({
        queryKey: ['pendingAwardApproval'],
      });
    }
  );

  usePusherEvent(assembly, 'APPROVAL_STATE_UPDATED', async () => {
    queryClient.invalidateQueries({
      queryKey: ['pendingAwardApproval'],
    });
    queryClient.invalidateQueries({
      queryKey: ['awardsCatalog'],
    });
  });
}
