import { useCurrency, useDisplayCurrency } from '@assembly-web/services';
import { PointsBlock, PointsContainer } from '@assembly-web/ui';
import { useCallback, useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { defineMessages, useIntl } from 'react-intl';

import { useAwardDetail } from '../../Awards';
import { useTrackAction } from '../hooks/useTrackAction';

const labels = defineMessages({
  placeHolder: {
    id: 'SARX44',
    defaultMessage: 'Add up to {pointsToGive} per person',
  },
  tooltip: {
    defaultMessage:
      'Award value has been set by the award creator and can’t be edited.',
    id: 'SQHbH6',
  },
  noEditTooltip: {
    id: 'Foj9hm',
    defaultMessage: '{currencyName} can’t be edited.',
  },
});

export function PointsSelectorBlock() {
  const { formatMessage } = useIntl();
  const currencyIcon = useDisplayCurrency();
  const { pluralName: currencyName } = useCurrency();
  const { watch, getValues, setValue, control } = useFormContext();
  const { settings, awardPostId } = useAwardDetail();
  const unitPoints = settings?.budget?.unitPoints;
  const remainingPoints = settings?.budget?.remainingPoints;
  const maxPoints = settings?.budget?.maxPoints;
  const memberCount = watch('recipientsCount', 0);
  const { trackAwardAction } = useTrackAction();

  const isEditAwardPost = Boolean(awardPostId);
  const getMaxPointsPerHead = useCallback(() => {
    const eligiblePointsToGivePerHead = maxPoints;
    const accountBalance = remainingPoints || 0;
    let pointsToGive;
    if (
      eligiblePointsToGivePerHead &&
      accountBalance >= eligiblePointsToGivePerHead * memberCount
    ) {
      pointsToGive = eligiblePointsToGivePerHead;
    } else {
      pointsToGive = accountBalance / memberCount;
    }
    if (!memberCount) {
      return eligiblePointsToGivePerHead;
    }
    return Math.floor(pointsToGive);
  }, [memberCount, maxPoints, remainingPoints]);

  const pointsToGive = useMemo(() => {
    return getMaxPointsPerHead() ?? (settings?.budget?.totalPoints || 0);
  }, [getMaxPointsPerHead, settings]);

  const calculatePoints = useCallback(
    (value: number) => {
      const val = Math.round(Number(value));
      if (memberCount > 1) {
        if (val > pointsToGive) {
          const maxTotalPoints = pointsToGive * memberCount;
          if (maxTotalPoints === 0) {
            return Math.floor(val);
          }
          if (val > maxTotalPoints) {
            return Math.floor(maxTotalPoints / memberCount);
          } else {
            return Math.floor(pointsToGive);
          }
        } else {
          return Math.floor(val);
        }
      } else if (memberCount <= 1) {
        if (val > pointsToGive) {
          return pointsToGive;
        } else {
          return Math.floor(val);
        }
      }
    },
    [pointsToGive, memberCount]
  );

  useEffect(() => {
    const points = getValues('points');
    if (!unitPoints) {
      setValue('points', calculatePoints(points));
    }
  }, [calculatePoints, getValues, setValue, unitPoints]);

  return (
    <Controller
      control={control}
      name="points"
      render={({ field: { onChange } }) => (
        <PointsBlock
          placeholder={formatMessage(labels.placeHolder, {
            pointsToGive,
          })}
          disabled={Boolean(unitPoints) || isEditAwardPost}
          currencyIcon={currencyIcon}
          error={null}
          tooltipText={
            unitPoints
              ? formatMessage(labels.tooltip)
              : isEditAwardPost
                ? formatMessage(labels.noEditTooltip, {
                    currencyName,
                  })
                : ''
          }
          onValueChange={(value: string | number) => {
            if (typeof value !== 'number') {
              onChange(0);
              return;
            }
            if (Number(value) < 0) {
              onChange(0);
              return;
            }
            onChange(calculatePoints(value));
          }}
          value={watch('points', control._defaultValues.points)}
          isLocked={Boolean(unitPoints)}
          trackAction={() => {
            trackAwardAction('pointsSelected');
          }}
        />
      )}
    />
  );
}

export function PointsSelector() {
  const { pluralName: currencyName } = useCurrency();
  return (
    <PointsContainer currencyName={currencyName}>
      <PointsSelectorBlock />
    </PointsContainer>
  );
}
