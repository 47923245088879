import type { LegacyUserDetailsAPIResponse } from '@assembly-web/services';
import {
  APIEndpoints,
  assemblyAPI,
  config,
  getAPIErrorCode,
  MobileJWTTokenQueryParamKey,
  MobileRefreshTokenQueryParam,
  userAuthStore,
} from '@assembly-web/services';
import {
  Button,
  InlineError,
  Radio,
  RadioOption,
  RouterForm,
  TextField,
  validateForm,
} from '@assembly-web/ui';
import type { QueryClient } from '@tanstack/react-query';
import { defineMessages, useIntl } from 'react-intl';
import type { ActionFunctionArgs } from 'react-router-dom';
import { redirect, useActionData, useNavigation } from 'react-router-dom';
import { z } from 'zod';

import { getWorkspaceQueryOptions } from '../../../../queries/useWorkspace';
import {
  trackOnboardingAction,
  trackRegistrationAction,
  trackRegistrationError,
} from '../../../../services/analytics';
import {
  generateReCaptchaToken,
  GoogleReCaptchaActionTypes,
} from '../../../../services/recaptchaServices';
import type { ReactRouterActionResponse } from '../../../../types/libs';
import { OnboardingContainer } from '../../components/OnboardingContainer';
import { onBoardingState } from '../../stores/onboardingStore';

const ownerDetailsFormText = defineMessages({
  title: {
    defaultMessage: 'Tell us about yourself!',
    id: 'KGqb1B',
  },
  firstNameInputPlaceholder: {
    defaultMessage: 'Your first name',
    id: 'x5rks2',
  },
  lastNameInputPlaceholder: {
    defaultMessage: 'Your last name',
    id: 'FuI6qb',
  },
  jobTitlePlaceholder: {
    defaultMessage: 'What best describes your role?',
    id: 'yyYBke',
  },
  isManagerLabel: {
    defaultMessage: 'Do you manage people?',
    id: 'HaT7HP',
  },
  ownerManagesPeople: {
    defaultMessage: 'Yes, I do',
    id: 'mEaLUv',
  },
  ownerDoesNotManagePeople: {
    defaultMessage: 'No, I do not',
    id: 'c5l8dH',
  },
  submitButton: {
    defaultMessage: 'Done',
    id: 'JXdbo8',
  },
  nextButton: {
    defaultMessage: 'Next',
    id: '9+Ddtu',
  },
});

const ownerDetailsFormErrors = defineMessages({
  firstNameError: {
    defaultMessage:
      'The first name you enter must have at least 2 alphabetical characters',
    id: 'xMl/Jy',
  },
  nameError: {
    defaultMessage:
      'Please remove special symbols — names can use letters only',
    id: 'achJ6s',
  },
  unknown: {
    defaultMessage: 'Something went wrong, try again later.',
    id: 'itkPLC',
  },
});

export function OwnerDetailsRoute() {
  const { formatMessage } = useIntl();

  const actionData = useActionData() as ReactRouterActionResponse<
    typeof ownerDetailsAction
  >;
  const navigation = useNavigation();

  const isPageLoading = navigation.state === 'loading';
  const isFormSubmissionInProgress = navigation.state === 'submitting';

  return (
    <OnboardingContainer
      title={formatMessage(ownerDetailsFormText.title)}
      className="w-full max-w-[646px] md:w-[646px] md:px-0"
    >
      <RouterForm className="space-y-6">
        <div className="space-y-4">
          <div className="flex flex-col gap-4 md:flex-row">
            <div className="flex-1">
              <TextField
                name="firstName"
                autoFocus // eslint-disable-line jsx-a11y/no-autofocus
                label={formatMessage(
                  ownerDetailsFormText.firstNameInputPlaceholder
                )}
                hideLabel
                placeholder={formatMessage(
                  ownerDetailsFormText.firstNameInputPlaceholder
                )}
                isInvalid={Boolean(actionData?.errors?.firstName)}
                invalidText={formatMessage(
                  actionData?.errors?.firstName?.[0] ===
                    'firstNameSpecialCharacters'
                    ? ownerDetailsFormErrors.nameError
                    : ownerDetailsFormErrors.firstNameError
                )}
                required
              />
            </div>
            <div className="flex-1">
              <TextField
                name="lastName"
                label={formatMessage(
                  ownerDetailsFormText.lastNameInputPlaceholder
                )}
                hideLabel
                placeholder={formatMessage(
                  ownerDetailsFormText.lastNameInputPlaceholder
                )}
                isInvalid={Boolean(actionData?.errors?.lastName)}
                invalidText={formatMessage(ownerDetailsFormErrors.nameError)}
              />
            </div>
          </div>

          <Radio label={formatMessage(ownerDetailsFormText.isManagerLabel)}>
            <RadioOption value="yes" name="isManager">
              {formatMessage(ownerDetailsFormText.ownerManagesPeople)}
            </RadioOption>
            <RadioOption value="no" name="isManager">
              {formatMessage(ownerDetailsFormText.ownerDoesNotManagePeople)}
            </RadioOption>
          </Radio>

          {actionData?.error ? (
            <InlineError id="owner-details-error">
              {formatMessage(
                ownerDetailsFormErrors[
                  actionData.error as keyof typeof ownerDetailsFormErrors
                ]
              )}
            </InlineError>
          ) : null}
        </div>
        <Button
          type="submit"
          isFullWidth
          isLoading={isFormSubmissionInProgress}
          disabled={isFormSubmissionInProgress || isPageLoading}
        >
          {formatMessage(ownerDetailsFormText.submitButton)}
        </Button>
      </RouterForm>
    </OnboardingContainer>
  );
}

export async function ownerDetailsAction({ request }: ActionFunctionArgs) {
  const isValidName = (x: string) =>
    x.match(/[\p{Letter}\p{Mark}]+/gu)?.join('').length === x.length;

  const formSchema = z.object({
    firstName: z
      .string()
      .trim()
      .min(2)
      .refine(isValidName, 'firstNameSpecialCharacters'),

    lastName: z
      .string()
      .trim()
      .refine((x) => {
        if (x.length === 0) return true;
        return isValidName(x);
      }),

    isManager: z
      .string()
      .transform((x) => x.toLowerCase())
      .refine((x) => ['yes', 'no'].includes(x))
      .optional(),
  });

  try {
    const { firstName, lastName, isManager } = validateForm(
      formSchema,
      await request.formData()
    );

    const fullName = `${firstName} ${lastName}`.trim();

    const captchaTokenForCreateWorkspace = await generateReCaptchaToken(
      GoogleReCaptchaActionTypes.SignUp
    );

    const {
      data: { user, jwtToken, refreshToken },
    } = await assemblyAPI.post<LegacyUserDetailsAPIResponse>(
      APIEndpoints.createWorkspace,
      {
        assemblyName: 'Assembly',
        captchaToken: captchaTokenForCreateWorkspace,
      },
      { signal: request.signal }
    );
    userAuthStore.getState().setJwtToken(jwtToken);
    userAuthStore.getState().updateUserDetails(user);

    if (refreshToken) {
      userAuthStore.getState().setRefreshToken(refreshToken);
    }

    const captchaTokenForUpdateUserDetails = await generateReCaptchaToken(
      GoogleReCaptchaActionTypes.SignUp
    );

    await assemblyAPI.put(
      APIEndpoints.updateUserDetails,
      { fullName, captchaToken: captchaTokenForUpdateUserDetails },
      { signal: request.signal }
    );

    trackRegistrationAction('assemblyCreated', {
      email: user.email,
      companyFields: { companyName: 'Assembly', fullName: 'Assembly' },
    });

    trackRegistrationAction('assemblyMemberCreated', {
      email: user.email,
      profileDetails: { fullName },
      assemblyId: userAuthStore.getState().assemblyId,
      isManager,
      isNotManager: !isManager,
    });

    trackOnboardingAction('managesPeopleSelected', {
      managesPeople: isManager,
    });

    const getOnboardingUrl = () => {
      const url = new URL(`${config.domains.app}/onboarding/wait-for-account`);

      const params = new URLSearchParams(url.search);
      params.set('isManager', isManager === 'yes' ? 'yes' : 'no');

      if (userAuthStore.getState().isLoginViaMobileApp) {
        const refreshToken = userAuthStore.getState().refreshToken;

        params.set('isMobileApp', 'true');
        params.set(
          MobileJWTTokenQueryParamKey,
          userAuthStore.getState().jwtToken ?? ''
        );
        if (refreshToken) {
          params.set(MobileRefreshTokenQueryParam, refreshToken);
        }
      }

      url.search = params.toString();

      onBoardingState.getState().setRedirectUrl(url.toString());

      return url.toString();
    };

    const redirectionURL = getOnboardingUrl();

    return redirect(redirectionURL);
  } catch (error) {
    trackRegistrationError('assemblyMemberCreated', {
      email: userAuthStore.getState().userDetails?.email,
      assemblyId: userAuthStore.getState().assemblyId,
    });

    if (error instanceof z.ZodError) {
      return { errors: error.flatten().fieldErrors };
    }

    return { error: getAPIErrorCode(error) };
  }
}

export function ownerDetailsLoader(queryClient: QueryClient) {
  return async function ownerDetailsLoader() {
    try {
      await queryClient.fetchQuery(getWorkspaceQueryOptions());

      return null;
    } catch (error) {
      return redirect(`/create-account`);
    }
  };
}
