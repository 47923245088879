import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  archiveFlow: {
    defaultMessage: 'Archive flow',
    id: 'BHVXiQ',
  },
  archivedText: {
    defaultMessage: 'Archived',
    id: '0HT+Ib',
  },
  shareFolder: {
    defaultMessage: 'Share folder',
    id: 'DLFtIb',
  },
  announcementEndedToastLabel: {
    defaultMessage: 'Success! Announcement ended',
    id: 'Thq4YZ',
  },
  editAnnouncement: {
    defaultMessage: 'Edit announcement',
    id: 'piq7j8',
  },
  viewAnnouncementInsights: {
    defaultMessage: 'View insights',
    id: 'HXhsPF',
  },
  endAnnouncement: {
    defaultMessage: 'End announcement',
    id: 'bmZUYW',
  },
  addExternalLinkToFolder: {
    defaultMessage: 'Add a link to this folder',
    id: 'n/yn00',
  },
  moveFolderDown: {
    defaultMessage: 'Move folder down',
    id: 'afCErd',
  },
  moveFolderUp: {
    defaultMessage: 'Move folder up',
    id: 'Z1mspD',
  },
  moveItemDown: {
    defaultMessage: 'Move item down',
    id: 'UjPa5a',
  },
  moveItemUp: {
    defaultMessage: 'Move item up',
    id: '6ibWnb',
  },
  addToFolder: {
    defaultMessage: 'Add to a folder',
    id: 'e5R2/b',
  },
  viewFeed: {
    defaultMessage: 'View feed',
    id: 'zPji8t',
  },
  startPost: {
    defaultMessage: 'Start post',
    id: 'pCERTC',
  },
  viewPostInFlowLabel: {
    defaultMessage: `view posts`,
    id: '5ZvSh6',
  },
  postInFlowLabel: {
    defaultMessage: `post`,
    id: 'X6hX1L',
  },
  postAndViewInFlowLabel: {
    defaultMessage: `post and view posts`,
    id: 'Ljiq34',
  },
  requestAccessGrantedLabel: {
    defaultMessage: `You can now {permission} in {emoticon} {flowName}`,
    id: 'q1hxEC',
  },
  markAsSeen: {
    defaultMessage: 'Mark as seen',
    id: 'o/8JwO',
  },
  copiedToClipboard: {
    defaultMessage: 'Link successfully copied to clipboard',
    id: 'QHwY68',
  },
  viewThread: {
    defaultMessage: 'View thread',
    id: 'akrSOb',
  },
  shareLink: {
    defaultMessage: 'Share link',
    id: 'GQNYob',
  },
  copyLink: {
    defaultMessage: 'Copy link',
    id: 'lbr3Lq',
  },
  copyPostLink: {
    defaultMessage: 'Copy post link',
    id: '6ACX/h',
  },
  copyReplyLink: {
    defaultMessage: 'Copy reply link',
    id: 'DiL7pw',
  },
  editLink: {
    defaultMessage: 'Edit link',
    id: 'sq+2Tt',
  },
  unfollowThread: {
    defaultMessage: 'Unfollow thread',
    id: '47TVnL',
  },
  followThread: {
    defaultMessage: 'Follow thread',
    id: '9yP/+Y',
  },
  deleteFolder: {
    defaultMessage: 'Delete folder',
    id: 'M0iO6Z',
  },
  deletePost: {
    defaultMessage: 'Delete post',
    id: 'yPcmjR',
  },
  deleteReply: {
    defaultMessage: 'Delete reply',
    id: 'T59CCg',
  },
  editFolder: {
    defaultMessage: 'Edit folder',
    id: 'jIxY6Z',
  },
  editFlow: {
    defaultMessage: 'Edit flow',
    id: 'TS84tj',
  },
  editPost: {
    defaultMessage: 'Edit post',
    id: '1Vd442',
  },
  editReply: {
    defaultMessage: 'Edit reply',
    id: 'tNcHxJ',
  },
  shareAsAnnouncement: {
    defaultMessage: 'Share as an announcement',
    id: 'QufzdJ',
  },
  viewFlowInsights: {
    defaultMessage: 'View flow insights',
    id: 'J/KnIX',
  },
  goToNotebook: {
    defaultMessage: 'Go to Notebook',
    id: 'KI9d2W',
  },
  goToPost: {
    defaultMessage: 'See post and reply',
    id: 'W0ozo6',
  },
  viewProof: {
    defaultMessage: 'View challenge claim',
    id: 'erUW9r',
  },
  addReaction: {
    defaultMessage: 'React',
    id: 'uX6eKe',
  },
  manageInteractionSettings: {
    defaultMessage: 'Manage replies and reactions',
    id: '4CKaGM',
  },
  goToFlow: {
    defaultMessage: 'Go to flow',
    id: 'fy4Xrz',
  },
  unarchiveTask: {
    defaultMessage: 'Unarchive task',
    id: 'AXO+OS',
  },
  deleteTask: {
    defaultMessage: 'Delete task',
    id: 'c6LNcW',
  },
  markTaskAsComplete: {
    defaultMessage: 'Mark task as complete',
    id: 'lg6l6z',
  },
  markTaskAsInComplete: {
    defaultMessage: 'Mark task as incomplete',
    id: 'ha21pL',
  },
  archiveTask: {
    defaultMessage: 'Archive task',
    id: 'JUSaQ4',
  },
  openAllLinks: {
    defaultMessage: 'Open all links',
    id: 'aGlp6n',
  },
  viewPostLabel: {
    defaultMessage: 'View post',
    id: 'TDQNax',
  },
  outOfStock: {
    defaultMessage: 'Out of stock',
    id: 'WHgdsk',
  },
  assignedByTaskLabel: {
    defaultMessage: '{author} assigned this task to you',
    id: '/zBwzT',
  },
  taskUpdatedLabel: {
    defaultMessage: '{author} {action} this task',
    id: 'MI8bWP',
  },
  postByLabel: {
    defaultMessage:
      'Post by {author, select, self {you} other {{author}}} in {flow}',
    id: 'd/Wpk/',
  },
  birthDayPostLabel: {
    defaultMessage:
      'Birthday Post for {celebratedTeammate, select, self {you} other {{celebratedTeammate}}} in {flow}',
    id: 'y8Ogzt',
  },
  anniversaryDayPostLabel: {
    defaultMessage:
      'Anniversary Post for {celebratedTeammate, select, self {you} other {{celebratedTeammate}}} in {flow}',
    id: 'aIfTHC',
  },
  authorLabel: {
    defaultMessage: '{author}: {content}',
    id: '/1iwkO',
  },
  anniversariesCelebrationLabel: {
    defaultMessage: `{name, select,
      self {{
        count, plural, =0 {It's your Anniversary!} other {It's your and {count} others' Anniversary!}
      }}
      other {{
        count, plural, =0 {It's {name}'s Anniversary!} other {It's {name}'s and {count} others' Anniversary!}
      }}
    }`,
    id: 'gT637R',
  },
  directReportBirthdayCelebrationLabel: {
    defaultMessage: `It’s, your direct report {name}’s birthday!`,
    id: 'J41d8+',
  },
  directReportAnniversaryCelebrationLabel: {
    defaultMessage: `It’s, your direct report {name}’s work anniversary!`,
    id: '4ewHLB',
  },
  birthdayCelebrationLabel: {
    defaultMessage: `{name, select,
      self {{
        count, plural, =0 {It's your Birthday!} other {It's your and {count} others' Birthday!}
      }}
      other {{
        count, plural, =0 {It's {name}'s Birthday!} other {It's {name}'s and {count} others' Birthday!}
      }}
    }`,
    id: 'ITSL/I',
  },
  goToLink: {
    defaultMessage: 'Go to link',
    id: '+ve/6Q',
  },
  removeFromFolder: {
    defaultMessage: 'Remove from this folder',
    id: 'cpbHpw',
  },
  directReportLabel: {
    id: 'IvOlLq',
    defaultMessage: `{others, plural,
        =0 {Your direct report, {reporterName}, posted in {flowName}}
        =1 {{reporterName} and 1 other direct report posted in {flowName}}
        other {{reporterName} and {others} other direct reports posted in {flowName}}
    }`,
  },
  directReportRecognizedLabel: {
    id: '3/KbCl',
    defaultMessage: `{others, plural,
        =0 {Your direct report, {reporterName} was recognized{hideFlowName, select, true {} other { 🎉 Give Recognition}}}
        =1 {Your direct reports, {reporterName} and 1 other were recognized{hideFlowName, select, true {} other { 🎉 Give Recognition}}}
        other {Your direct reports, {reporterName} and {others} were recognized{hideFlowName, select, true {} other { 🎉 Give Recognition}}}
    }`,
  },
  replyActivityLabel: {
    defaultMessage: `{name, select,
      self {{
        count, plural,
          =0 {Reply by you on your post in {emoticon} {flow}}
          =1 {Replies by you and 1 other on your post in {flow}}
          other {Replies by you and {count} others on your post in {flow}}
      }}
      other {{
        count, plural,
          =0 {Reply by {name} on your post in {emoticon} {flow}}
          =1 {Replies by {name} and 1 other on your post in {flow}}
          other {Replies by {name} and {count} others on your post in {flow}}
      }}
    }`,
    id: 'cV0E05',
  },

  v3ReplyActivityLabel: {
    defaultMessage: `{name, select,
      self {{
        count, plural,
          =0 {New Reply by you on your post{hideFlowName, select, true {} other { in {emoticon} {flow}}}}
          =1 {New Replies by you and 1 other on your post{hideFlowName, select, true {} other { in {flow}}}}
          other {New Replies by you and {count} others on your post{hideFlowName, select, true {} other { in {flow}}}}
      }}
      other {{
        count, plural,
          =0 {{name} replied to your post{hideFlowName, select, true {} other { in {emoticon} {flow}}}}
          =1 {{name} and 1 other replied to your post{hideFlowName, select, true {} other { in {flow}}}}
          other {{name} and {count} others replied to your post{hideFlowName, select, true {} other { in {flow}}}}
      }}
    }`,
    id: '2LSa3/',
  },

  v3ChallengeReplyActivityLabel: {
    defaultMessage: `{name, select,
      self {{
        count, plural,
          =0 {New Reply by you on the post {challengeName}}
          =1 {New Replies by you and 1 other on the post {challengeName}}
          other {New Replies by you and {count} others on the post {challengeName}}
      }}
      other {{
        count, plural,
          =0 {{name} replied to the post {challengeName}}
          =1 {{name} and 1 other replied to the post {challengeName}}
          other {{name} and {count} others replied to the post {challengeName}}
      }}
    }`,
    id: 'j5hIHi',
  },

  v3ChallengeThreadedPostResponseLabel: {
    defaultMessage: `{hasMentionedBy, select,
    true {{
      hasRepliedBy, select,
        true {{
          count, plural,
            =0 {{additionalMentionedByCount, plural,
               =0 {{mentionedBy, select, self {you} other {{mentionedBy}}} mentioned you and {repliedBy, select, self {you} other {{repliedBy}}} replied to {postAuthor, select, self {your} other {{postAuthor}}}'s the post {name}}
               =1 {{mentionedBy, select, self {you} other {{mentionedBy}}} and 1 other mentioned you and {repliedBy, select, self {you} other {{repliedBy}}} replied to {postAuthor, select, self {your} other {{postAuthor}}}'s the post {name}}
               other {{mentionedBy, select, self {you} other {{mentionedBy}}} and {additionalMentionedByCount} others mentioned you and {repliedBy, select, self {you} other {{repliedBy}}} replied to {postAuthor, select, self {your} other {{postAuthor}}}'s the post {name}}
              }}
            =1 {{additionalMentionedByCount, plural,
               =0 {{mentionedBy, select, self {you} other {{mentionedBy}}} mentioned you and {repliedBy, select, self {you} other {{repliedBy}}} and 1 other replied to {postAuthor, select, self {your} other {{postAuthor}}}'s the post {name}}
               =1 {{mentionedBy, select, self {you} other {{mentionedBy}}} and 1 other mentioned you and {repliedBy, select, self {you} other {{repliedBy}}} and 1 other replied to {postAuthor, select, self {your} other {{postAuthor}}}'s the post {name}}
               other {{mentionedBy, select, self {you} other {{mentionedBy}}} and {additionalMentionedByCount} others mentioned you and {repliedBy, select, self {you} other {{repliedBy}}} and 1 other replied to {postAuthor, select, self {your} other {{postAuthor}}}'s the post {name}}
              }}
            other {{additionalMentionedByCount, plural,
               =0 {{mentionedBy, select, self {you} other {{mentionedBy}}} mentioned you and {repliedBy, select, self {you} other {{repliedBy}}} and {count} others replied on {postAuthor, select, self {your} other {{postAuthor}}}'s the post {name}}
               =1 {{mentionedBy, select, self {you} other {{mentionedBy}}} and 1 other mentioned you and {repliedBy, select, self {you} other {{repliedBy}}} and {count} others replied to {postAuthor, select, self {your} other {{postAuthor}}}'s the post {name}}
               other {{mentionedBy, select, self {you} other {{mentionedBy}}} and {additionalMentionedByCount} others mentioned you and {repliedBy, select, self {you} other {{repliedBy}}} and {count} others replied to {postAuthor, select, self {your} other {{postAuthor}}}'s the post {name}}
              }}
        }}
        false {{
          additionalMentionedByCount, select,
            0 {{mentionedBy, select, self {you} other {{mentionedBy}}} mentioned you on {postAuthor, select, self {your} other {{postAuthor}}}'s the post {name}}
            1 {{mentionedBy, select, self {you} other {{mentionedBy}}} and 1 other mentioned you on {postAuthor, select, self {your} other {{postAuthor}}}'s the post {name}}
            other {{mentionedBy, select, self {you} other {{mentionedBy}}} and {additionalMentionedByCount} others mentioned you on {postAuthor, select, self {your} other {{postAuthor}}}'s the post {name}}
        }}
        other {unknown}
    }}
    false {{
      hasRepliedBy, select,
        true {{
          count, plural,
            =0 {New reply by {repliedBy, select, self {you} other {{repliedBy}}} on {postAuthor, select, self {your} other {{postAuthor}}}'s the post {name}}
            =1 {New replies by {repliedBy, select, self {you} other {{repliedBy}}} and {count} other on {postAuthor, select, self {your} other {{postAuthor}}}'s the post {name}}
            other {New replies by {repliedBy, select, self {you} other {{repliedBy}}} and {count} others on {postAuthor, select, self {your} other {{postAuthor}}}'s the post {name}}
        }}
        false {New reply on {postAuthor, select, self {your} other {{postAuthor}}}'s the post {name}}
        other {unknown}
    }}
    other {unknown}
  }
`,
    id: 'rovk8q',
  },

  celebrationThreadedResponseLabel: {
    defaultMessage: `{hasMentionedBy, select,
    true {{
      hasRepliedBy, select,
        true {{
          count, plural,
            =0 {{additionalMentionedByCount, plural,
               =0 {Mention by {mentionedBy, select, self {you} other {{mentionedBy}}} and reply by {repliedBy, select, self {you} other {{repliedBy}}} on {celebrationType} Post for {celebratedUser, select, self {you} other {{celebratedUser}}} in {emoticon} {flowName}}
               =1 {Mention by {mentionedBy, select, self {you} other {{mentionedBy}}} and 1 other and reply by {repliedBy, select, self {you} other {{repliedBy}}} on {celebrationType} Post for {celebratedUser, select, self {you} other {{celebratedUser}}} in {emoticon} {flowName}}
               other {Mention by {mentionedBy, select, self {you} other {{mentionedBy}}} and {additionalMentionedByCount} others and reply by {repliedBy, select, self {you} other {{repliedBy}}} on {celebrationType} Post for {celebratedUser, select, self {you} other {{celebratedUser}}} in {emoticon} {flowName}}
              }}
            =1 {{additionalMentionedByCount, plural,
               =0 {Mention by {mentionedBy, select, self {you} other {{mentionedBy}}} and reply by {repliedBy, select, self {you} other {{repliedBy}}} and 1 other on {celebrationType} Post for {celebratedUser, select, self {you} other {{celebratedUser}}} in {emoticon} {flowName}}
               =1 {Mention by {mentionedBy, select, self {you} other {{mentionedBy}}} and 1 other and reply by {repliedBy, select, self {you} other {{repliedBy}}} and 1 other on {celebrationType} Post for {celebratedUser, select, self {you} other {{celebratedUser}}} in {emoticon} {flowName}}
               other {Mention by {mentionedBy, select, self {you} other {{mentionedBy}}} and {additionalMentionedByCount} others and reply by {repliedBy, select, self {you} other {{repliedBy}}} and 1 other on {celebrationType} Post for {celebratedUser, select, self {you} other {{celebratedUser}}} in {emoticon} {flowName}}
              }}
            other {{additionalMentionedByCount, plural,
               =0 {Mention by {mentionedBy, select, self {you} other {{mentionedBy}}} and reply by {repliedBy, select, self {you} other {{repliedBy}}} and {count} others on {celebrationType} Post for {celebratedUser, select, self {you} other {{celebratedUser}}} in {emoticon} {flowName}}
               =1 {Mention by {mentionedBy, select, self {you} other {{mentionedBy}}} and 1 other and reply by {repliedBy, select, self {you} other {{repliedBy}}} and {count} others on {celebrationType} Post for {celebratedUser, select, self {you} other {{celebratedUser}}} in {emoticon} {flowName}}
               other {Mention by {mentionedBy, select, self {you} other {{mentionedBy}}} and {additionalMentionedByCount} others and reply by {repliedBy, select, self {you} other {{repliedBy}}} and {count} others on {celebrationType} Post for {celebratedUser, select, self {you} other {{celebratedUser}}} in {emoticon} {flowName}}
              }}
        }}
        false {{
          additionalMentionedByCount, select,
            0 {Mention by {mentionedBy, select, self {you} other {{mentionedBy}}} on {celebrationType} Post for {celebratedUser, select, self {you} other {{celebratedUser}}} in {emoticon} {flowName}}
            1 {Mention by {mentionedBy, select, self {you} other {{mentionedBy}}} and 1 other on {celebrationType} Post for {celebratedUser, select, self {you} other {{celebratedUser}}} in {emoticon} {flowName}}
            other {Mention by {mentionedBy, select, self {you} other {{mentionedBy}}} and {additionalMentionedByCount} others on {celebrationType} Post for {celebratedUser, select, self {you} other {{celebratedUser}}} in {emoticon} {flowName}}
        }}
        other {unknown}
    }}
    false {{
      hasRepliedBy, select,
        true {{
          count, plural,
            =0 {Reply by {repliedBy, select, self {you} other {{repliedBy}}} on {celebrationType} Post for {celebratedUser, select, self {you} other {{celebratedUser}}} in {emoticon} {flowName}}
            =1 {Replies by {repliedBy, select, self {you} other {{repliedBy}}} and {count} other on {celebrationType} Post for {celebratedUser, select, self {you} other {{celebratedUser}}} in {emoticon} {flowName}}
            other {Replies by {repliedBy, select, self {you} other {{repliedBy}}} and {count} others on {celebrationType} Post for {celebratedUser, select, self {you} other {{celebratedUser}}} in {emoticon} {flowName}}
        }}
        false {Reply on {celebrationType} Post for {celebratedUser, select, self {you} other {{celebratedUser}}} in {emoticon} {flowName}}
        other {unknown}
    }}
    other {unknown}
  }`,
    id: 'J1lWCY',
  },
  v3CelebrationThreadedResponseLabel: {
    defaultMessage: `{hasMentionedBy, select,
    true {{
      hasRepliedBy, select,
        true {{
          count, plural,
            =0 {{additionalMentionedByCount, plural,
               =0 {{mentionedBy, select, self {you} other {{mentionedBy}}} mentioned you and {repliedBy, select, self {you} other {{repliedBy}}} replied to {celebrationType} Post for {celebratedUser, select, self {you} other {{celebratedUser}}}{hideFlowName, select, true {} other { in {emoticon} {flowName}}}}
               =1 {{mentionedBy, select, self {you} other {{mentionedBy}}} and 1 other mentioned you and{repliedBy, select, self {you} other {{repliedBy}}} replied to {celebrationType} Post for {celebratedUser, select, self {you} other {{celebratedUser}}}{hideFlowName, select, true {} other { in {emoticon} {flowName}}}}
               other {{mentionedBy, select, self {you} other {{mentionedBy}}} and {additionalMentionedByCount} others mentioned you and {repliedBy, select, self {you} other {{repliedBy}}} replied to {celebrationType} Post for {celebratedUser, select, self {you} other {{celebratedUser}}}{hideFlowName, select, true {} other { in {emoticon} {flowName}}}}
              }}
            =1 {{additionalMentionedByCount, plural,
               =0 {{mentionedBy, select, self {you} other {{mentionedBy}}} mentioned you and {repliedBy, select, self {you} other {{repliedBy}}} and 1 other replied to {celebrationType} Post for {celebratedUser, select, self {you} other {{celebratedUser}}}{hideFlowName, select, true {} other { in {emoticon} {flowName}}}}
               =1 {{mentionedBy, select, self {you} other {{mentionedBy}}} and 1 other mentioned you and{repliedBy, select, self {you} other {{repliedBy}}} and 1 other replied to {celebrationType} Post for {celebratedUser, select, self {you} other {{celebratedUser}}}{hideFlowName, select, true {} other { in {emoticon} {flowName}}}}
               other {{mentionedBy, select, self {you} other {{mentionedBy}}} and {additionalMentionedByCount} others mentioned you and {repliedBy, select, self {you} other {{repliedBy}}} and 1 other replied to {celebrationType} Post for {celebratedUser, select, self {you} other {{celebratedUser}}}{hideFlowName, select, true {} other { in {emoticon} {flowName}}}}
              }}
            other {{additionalMentionedByCount, plural,
               =0 {{mentionedBy, select, self {you} other {{mentionedBy}}} mentioned you and {repliedBy, select, self {you} other {{repliedBy}}} and {count} others replied to {celebrationType} Post for {celebratedUser, select, self {you} other {{celebratedUser}}}{hideFlowName, select, true {} other { in {emoticon} {flowName}}}}
               =1 {{mentionedBy, select, self {you} other {{mentionedBy}}} and 1 other mentioned you and{repliedBy, select, self {you} other {{repliedBy}}} and {count} others replied to {celebrationType} Post for {celebratedUser, select, self {you} other {{celebratedUser}}}{hideFlowName, select, true {} other { in {emoticon} {flowName}}}}
               other {{mentionedBy, select, self {you} other {{mentionedBy}}} and {additionalMentionedByCount} others mentioned you and {repliedBy, select, self {you} other {{repliedBy}}} and {count} others replied to {celebrationType} Post for {celebratedUser, select, self {you} other {{celebratedUser}}}{hideFlowName, select, true {} other { in {emoticon} {flowName}}}}
              }}
        }}
        false {{
          additionalMentionedByCount, select,
            0 {{mentionedBy, select, self {you} other {{mentionedBy}}} mentioned you on {celebrationType} Post for {celebratedUser, select, self {you} other {{celebratedUser}}}{hideFlowName, select, true {} other { in {emoticon} {flowName}}}}
            1 {{mentionedBy, select, self {you} other {{mentionedBy}}} and 1 other mentioned you on {celebrationType} Post for {celebratedUser, select, self {you} other {{celebratedUser}}}{hideFlowName, select, true {} other { in {emoticon} {flowName}}}}
            other {{mentionedBy, select, self {you} other {{mentionedBy}}} and {additionalMentionedByCount} others mentioned you on {celebrationType} Post for {celebratedUser, select, self {you} other {{celebratedUser}}}{hideFlowName, select, true {} other { in {emoticon} {flowName}}}}
        }}
        other {unknown}
    }}
    false {{
      hasRepliedBy, select,
        true {{
          count, plural,
            =0 {New reply by {repliedBy, select, self {you} other {{repliedBy}}} on {celebrationType} Post for {celebratedUser, select, self {you} other {{celebratedUser}}}{hideFlowName, select, true {} other { in {emoticon} {flowName}}}}
            =1 {New replies by {repliedBy, select, self {you} other {{repliedBy}}} and {count} other on {celebrationType} Post for {celebratedUser, select, self {you} other {{celebratedUser}}}{hideFlowName, select, true {} other { in {emoticon} {flowName}}}}
            other {New replies by {repliedBy, select, self {you} other {{repliedBy}}} and {count} others on {celebrationType} Post for {celebratedUser, select, self {you} other {{celebratedUser}}}{hideFlowName, select, true {} other { in {emoticon} {flowName}}}}
        }}
        false {New reply on {celebrationType} Post for {celebratedUser, select, self {you} other {{celebratedUser}}}{hideFlowName, select, true {} other { in {emoticon} {flowName}}}}
        other {unknown}
    }}
    other {unknown}
  }`,
    id: '04QAiZ',
  },
  threadedPostResponseLabel: {
    defaultMessage: `{hasMentionedBy, select,
    true {{
      hasRepliedBy, select,
        true {{
          count, plural,
            =0 {{additionalMentionedByCount, plural,
               =0 {Mention by {mentionedBy, select, self {you} other {{mentionedBy}}} and reply by {repliedBy, select, self {you} other {{repliedBy}}} on {postAuthor, select, self {your} other {{postAuthor}}}'s post in {emoticon} {flowName}}
               =1 {Mention by {mentionedBy, select, self {you} other {{mentionedBy}}} and 1 other and reply by {repliedBy, select, self {you} other {{repliedBy}}} on {postAuthor, select, self {your} other {{postAuthor}}}'s post in {emoticon} {flowName}}
               other {Mention by {mentionedBy, select, self {you} other {{mentionedBy}}} and {additionalMentionedByCount} others and reply by {repliedBy, select, self {you} other {{repliedBy}}} on {postAuthor, select, self {your} other {{postAuthor}}}'s post in {emoticon} {flowName}}
              }}
            =1 {{additionalMentionedByCount, plural,
               =0 {Mention by {mentionedBy, select, self {you} other {{mentionedBy}}} and reply by {repliedBy, select, self {you} other {{repliedBy}}} and 1 other on {postAuthor, select, self {your} other {{postAuthor}}}'s post in {emoticon} {flowName}}
               =1 {Mention by {mentionedBy, select, self {you} other {{mentionedBy}}} and 1 other and reply by {repliedBy, select, self {you} other {{repliedBy}}} and 1 other on {postAuthor, select, self {your} other {{postAuthor}}}'s post in {emoticon} {flowName}}
               other {Mention by {mentionedBy, select, self {you} other {{mentionedBy}}} and {additionalMentionedByCount} others and reply by {repliedBy, select, self {you} other {{repliedBy}}} and 1 other on {postAuthor, select, self {your} other {{postAuthor}}}'s post in {emoticon} {flowName}}
              }}
            other {{additionalMentionedByCount, plural,
               =0 {Mention by {mentionedBy, select, self {you} other {{mentionedBy}}} and reply by {repliedBy, select, self {you} other {{repliedBy}}} and {count} others on {postAuthor, select, self {your} other {{postAuthor}}}'s post in {emoticon} {flowName}}
               =1 {Mention by {mentionedBy, select, self {you} other {{mentionedBy}}} and 1 other and reply by {repliedBy, select, self {you} other {{repliedBy}}} and {count} others on {postAuthor, select, self {your} other {{postAuthor}}}'s post in {emoticon} {flowName}}
               other {Mention by {mentionedBy, select, self {you} other {{mentionedBy}}} and {additionalMentionedByCount} others and reply by {repliedBy, select, self {you} other {{repliedBy}}} and {count} others on {postAuthor, select, self {your} other {{postAuthor}}}'s post in {emoticon} {flowName}}
              }}
        }}
        false {{
          additionalMentionedByCount, select,
            0 {Mention by {mentionedBy, select, self {you} other {{mentionedBy}}} on {postAuthor, select, self {your} other {{postAuthor}}}'s post in {emoticon} {flowName}}
            1 {Mention by {mentionedBy, select, self {you} other {{mentionedBy}}} and 1 other on {postAuthor, select, self {your} other {{postAuthor}}}'s post in {emoticon} {flowName}}
            other {Mention by {mentionedBy, select, self {you} other {{mentionedBy}}} and {additionalMentionedByCount} others on {postAuthor, select, self {your} other {{postAuthor}}}'s post in {emoticon} {flowName}}
        }}
        other {unknown}
    }}
    false {{
      hasRepliedBy, select,
        true {{
          count, plural,
            =0 {Reply by {repliedBy, select, self {you} other {{repliedBy}}} on {postAuthor, select, self {your} other {{postAuthor}}}'s post in {emoticon} {flowName}}
            =1 {Replies by {repliedBy, select, self {you} other {{repliedBy}}} and {count} other on {postAuthor, select, self {your} other {{postAuthor}}}'s post in {emoticon} {flowName}}
            other {Replies by {repliedBy, select, self {you} other {{repliedBy}}} and {count} others on {postAuthor, select, self {your} other {{postAuthor}}}'s post in {emoticon} {flowName}}
        }}
        false {Reply on {postAuthor, select, self {your} other {{postAuthor}}}'s post in {emoticon} {flowName}}
        other {unknown}
    }}
    other {unknown}
  }`,
    id: '3cJMSK',
  },
  v3ThreadedPostResponseLabel: {
    defaultMessage: `{hasMentionedBy, select,
    true {{
      hasRepliedBy, select,
        true {{
          count, plural,
            =0 {{additionalMentionedByCount, plural,
               =0 {{mentionedBy, select, self {you} other {{mentionedBy}}} mentioned you and {repliedBy, select, self {you} other {{repliedBy}}} replied to {postAuthor, select, self {your} other {{postAuthor}}}'s post{hideFlowName, select, true {} other { in {emoticon} {flowName}}}}
               =1 {{mentionedBy, select, self {you} other {{mentionedBy}}} and 1 other mentioned you and {repliedBy, select, self {you} other {{repliedBy}}} replied to {postAuthor, select, self {your} other {{postAuthor}}}'s post{hideFlowName, select, true {} other { in {emoticon} {flowName}}}}
               other {{mentionedBy, select, self {you} other {{mentionedBy}}} and {additionalMentionedByCount} others mentioned you and {repliedBy, select, self {you} other {{repliedBy}}} replied to {postAuthor, select, self {your} other {{postAuthor}}}'s post{hideFlowName, select, true {} other { in {emoticon} {flowName}}}}
              }}
            =1 {{additionalMentionedByCount, plural,
               =0 {{mentionedBy, select, self {you} other {{mentionedBy}}} mentioned you and {repliedBy, select, self {you} other {{repliedBy}}} and 1 other replied to {postAuthor, select, self {your} other {{postAuthor}}}'s post{hideFlowName, select, true {} other { in {emoticon} {flowName}}}}
               =1 {{mentionedBy, select, self {you} other {{mentionedBy}}} and 1 other mentioned you and {repliedBy, select, self {you} other {{repliedBy}}} and 1 other replied to {postAuthor, select, self {your} other {{postAuthor}}}'s post{hideFlowName, select, true {} other { in {emoticon} {flowName}}}}
               other {{mentionedBy, select, self {you} other {{mentionedBy}}} and {additionalMentionedByCount} others mentioned you and {repliedBy, select, self {you} other {{repliedBy}}} and 1 other replied to {postAuthor, select, self {your} other {{postAuthor}}}'s post{hideFlowName, select, true {} other { in {emoticon} {flowName}}}}
              }}
            other {{additionalMentionedByCount, plural,
               =0 {{mentionedBy, select, self {you} other {{mentionedBy}}} mentioned you and {repliedBy, select, self {you} other {{repliedBy}}} and {count} others replied on {postAuthor, select, self {your} other {{postAuthor}}}'s post{hideFlowName, select, true {} other { in {emoticon} {flowName}}}}
               =1 {{mentionedBy, select, self {you} other {{mentionedBy}}} and 1 other mentioned you and {repliedBy, select, self {you} other {{repliedBy}}} and {count} others replied to {postAuthor, select, self {your} other {{postAuthor}}}'s post{hideFlowName, select, true {} other { in {emoticon} {flowName}}}}
               other {{mentionedBy, select, self {you} other {{mentionedBy}}} and {additionalMentionedByCount} others mentioned you and {repliedBy, select, self {you} other {{repliedBy}}} and {count} others replied to {postAuthor, select, self {your} other {{postAuthor}}}'s post{hideFlowName, select, true {} other { in {emoticon} {flowName}}}}
              }}
        }}
        false {{
          additionalMentionedByCount, select,
            0 {{mentionedBy, select, self {you} other {{mentionedBy}}} mentioned you on {postAuthor, select, self {your} other {{postAuthor}}}'s post{hideFlowName, select, true {} other { in {emoticon} {flowName}}}}
            1 {{mentionedBy, select, self {you} other {{mentionedBy}}} and 1 other mentioned you on {postAuthor, select, self {your} other {{postAuthor}}}'s post{hideFlowName, select, true {} other { in {emoticon} {flowName}}}}
            other {{mentionedBy, select, self {you} other {{mentionedBy}}} and {additionalMentionedByCount} others mentioned you on {postAuthor, select, self {your} other {{postAuthor}}}'s post{hideFlowName, select, true {} other { in {emoticon} {flowName}}}}
        }}
        other {unknown}
    }}
    false {{
      hasRepliedBy, select,
        true {{
          count, plural,
            =0 {New reply by {repliedBy, select, self {you} other {{repliedBy}}} on {postAuthor, select, self {your} other {{postAuthor}}}'s post{hideFlowName, select, true {} other { in {emoticon} {flowName}}}}
            =1 {New replies by {repliedBy, select, self {you} other {{repliedBy}}} and {count} other on {postAuthor, select, self {your} other {{postAuthor}}}'s post{hideFlowName, select, true {} other { in {emoticon} {flowName}}}}
            other {New replies by {repliedBy, select, self {you} other {{repliedBy}}} and {count} others on {postAuthor, select, self {your} other {{postAuthor}}}'s post{hideFlowName, select, true {} other { in {emoticon} {flowName}}}}
        }}
        false {New reply on {postAuthor, select, self {your} other {{postAuthor}}}'s post{hideFlowName, select, true {} other { in {emoticon} {flowName}}}}
        other {unknown}
    }}
    other {unknown}
  }
`,
    id: 'urakGc',
  },
  flowArchivedSuccessfully: {
    defaultMessage: 'Flow archived successfully!',
    id: 'Wn57bP',
  },
  archivedFlowDescription: {
    defaultMessage:
      'Unarchive view feed. This will restore all posts to the feed and they will be visible to all viewers. You can archive it again at any time.',
    id: 'x3ro6p',
  },
  followPost: { defaultMessage: 'Follow post', id: 'njes0v' },
  unfollowPost: { defaultMessage: 'Unfollow post', id: '8K6ak6' },
  followedPostSuccess: {
    defaultMessage: "You successfully followed {author}'s post",
    id: 'JhiiWm',
  },
  unfollowedPostSuccess: {
    defaultMessage: "You successfully unfollowed {author}'s post",
    id: 'voXBV3',
  },
  followedPostError: {
    defaultMessage: "Unable to follow {author}'s post",
    id: 'XSVwLS',
  },
  unfollowedPostError: {
    defaultMessage: "Unable to unfollow {author}'s post",
    id: 'nB5YF9',
  },
  followedAnniversaryPostSuccess: {
    defaultMessage: "You successfully followed {receiver}'s anniversary post",
    id: 'BL7jmf',
  },
  unfollowedAnniversaryPostSuccess: {
    defaultMessage: "You successfully unfollowed {receiver}'s anniversary post",
    id: '/hiGra',
  },
  followedAnniversaryPostError: {
    defaultMessage: "Unable to follow {receiver}'s anniversary post",
    id: 'Lp9h50',
  },
  unfollowedAnniversaryPostError: {
    defaultMessage: "Unable to unfollow {receiver}'s anniversary post",
    id: 'ud08b0',
  },
  followedBirthdayPostSuccess: {
    defaultMessage: "You successfully followed {receiver}'s birthday post",
    id: 'Nkt3dH',
  },
  unfollowedBirthdayPostSuccess: {
    defaultMessage: "You successfully unfollowed {receiver}'s birthday post",
    id: 'X4X7+j',
  },
  followedBirthdayPostError: {
    defaultMessage: "Unable to follow {receiver}'s birthday post",
    id: 'rPLG0U',
  },
  unfollowedBirthdayPostError: {
    defaultMessage: "Unable to unfollow {receiver}'s birthday post",
    id: 'tPc0VI',
  },
  claimSubmittedSuccessfully: {
    defaultMessage: 'Claim submitted successfully!',
    id: 'Fu6ENy',
  },
  failedToSubmitClaim: {
    defaultMessage: 'Unable to submit claim, please try again later',
    id: 'TqeH9H',
  },
});
