import { PopoutDrawer, RefProvider } from '@assembly-web/ui';
import type { ReactNode } from 'react';
import { useContext, useEffect, useState } from 'react';

import { useMultiDrawerStore } from '../../../../stores/useMultiDrawerStore';
import { CloseDrawerContext } from '../../contexts/CloseDrawerContext';
import { trackPopoutAction } from '../../services/analytics';
import {
  getFullScreenDrawerId,
  setFullScreenDrawerId,
} from '../../services/drawers';
import { DefaultToolbar } from './shared/DefaultToolbar';
import { DrawerHeader } from './shared/DrawerHeader';
import { accessDrawer, type Drawer } from './types';

type PopoutDrawerTemplateProps = {
  children: ReactNode | ((isFullScreen: boolean) => ReactNode);
  shouldShowDotNotification?: boolean;
} & Drawer;

export function PopoutDrawerTemplate(props: PopoutDrawerTemplateProps) {
  const {
    children,
    shouldShowDotNotification = false,
    newActivityType = 'info',
    ...drawerProps
  } = props;

  const { title, type, id, isOpen } = drawerProps;

  const closeDrawer = useMultiDrawerStore((store) => store.closeDrawer);
  const openDrawer = useMultiDrawerStore((store) => store.openDrawer);
  const deleteDrawer = useMultiDrawerStore((store) => store.deleteDrawer);

  const [ref, setRef] = useState<HTMLDivElement | null>(null);

  const [isFullScreen, setIsFullScreen] = useState(
    props.id === getFullScreenDrawerId()
  );

  const { openModal: openConfirmationCloseModal } =
    useContext(CloseDrawerContext);

  useEffect(() => {
    function handler(event: Event) {
      if (event instanceof CustomEvent && typeof event.detail === 'string') {
        setIsFullScreen(event.detail === props.id);
      }
    }
    document.addEventListener('drawer-full-screen', handler);
  }, [props.id]);

  const handleCollapse = () => {
    closeDrawer(id);
    trackPopoutAction('collapsePopoutClicked', { popoutType: type });
  };

  const onOpen = () => {
    openDrawer(id);
    trackPopoutAction('expandPopoutClicked', { popoutType: type });
  };

  const handleClose = () => {
    accessDrawer(drawerProps, type, (config, props) => {
      if (config.shouldConfirmOnClose?.(props)) {
        openConfirmationCloseModal(props);
      } else {
        deleteDrawer(id);
        config.onClose?.(props);
      }
    });

    trackPopoutAction('exitPopoutClicked', { popoutType: type });
  };

  const handleOpenChange = (newIsOpen: boolean) => {
    accessDrawer(drawerProps, type, (config, args) => {
      const onOpenChange = config.onOpenChange;
      onOpenChange?.(args, newIsOpen);
    });

    if (!newIsOpen) {
      accessDrawer(drawerProps, type, (config, args) => {
        config.onCollapse?.(args);
        handleCollapse();
      });
    } else {
      onOpen();
    }
  };

  const toggleFullScreen = () => {
    if (!isFullScreen) {
      trackPopoutAction('fullScreenModalClicked', { popoutType: type });
    }

    setFullScreenDrawerId(!isFullScreen ? props.id : '');
    setIsFullScreen(!isFullScreen);
  };

  return (
    <RefProvider value={ref}>
      <PopoutDrawer
        isWide={
          drawerProps.type === 'doraChat' || drawerProps.type === 'flowCreation'
        }
        hasNewActivity={shouldShowDotNotification}
        newActivityType={newActivityType}
        header={<DrawerHeader {...drawerProps} />}
        isOpen={isOpen}
        label={title}
        onOpenChange={handleOpenChange}
        isFullScreen={isFullScreen}
        ref={setRef}
        toolbar={
          <DefaultToolbar
            onCollapse={handleCollapse}
            isOpen={isOpen}
            isFullScreen={isFullScreen}
            toggleFullScreen={toggleFullScreen}
            onClose={handleClose}
          />
        }
        transparentHeader={drawerProps.type === 'flowCreation'}
        {...(drawerProps.type === 'postsAndReplies' &&
          drawerProps.data.type === 'post' && {
            postType: drawerProps.data.postType,
          })}
      >
        {typeof children === 'function' ? children(isFullScreen) : children}
      </PopoutDrawer>
    </RefProvider>
  );
}
