import { APIEndpoints, assemblyAPI } from '@assembly-web/services';
import type { ProviderSwagInfo } from '@assembly-web/services/lib/types/rewards';
import { useQuery } from '@tanstack/react-query';

export function useGetInfoCovverQuery() {
  return useQuery({
    queryKey: ['getInfoCovver'],
    queryFn: async () => {
      const res = await assemblyAPI.get<ProviderSwagInfo>(
        APIEndpoints.getInfoCovver
      );
      return res.data;
    },
  });
}
