import {
  queryOptions,
  useQuery,
  type UseQueryOptions,
} from '@tanstack/react-query';

import { APIEndpoints } from '../../../../../../packages/services/lib/APIEndpoints';
import { assemblyAPI } from '../../../../../../packages/services/lib/assemblyAPI';
import type {
  FlowParticipationInsightsParams,
  FlowParticipationInsightsResponse,
} from '../types';

const getFlowParticipationInsightsQueryOptions = ({
  options,
  params,
}: {
  params: FlowParticipationInsightsParams;
  options?: Partial<UseQueryOptions<FlowParticipationInsightsResponse>>;
}) => {
  return queryOptions({
    ...options,
    queryKey: ['flowParticipationInsights', params],
    staleTime: 1000 * 60 * 3, // 3 minutes
    queryFn: async () => {
      const response = await assemblyAPI.get<FlowParticipationInsightsResponse>(
        APIEndpoints.getFlowParticipationInsights,
        {
          params,
        }
      );
      return response.data;
    },
  });
};

export function useGetFlowParticipationInsights({
  options,
  params,
}: {
  params: FlowParticipationInsightsParams;
  options?: Partial<UseQueryOptions<FlowParticipationInsightsResponse>>;
}) {
  return useQuery(
    getFlowParticipationInsightsQueryOptions({ params, options })
  );
}
