import {
  type AwardDetail,
  type AwardPostResponse,
  parsePostResponseRecipients,
} from '@assembly-web/services';

import type { GiveAwardForm } from './types';

export function getAwardDetailData(data: AwardDetail): GiveAwardForm {
  return {
    coreValue: data.post.coreValue,
    points: data.budget.unitPoints,
    isPrivate: false,
    description: data.post.description,
    image: data.post.image,
  };
}
export function getAwardPostData(data: AwardPostResponse): GiveAwardForm {
  return {
    coreValue: data.post.coreValue,
    points: data.post.pointsEach,
    isPrivate: data.post.isPrivate,
    recipients: parsePostResponseRecipients(data.post.recipient),
    attachments: data.post.attachments,
    message: {
      plainText: data.post.message,
      html: data.post.messageHtml,
      json: data.post.messageTokens,
    },
    description: data.post.award.description,
    image: data.post.award.image,
  };
}
