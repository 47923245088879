import { mapHexCodeToEmoticon } from '@assembly-web/services';
import { TextStyle } from '@assembly-web/ui';
import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { ContentLayout } from '../../components/Layout/ContentLayout';
import { useGetFlowBuilderDetailsById } from '../discover/hooks/flows/useGetFlowBuilderDetailsById';
import { BlockInsights } from './components/BlockInsights';
import { MetricCard } from './components/MetricCard';
import { useGetFlowParticipationInsights } from './hooks/useGetFlowParticipationInsights';

const messages = defineMessages({
  participantsHeader: {
    defaultMessage: 'Participants',
    id: 'zx0myy',
  },
  responsesHeader: {
    defaultMessage: 'Responses',
    id: 'WBVVYd',
  },
  responseRateHeader: {
    defaultMessage: 'Response Rate',
    id: 'B9FtPC',
  },
  responseRateTooltip: {
    defaultMessage:
      'Response rate is the percentage of participants who responded to the flow.',
    id: 'Eu8H+i',
  },
});

export function FlowInsightsRoute() {
  const { id: flowId } = useParams<{ id: string }>();
  const { formatMessage } = useIntl();
  const { data: flowDetails, isLoading: isLoadingFlowDetails } =
    useGetFlowBuilderDetailsById(flowId ?? '', {
      select: (data) => data,
    });

  const { data: flowParticipationInsights } = useGetFlowParticipationInsights({
    params: {
      flowId: flowId ?? '',
    },
  });

  const flowBlockInsights = useMemo(
    () =>
      flowId
        ? flowDetails?.action?.blocks.map((x) => {
            if (
              x.type === 'DROPDOWN' ||
              x.type === 'MULTI_CHOICE' ||
              x.type === 'SCALE' ||
              x.type === 'PERSON_SELECTOR'
            ) {
              return (
                <BlockInsights key={x.blockId} flowId={flowId} block={x} />
              );
            }
          })
        : [],
    [flowId, flowDetails?.action?.blocks]
  );

  if (isLoadingFlowDetails) return '';

  return (
    <ContentLayout>
      <section className="flex max-w-[900px] flex-col gap-8 rounded-2xl bg-gray-1 p-4">
        <header className="flex items-center justify-between">
          <h3>
            <TextStyle variant="xl-bold" as="span" className="line-clamp-3">
              {mapHexCodeToEmoticon(flowDetails?.icon.value ?? '')}{' '}
              {flowDetails?.name}
            </TextStyle>
          </h3>
        </header>
        {flowParticipationInsights !== undefined && (
          <div className="flex w-full flex-col gap-4 min-[525px]:flex-row [&>*]:flex-1">
            <MetricCard
              title={formatMessage(messages.participantsHeader)}
              value={flowParticipationInsights.totalParticipants}
            />
            <MetricCard
              title={formatMessage(messages.responsesHeader)}
              value={flowParticipationInsights.totalResponses}
            />
            <MetricCard
              title={formatMessage(messages.responseRateHeader)}
              value={`${flowParticipationInsights.responseRatePercentage}%`}
              tooltipText={formatMessage(messages.responseRateTooltip)}
            />
          </div>
        )}
        <div className="flex flex-col gap-8">{flowBlockInsights}</div>
      </section>
    </ContentLayout>
  );
}
