import type { Member } from '@assembly-web/services';
import {
  AllowanceType,
  GlobalFilterOption,
  isUserAdmin,
  MemberStatus,
  RewardType,
  SplitNames,
  useFeatureSplit,
  useSuspenseUserDetails,
} from '@assembly-web/services';
import { useSearchParams } from 'react-router-dom';

import { useGetAmazonConnections } from '../../discover/hooks/rewards/useGetAmazonConnections';
import { useAxomoStoreInfoQuery } from '../../discover/hooks/rewards/useGetAxomoDetailsQuery';
import { useGetInfoCovverQuery } from '../../discover/hooks/rewards/useGetInfoCovver';
import { useGetPlanFeaturesQuery } from '../../discover/hooks/useGetPlanFeaturesQuery';
import { useGlobalFilter } from '../../discover/hooks/useGlobalFilter';
import { useRedeemRewardDetailsQuery } from '../../discover/hooks/useRedeemRewardDetailsQuery';
import { mapV2ToV3RewardsRoute } from '../../discover/services/rewardsUtil';

function canCurrentUserGiveAllowance(currentUser: Member) {
  const { allowance } = currentUser;

  const hasCustomAllowanceOfZero =
    allowance.type === AllowanceType.Custom && allowance.points === 0;

  const hasDefaultAllowanceOfZero =
    allowance.type === AllowanceType.Regular && allowance.points === 0;

  const hasNoAllowance =
    currentUser.allowance.type === AllowanceType.NoAllowance &&
    allowance.points === 0;

  const isObserver = currentUser.status === MemberStatus.Observer;

  const isReceiver = currentUser.status === MemberStatus.Receiver;

  return !(
    hasCustomAllowanceOfZero ||
    hasNoAllowance ||
    isObserver ||
    isReceiver ||
    hasDefaultAllowanceOfZero
  );
}

export function getRewardsRedirectionPath({
  hasGiftCardsSetup,
  hasCharitiesSetup,
  workspaceSlugPath = '',
}: {
  hasGiftCardsSetup: boolean;
  hasCharitiesSetup: boolean;
  workspaceSlugPath: string;
}) {
  const basePath = `/a/discover`;
  const paramMappings = {
    filter: 0,
    tab: 1,
  };

  const rewardsV2PathMap = {
    [RewardType.GiftCards]: 'gift-cards',
  };

  if (hasGiftCardsSetup || hasCharitiesSetup) {
    const path = `/${workspaceSlugPath}/rewards/${
      hasGiftCardsSetup
        ? rewardsV2PathMap[RewardType.GiftCards]
        : RewardType.Charities
    }`;

    return mapV2ToV3RewardsRoute(
      basePath,
      paramMappings,
      path,
      workspaceSlugPath
    );
  }

  return mapV2ToV3RewardsRoute(
    basePath,
    paramMappings,
    `/${workspaceSlugPath}/rewards/culture`,
    workspaceSlugPath
  );
}

export function useRewardsDetails() {
  let [searchParams] = useSearchParams();
  const isRewardsTabSelected = useGlobalFilter() === GlobalFilterOption.Rewards;

  const { data: userDetails } = useSuspenseUserDetails();
  const { member } = userDetails;

  const { data: planDetails } = useGetPlanFeaturesQuery();

  const { data: axomoInfo, isPending: isSwagTabStatusPending } =
    useAxomoStoreInfoQuery({
      enabled: !(
        planDetails?.uniqueId === 'free' || planDetails?.uniqueId === 'lite'
      ),
    });
  const { data: covverInfo } = useGetInfoCovverQuery();

  const { isTreatmentActive: isAmazonRewardsEnabled } = useFeatureSplit(
    SplitNames.AmazonRewards
  );

  const { data: amazonConnections } = useGetAmazonConnections({
    enabled: isAmazonRewardsEnabled && isRewardsTabSelected,
  });

  const isSwagTabOn =
    covverInfo?.state === 'CONNECTED'
      ? covverInfo.visibility === 'ON'
      : axomoInfo?.visibility === 'ON';
  const isSwagConnected =
    covverInfo?.state === 'CONNECTED' ? true : axomoInfo?.state === 'CONNECTED';
  const swagTabInfo =
    covverInfo?.state === 'CONNECTED' ? covverInfo : axomoInfo;

  const getAmazonRewardsTurnedOn = () => {
    if (amazonConnections === 'OK' || !amazonConnections) return false;
    return amazonConnections.visibility === 'ON';
  };

  const isAmazonRewardsTurnedOn = getAmazonRewardsTurnedOn();
  const isAmazonConnected = amazonConnections !== 'OK';

  const isAdmin = Boolean(isUserAdmin(member));

  const [{ data: redeemInfoData, isPending: isRedeemInfoDataPending }] =
    useRedeemRewardDetailsQuery();

  const isObserver = member.status === MemberStatus.Observer;

  const isRedeemable = redeemInfoData?.isRedeemable;

  const hasCultureSetup = isAdmin
    ? true
    : (redeemInfoData?.hasCulture ?? false);
  const hasGiftCardsSetup = isAdmin
    ? true
    : (redeemInfoData?.hasGiftCards ?? false);
  const hasCharitiesSetup = isAdmin
    ? true
    : (redeemInfoData?.hasCharities ?? false);
  const hasSwagsSetup = redeemInfoData?.hasSwags ?? false;
  const hasDiscountsSetup = redeemInfoData?.hasDiscounts ?? false;
  const hasAmazonSetup = isAdmin
    ? true
    : ((redeemInfoData?.hasAmazon && isAmazonRewardsTurnedOn) ?? false);

  const getMultipleCountriesConnected = () => {
    if (amazonConnections === 'OK') return false;
    return amazonConnections?.connections
      ? amazonConnections.connections.length > 1
      : false;
  };
  const hasMultipleCountriesConnected = getMultipleCountriesConnected();

  const rewardsType = searchParams.get('tab');

  const hasRewardsSetup =
    hasCharitiesSetup ||
    hasCultureSetup ||
    hasGiftCardsSetup ||
    hasSwagsSetup ||
    hasAmazonSetup;

  const hideEarnedPoints = Boolean(
    member.status === MemberStatus.Giver || isObserver
  );

  const hasAllowance = canCurrentUserGiveAllowance(member);

  const isRewardsTabsDisabled =
    !isRedeemInfoDataPending &&
    ((rewardsType === RewardType.GiftCards && !hasGiftCardsSetup) ||
      (rewardsType === RewardType.Swag && !hasSwagsSetup) ||
      (rewardsType === RewardType.Charities && !hasCharitiesSetup) ||
      (rewardsType === RewardType.Culture && !hasCultureSetup) ||
      (rewardsType === RewardType.Amazon && !hasAmazonSetup) ||
      !hasRewardsSetup);

  const isRewardsDisabled =
    !isRedeemInfoDataPending &&
    ((rewardsType === RewardType.GiftCards && !redeemInfoData?.hasGiftCards) ||
      (rewardsType === RewardType.Swag && !redeemInfoData?.hasSwags) ||
      (rewardsType === RewardType.Charities && !redeemInfoData?.hasCharities) ||
      (rewardsType === RewardType.Culture && !redeemInfoData?.hasCulture) ||
      (rewardsType === RewardType.Amazon && !redeemInfoData?.hasAmazon) ||
      !hasRewardsSetup);

  const isRewardsSetupForUser =
    !isObserver &&
    (hasCharitiesSetup ||
      hasCultureSetup ||
      hasGiftCardsSetup ||
      hasAmazonSetup ||
      hasSwagsSetup);

  const earnedBalance = member.pointsEarned;

  const spentAllowance = member.allowance.points - member.pointsLeftThisCycle;

  const totalAllowance = member.allowance.points;

  const remainingAllowance = member.pointsLeftThisCycle;

  return {
    currency: userDetails.assembly.currency,
    remainingAllowance,
    totalAllowance,
    earnedBalance,
    spentAllowance,
    isSwagTabOn,
    isSwagConnected,
    isSwagTabStatusLoading: isSwagTabStatusPending,
    swagTabInfo,
    hasAllowance,
    isRedeemable,
    hasCultureSetup,
    hasGiftCardsSetup,
    hasCharitiesSetup,
    hasSwagsSetup,
    hasEarnedPoints: !hideEarnedPoints,
    isRedeemInfoDataLoading: isRedeemInfoDataPending,
    isRewardsTabsDisabled,
    isRewardsDisabled,
    hasDiscountsSetup,
    isAmazonRewardsTurnedOn,
    isAmazonConnected,
    hasAmazonSetup,
    hasMultipleCountriesConnected,
    canRedeemAmazonRewards: redeemInfoData?.hasAmazon ?? false,
    /** Is rewards setup for the current user */
    isRewardsSetupForUser,
  };
}
