import type {
  FileDownloadRequest,
  ReactionDetails,
} from '@assembly-web/services';
import {
  config,
  copyToClipboard,
  getFlowPostPoints,
  getMemberDetailsFromUserDetails,
  isBotPost,
  useSuspenseUserDetails,
} from '@assembly-web/services';
import {
  Post,
  PostCardActions,
  PostLoader,
  useToastStore,
} from '@assembly-web/ui';
import { isAxiosError } from 'axios';
import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { ContentTranslationButton } from '../../../../../components/ContentTranslationButton';
import { useContentTranslation } from '../../../../../hooks/useContentTranslation';
import { useGetPostAndRepliesDetails } from '../../../../../hooks/useGetPostAndRepliesDetails';
import { useIsRecognitionOnlyCustomer } from '../../../../../hooks/useIsRecognitionOnlyCustomer';
import { useNavigateToUserFeed } from '../../../../../hooks/useNavigateToUserFeed';
import { useOpenFlowsPreviewer } from '../../../../../hooks/useOpenFlowsPreviewer';
import { useReactionMutation } from '../../../../../hooks/useReactionMutation';
import { downloadFeedFile } from '../../../../../services/file';
import { getURLForAssemblyPostActions } from '../../../../../services/post';
import type { PostData } from '../../../../../types/postsAndReplies';
import { useCurrentUserDetail } from '../../../hooks/useCurrentUserDetail';
import {
  trackReplyDrawerAction,
  trackReplyDrawerError,
} from '../../../services/analytics';
import { getFlowOrRecognitionPostLinkToCopy } from '../../../services/common';
import { useBoostOptions } from '../shared/hooks/useBoostOptions';
import { usePostToolbar } from './hooks/usePostToolbar';

const messages = defineMessages({
  copiedToClipboardText: {
    defaultMessage: 'File Link Copied to clipboard',
    id: 'Hj9In1',
  },
  downloadingFileText: {
    defaultMessage: 'Downloading file',
    id: '2vpW3T',
  },
  fileDownloadedText: {
    defaultMessage: 'File downloaded successfully',
    id: 'griv1/',
  },
  errorDownloadingFileText: {
    defaultMessage: 'Error while downloading the file',
    id: 'hDCJX3',
  },
});

type PostContainerProps = {
  flowId: string;
  responseId: string;
  kind: 'RESPONSE' | 'POST';
};

export function PostContainer({
  kind,
  flowId,
  responseId,
}: PostContainerProps) {
  const { formatMessage } = useIntl();
  const isRecognitionOnlyCustomer = useIsRecognitionOnlyCustomer();

  const boostOptions = useBoostOptions({ flowId, responseId });

  const {
    data: {
      assembly: {
        settings: {
          postPermission: {
            canHidePoints: { enabled: canHidePoints },
          },
        },
      },
    },
  } = useSuspenseUserDetails();

  const currentUserDetails = useCurrentUserDetail();

  const { navigate: navigateToUserFeed } = useNavigateToUserFeed();
  const { showSuccessToast, showErrorToast, showInfoToast } = useToastStore();
  const {
    currentUser,
    flowResponse: originalFlowResponse,
    postCardRepliesDetails,
  } = useGetPostAndRepliesDetails({
    flowId,
    responseId,
    enabled: true,
  });

  const translationPayload = { flowId, responseId };

  const {
    getContent,
    showTranslatedContent,
    showOriginalContent,
    isTranslationInProgress,
    isShowingOriginalContent,
  } = useContentTranslation<NonNullable<typeof originalFlowResponse>>({
    contents: originalFlowResponse ? [originalFlowResponse] : [],
  });

  const flowResponse = getContent(translationPayload);
  const isShowingOriginalContentValue = flowResponse
    ? isShowingOriginalContent(translationPayload)
    : false;

  const reactionMutate = useReactionMutation({
    flowId,
    responseId,
    currentUser: getMemberDetailsFromUserDetails(currentUser.member),
    onError: (error: unknown) => {
      const errorObj = isAxiosError(error) ? error : undefined;
      trackReplyDrawerError({
        errorType:
          errorObj?.response?.data?.message ?? 'Error on post reaction update',
      });
    },
  });

  const { secondaryToolbarItems, handleMenuItemClick } = usePostToolbar({
    kind,
    flowId,
    responseId,
    noOfPoints: getFlowPostPoints(flowResponse),
    isBotPost: isBotPost(flowResponse),
  });

  const navigate = useNavigate();

  const openPreviewer = useOpenFlowsPreviewer();

  const workspaceSlug = `${currentUser.assembly.workspaceSlug.name}-${currentUser.assembly.workspaceSlug.shortCode}`;

  const handleAssemblyPostRedirection = useCallback(
    ({ action, id }: { action: PostCardActions; id: string }) => {
      if (action === PostCardActions.FlowNameClicked) {
        trackReplyDrawerAction('flowClicked', {
          isAnnouncement: Boolean(
            flowResponse?.activeAnnouncement?.announcementId
          ),
          flowId: flowId,
          isRecognition: kind === 'POST',
          flowName: flowResponse?.flow.name ?? 'Recognition',
        });
      }
      const redirectionURL = getURLForAssemblyPostActions({
        action,
        slug: workspaceSlug,
        id,
        flowId: flowResponse?.flow.flowId,
        responseId: flowResponse?.responseId,
      });
      if (redirectionURL) navigate(redirectionURL);
    },
    [
      flowId,
      flowResponse?.activeAnnouncement?.announcementId,
      flowResponse?.flow.flowId,
      flowResponse?.flow.name,
      flowResponse?.responseId,
      kind,
      navigate,
      workspaceSlug,
    ]
  );

  const handleFileClick = (responseBlock: {
    blockId: string;
    fileName: string;
    fileType: string;
    location?: string;
  }) => {
    const { blockId, fileName, fileType, location } = responseBlock;
    trackReplyDrawerAction('fileClicked', {
      flowId: flowId,
      isRecognition: kind === 'POST',
      flowName: flowResponse?.flow.name ?? 'Recognition',
      isAnnouncement: Boolean(flowResponse?.activeAnnouncement?.announcementId),
    });

    openPreviewer({
      blockId,
      fileName,
      flowId,
      responseId,
      workspaceSlug,
      fileType,
      locationUrl: location,
      memberID: currentUser.member.memberId,
      memberName: currentUser.member.name,
      memberImage: currentUser.member.image,
      dateShared: flowResponse?.createdAt ?? '',
      sharedIn: flowResponse?.flow.name ?? '',
      sharedInIcon: flowResponse?.flow.icon,
    });
  };

  const handleReactionClick = (emoji: ReactionDetails) => {
    if (responseId !== '' && flowId !== '') {
      const hasCurrentUserReactedToTheSelectedEmoji = flowResponse
        ? flowResponse.reactions.some(
            (reaction) =>
              reaction.name === emoji.name &&
              reaction.members.some(
                (member) => member.memberID === currentUser.member.memberId
              )
          )
        : undefined;
      const userAction = hasCurrentUserReactedToTheSelectedEmoji
        ? 'postReactionRemoved'
        : 'postReactionAdded';
      trackReplyDrawerAction(userAction, {
        flowId: flowId,
        isRecognition: kind === 'POST',
        flowName: flowResponse?.flow.name ?? 'Recognition',
        isAnnouncement: Boolean(
          flowResponse?.activeAnnouncement?.announcementId
        ),
      });
      reactionMutate({
        payload: {
          displayName: emoji.displayName,
          name: emoji.name,
          type: emoji.type,
          value: emoji.type,
        },
        action: hasCurrentUserReactedToTheSelectedEmoji ? 'unset' : 'set',
      });
    }
  };

  const handlePostCardBodyClick = (action: PostCardActions, id: string) => {
    if (action === PostCardActions.PersonClicked) {
      trackReplyDrawerAction('mentionedMemberClicked', {
        flowId: flowId,
        isRecognition: kind === 'POST',
        flowName: flowResponse?.flow.name ?? 'Recognition',
        isAnnouncement: Boolean(
          flowResponse?.activeAnnouncement?.announcementId
        ),
      });
    }
    handleAssemblyPostRedirection({ action, id });
  };

  const handleBlockToolbarItemClick = async (responseBlock: {
    blockId: string;
    fileName: string;
    itemId: string;
  }) => {
    const { blockId, itemId, fileName } = responseBlock;
    if (itemId === 'copyLink') {
      const baseUrl = config.domains.app;
      const postData: PostData =
        kind === 'POST'
          ? { type: 'post', postId: responseId }
          : { type: 'flow', flowId, responseId };
      const url = getFlowOrRecognitionPostLinkToCopy(postData);
      const urlToCopy = `${baseUrl}${url}`;
      await copyToClipboard(urlToCopy);
      showSuccessToast(formatMessage(messages.copiedToClipboardText));
    }
    if (itemId === 'downloadFile') {
      const request: FileDownloadRequest = {
        blockId: blockId,
        flowId: flowId,
        responseId: responseId,
        instanceId: flowResponse?.instanceId ?? '',
        fileName: fileName,
      };
      try {
        showInfoToast(formatMessage(messages.downloadingFileText));
        await downloadFeedFile(request);
        showSuccessToast(formatMessage(messages.fileDownloadedText));
      } catch (e) {
        showErrorToast(formatMessage(messages.errorDownloadingFileText));
      }
    }
  };

  if (!flowResponse) {
    return <PostLoader />;
  }

  const handlePostToggleClick = (isPostExpanded: boolean) => {
    const action = !isPostExpanded ? 'postCollapsed' : 'postExpanded';
    trackReplyDrawerAction(action, {
      flowId: flowId,
      isRecognition: kind === 'POST',
      flowName: flowResponse.flow.name,
      isAnnouncement: Boolean(flowResponse.activeAnnouncement?.announcementId),
    });
  };

  const handlePostCardClick = (action: PostCardActions, id: string) => {
    handleAssemblyPostRedirection({ action, id });
  };

  return (
    <Post
      boostOptions={boostOptions}
      onMemberClick={navigateToUserFeed}
      postedAt={flowResponse.createdAt}
      secondaryToolbarItems={secondaryToolbarItems}
      onMenuItemClick={handleMenuItemClick}
      currentUser={currentUserDetails}
      postHeaderProps={{
        flowIcon: flowResponse.flow.icon.value,
        flowName: flowResponse.flow.name,
        flowId,
        createdAt: flowResponse.createdAt,
        visibility: flowResponse.visibility,
        onPostCardHeaderClick: handlePostCardClick,
        respondent: flowResponse.respondent,
        isEdited: flowId === 'recognition' && flowResponse.responses[0].edited,
        showFlowDetails: !isRecognitionOnlyCustomer,
        translationElement: (
          <ContentTranslationButton
            isTranslationInProgress={isTranslationInProgress}
            isShowingOriginalContent={isShowingOriginalContentValue}
            onClick={() => {
              isShowingOriginalContentValue
                ? showTranslatedContent(translationPayload)
                : showOriginalContent(translationPayload);
            }}
          />
        ),
      }}
      postBodyProps={{
        flowResponse: flowResponse,
        onFileClick: handleFileClick,
        onPostCardBodyClick: handlePostCardBodyClick,
        currency: currentUser.assembly.currency,
        repliesCount: postCardRepliesDetails?.count ?? 0,
        onBlockToolbarItemClick: handleBlockToolbarItemClick,
      }}
      reactionsBarProps={{
        currentMemberId: currentUser.member.memberId,
        onReactionClick: handleReactionClick,
        reactions: flowResponse.reactions,
        variant: 'default',
      }}
      onPostToggleClick={handlePostToggleClick}
      canHidePoints={canHidePoints}
    />
  );
}
