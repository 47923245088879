import {
  QuickParticipation,
  RecognitionParticipationLoader,
} from '@assembly-web/ui';
import { Suspense, useCallback, useRef } from 'react';

import { useParticipationModalContext } from '../../Provider';
import { Title } from '../header/Title';
import { RecognitionParticipation } from '../RecognitionParticipation';
import { ParticipationWrapper } from './ParticipationWrapper';

export type ParticipationModalProps = {
  onClose: () => void;
};

export function RecognitionParticipationModal() {
  const { isOpen, closeModal, id, defaultForm } =
    useParticipationModalContext();
  const childRef = useRef<ParticipationModalProps>(null);

  const handleCloseModal = useCallback(() => {
    if (childRef.current) {
      childRef.current.onClose();
    } else {
      closeModal();
    }
  }, [closeModal]);

  return (
    <QuickParticipation.Modal
      isOpen={isOpen}
      onClose={handleCloseModal}
      title={<Title />}
      onEscapeKeyDown={(e) => {
        if (document.getElementsByClassName('mentions-menu').length > 0) {
          e.preventDefault();
        }
      }}
      onOpenAutoFocus={(e) => {
        e.preventDefault();
      }}
    >
      <Suspense fallback={<RecognitionParticipationLoader />}>
        <>
          <ParticipationWrapper />
          {Object.keys(defaultForm).length ? (
            <RecognitionParticipation key={id} ref={childRef} />
          ) : (
            <RecognitionParticipationLoader />
          )}
        </>
      </Suspense>
    </QuickParticipation.Modal>
  );
}
