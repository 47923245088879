import { createContext, type ReactNode, useState } from 'react';

export const ModalsContext = createContext<{
  selectedPostOrReplyToDeleteDetails: {
    replyId: string | null;
    flowId: string;
    responseId: string;
    hasPoints?: boolean;
    noOfPoints?: number;
    isBotPost?: boolean;
    isLegacyReply?: boolean;
    challengeId?: string | null;
    onReplyDeleteSuccess?: () => void;
    onEditReplyCancel?: () => void;
  };
  selectedFlowToDeleteDetails: {
    flowId: string;
    onFlowDeleteSuccess?: () => void;
  };
  onPostOrDeleteModalClose: () => void;
  onFlowDeleteModalClose: () => void;
  onDeleteChallengeReplyClick: ({
    replyId,
    challengeId,
    hasPoints,
  }: {
    replyId: string;
    challengeId: string;
    hasPoints: boolean;
  }) => void;
  onDeleteReplyOrPostClick: ({
    replyId,
    flowId,
    responseId,
    hasPoints,
    noOfPoints,
    isBotPost,
    isLegacyReply,
    onReplyDeleteSuccess,
    onEditReplyCancel,
  }: {
    flowId: string;
    responseId: string;
    hasPoints?: boolean;
    noOfPoints?: number;
    isBotPost?: boolean;
    replyId: string | null;
    isLegacyReply?: boolean;
    onEditReplyCancel?: () => void;
    onReplyDeleteSuccess?: () => void;
  }) => void;
  onDeleteFlowClick: ({
    flowId,
    onFlowDeleteSuccess,
  }: {
    flowId: string;
    onFlowDeleteSuccess?: () => void;
  }) => void;
}>({
  selectedPostOrReplyToDeleteDetails: {
    replyId: '',
    flowId: '',
    responseId: '',
    hasPoints: false,
    noOfPoints: undefined,
    isBotPost: undefined,
    isLegacyReply: false,
    onEditReplyCancel: undefined,
    onReplyDeleteSuccess: undefined,
  },
  selectedFlowToDeleteDetails: {
    flowId: '',
    onFlowDeleteSuccess: undefined,
  },
  onPostOrDeleteModalClose: () => {},
  onFlowDeleteModalClose: () => {},
  onDeleteReplyOrPostClick: () => {},
  onDeleteFlowClick: () => {},
  onDeleteChallengeReplyClick: () => {},
});

export const ModalsProvider = ({
  children,
}: {
  children: ReactNode | ReactNode[];
}) => {
  const [
    selectedPostOrReplyToDeleteDetails,
    setSelectedPostOrReplyToDeleteDetails,
  ] = useState<{
    flowId: string;
    responseId: string;
    hasPoints?: boolean;
    noOfPoints?: number;
    isBotPost?: boolean;
    replyId: string | null;
    isLegacyReply?: boolean;
    challengeId?: string | null;
    onEditReplyCancel?: () => void;
    onReplyDeleteSuccess?: () => void;
  }>({
    flowId: '',
    replyId: null,
    responseId: '',
    challengeId: null,
    hasPoints: false,
    noOfPoints: undefined,
    isLegacyReply: false,
    isBotPost: undefined,
    onEditReplyCancel: undefined,
    onReplyDeleteSuccess: undefined,
  });

  const [selectedFlowToDeleteDetails, setSelectedFlowToDeleteDetails] =
    useState<{
      flowId: string;
      onFlowDeleteSuccess?: () => void;
    }>({
      flowId: '',
      onFlowDeleteSuccess: undefined,
    });

  const handleDeletePostOrReplyModalClose = () => {
    setSelectedPostOrReplyToDeleteDetails({
      replyId: null,
      flowId: '',
      responseId: '',
      challengeId: null,
      hasPoints: false,
      isBotPost: undefined,
      isLegacyReply: false,
      onEditReplyCancel: undefined,
      onReplyDeleteSuccess: undefined,
    });
  };

  const handleDeleteFlowModalClose = () => {
    setSelectedFlowToDeleteDetails({
      flowId: '',
      onFlowDeleteSuccess: undefined,
    });
  };

  const handleDeletePostOrReplyClick = ({
    replyId,
    flowId,
    responseId,
    hasPoints,
    noOfPoints,
    isBotPost,
    isLegacyReply,
    onReplyDeleteSuccess,
    onEditReplyCancel,
  }: {
    replyId: string | null;
    flowId: string;
    responseId: string;
    hasPoints?: boolean;
    noOfPoints?: number;
    isBotPost?: boolean;
    isLegacyReply?: boolean;
    onReplyDeleteSuccess?: () => void;
    onEditReplyCancel?: () => void;
  }) => {
    setSelectedPostOrReplyToDeleteDetails({
      replyId,
      flowId,
      responseId,
      hasPoints,
      noOfPoints,
      isBotPost,
      isLegacyReply,
      onReplyDeleteSuccess,
      onEditReplyCancel,
      challengeId: null,
    });
  };

  const handleDeleteFlowClick = ({
    flowId,
    onFlowDeleteSuccess,
  }: {
    flowId: string;
    onFlowDeleteSuccess?: () => void;
  }) => {
    setSelectedFlowToDeleteDetails({
      flowId,
      onFlowDeleteSuccess,
    });
  };

  const handleDeleteChallengeReplyClick = ({
    replyId,
    challengeId,
    hasPoints,
  }: {
    replyId: string;
    challengeId: string;
    hasPoints: boolean;
  }) => {
    setSelectedPostOrReplyToDeleteDetails({
      replyId,
      flowId: '',
      challengeId,
      responseId: '',
      hasPoints,
      noOfPoints: undefined,
      isBotPost: undefined,
    });
  };

  return (
    <ModalsContext.Provider
      value={{
        selectedPostOrReplyToDeleteDetails,
        selectedFlowToDeleteDetails,
        onDeleteReplyOrPostClick: handleDeletePostOrReplyClick,
        onDeleteFlowClick: handleDeleteFlowClick,
        onPostOrDeleteModalClose: handleDeletePostOrReplyModalClose,
        onFlowDeleteModalClose: handleDeleteFlowModalClose,
        onDeleteChallengeReplyClick: handleDeleteChallengeReplyClick,
      }}
    >
      {children}
    </ModalsContext.Provider>
  );
};
