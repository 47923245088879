import { Portal } from '@assembly-web/ui';

import { useAwardFooterRef } from '../../Awards';
import { SubmitButton } from './SubmitButton';
import { VisibilitySelector } from './VisibilitySelector';

export function Footer() {
  return (
    <Portal asChild container={useAwardFooterRef()}>
      <div className="flex justify-center gap-2 sm:justify-between">
        <VisibilitySelector />
        <SubmitButton />
      </div>
    </Portal>
  );
}
