import {
  SplitNames,
  useFeatureSplit,
  useGetPendingAwardApprovals,
} from '@assembly-web/services';

export function usePendingAwardApprovalCount() {
  const { data: pendingApprovals } = useGetPendingAwardApprovals();
  const { isTreatmentActive: isAwardsEnabled } = useFeatureSplit(
    SplitNames.AwardsPage
  );
  try {
    return isAwardsEnabled && pendingApprovals
      ? pendingApprovals.pages[0].total || pendingApprovals.pages[0].data.length
      : 0;
  } catch {
    return 0;
  }
}
