import {
  type BaseEmoji,
  type BlockDataType,
  type BlockType,
  type ContentBlockBaseState,
  type ContentBlockState,
  type ContentGivePointsBlockState,
  type ContentOpenEndedBlockState,
  type ContentScaleBlockState,
  CriteriaRuleType,
  type DeadlineType,
  type FlowBuilderState,
  FlowFrequencyOptions,
  type FlowResponseType,
  getBrowserTimeZone,
  getCalendarDate,
  getNextCalendarDate,
  isGivePointsBlock,
  isLinkedBlock,
  isMultiChoiceOrDropdownBlock,
  isOpenEndedBlock,
  isPersonSelectorBlock,
  isScaleBlock,
  KnownErrorKeys,
  mapHexCodeToEmoticon,
  type MemberDetails,
  type MilestoneFrequencyType,
  type MilestoneType,
  type OptionItemProps,
  type OptionsSelectObject,
  type RemindersType,
  type ResponseFrequencyType,
  type ScheduleRule,
  type SelectableOptionType,
  type SelectablePeopleTypes,
  type ShareCriteria,
  type TimeUnit,
} from '@assembly-web/services';
import type { CalendarDate } from '@internationalized/date';
import type { WritableDraft } from 'immer';
import { useCallback } from 'react';
import type { IntlShape } from 'react-intl';
import { defineMessage, useIntl } from 'react-intl';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { useShallow } from 'zustand/react/shallow';

import {
  useEditorMiscState,
  useSetExpandedBlockId,
} from '../modules/discover/components/Drawers/FlowCreationDrawer/store/useEditorMiscState';
import {
  defaultEndTimeInMinutes,
  defaultReminderAndStartTime,
} from '../modules/discover/components/Drawers/FlowCreationDrawer/utils/distribution';
import { formatFlowCollaboratorToCriteriaAPIResponse } from '../modules/discover/components/Drawers/FlowCreationDrawer/utils/user';
import { useCurrentUserDetail } from '../modules/discover/hooks/useCurrentUserDetail';

type GetBlockOptionProps = {
  id: string;
  blockId: string;
  optionId: string;
};

type FlowBuilderStateKeys = {
  flowId: string;
  flowName: string;
  emoji: BaseEmoji;
  templateId: string;
  description: string;
  inEditMode: boolean;
  canValidate: boolean;
  templateName: string;
  owner: MemberDetails;
  dueDate: CalendarDate;
  dueTime: string | null;
  blockData: BlockDataType;
  remindersDueTime: string;
  numberOfResponses: number;
  remindersTimeZone: string;
  responseTimeValue: number;
  responseTimeUnit: TimeUnit;
  newUsersToInvite: string[];
  deadlineType: DeadlineType;
  skipWeekend: boolean | null;
  remindersType: RemindersType;
  endDate: CalendarDate | null;
  remindersCount: number | null;
  showDataChangeConfirm: boolean;
  currentSchedule?: ScheduleRule;
  collaborators: MemberDetails[];
  reminderSchedule: string | null;
  selectedMilestone: MilestoneType;
  responseFrequencyTimeZone: string;
  remindersStartDate?: CalendarDate;
  errors: FlowBuilderState['errors'];
  isFlowDataChangeConfirmed: boolean;
  flowResponseType: FlowResponseType;
  allowMemberLevelOccurrence: boolean;
  repeatFrequency: FlowFrequencyOptions;
  remindersFrequency: FlowFrequencyOptions;
  numberOfResponseForMilestone: number | null;
  responseFrequencyType: ResponseFrequencyType;
  selectedMilestoneFrequency: MilestoneFrequencyType;
};

type FlowBuilderStateKey = keyof FlowBuilderStateKeys;

type Actions = {
  createFlow: (
    id: string,
    owner: MemberDetails,
    formatMessage: IntlShape['formatMessage'],
    editFlow?: boolean
  ) => string;
  setFlow: (id: string, flow: FlowBuilderState) => string;
  createBlock: (
    id: string,
    blockType: BlockType,
    formatMessage: IntlShape['formatMessage']
  ) => string;
  duplicateBlock: (id: string, blockId: string) => string;
  setBlockTitle: (id: string, blockId: string, updatedTitle: string) => void;
  setBlockDescription: (
    id: string,
    blockId: string,
    updatedDescription: string
  ) => void;
  setSelectableOptionType: (
    id: string,
    blockId: string,
    selection: SelectableOptionType
  ) => void;
  setSelectableOptionDetails: (
    id: string,
    blockId: string,
    mutate: (state: OptionsSelectObject) => OptionsSelectObject
  ) => void;
  setContentLimits: (
    id: string,
    blockId: string,
    type: keyof Pick<
      ContentOpenEndedBlockState,
      'maximumCharacters' | 'minimumCharacters'
    >,
    mutate: (
      state: Pick<
        ContentOpenEndedBlockState,
        'maximumCharacters' | 'minimumCharacters'
      >
    ) => number | undefined
  ) => void;
  setFormattingOptions: (
    id: string,
    blockId: string,
    type:
      | Exclude<keyof ContentOpenEndedBlockState['openEndedOptions'], 'tasks'>
      | 'all'
  ) => void;
  setBlockParticipants: (
    id: string,
    blockId: string,
    selection: SelectablePeopleTypes
  ) => void;
  setCustomParticipants: (
    id: string,
    blockId: string,
    data: {
      criteria: ShareCriteria;
      customSelectionCount: number;
      newUsersToInvite: string[];
    }
  ) => void;
  setGivePointsBlockData: (
    id: string,
    blockId: string,
    mutate: (state: ContentGivePointsBlockState) => ContentGivePointsBlockState
  ) => void;
  setBlockIsRequired: (
    id: string,
    blockId: string,
    isRequired: boolean
  ) => void;
  setFlowEmoji: (id: string, emoji: BaseEmoji) => void;
  setFlowName: (id: string, updatedName: string) => void;
  setFlowDescription: (id: string, updatedDescription: string) => void;
  setFlowPermissions: (
    id: string,
    mutate: (
      state: Pick<FlowBuilderState, 'owner' | 'collaborators'>
    ) => Pick<FlowBuilderState, 'owner' | 'collaborators'>
  ) => void;
  setOptionLabel: (props: GetBlockOptionProps, updatedLabel: string) => void;
  setScaleBlockData: (
    id: string,
    blockId: string,
    mutate: (state: ContentScaleBlockState) => ContentScaleBlockState
  ) => void;
  createOption: (id: string, blockId: string, otherOption?: boolean) => void;
  duplicateOption: (props: GetBlockOptionProps) => void;
  removeOption: (props: GetBlockOptionProps) => void;
  deleteFlow: (id: string) => void;
  deleteBlock: (id: string, blockId: string) => void;
  insertBlock: (
    id: string,
    blockType: BlockType,
    index: number,
    formatMessage: IntlShape['formatMessage']
  ) => string;
  moveBlock: (
    id: string,
    blockId: string,
    data: { fromIndex: number; toIndex: number }
  ) => void;
  replaceBlock: (
    id: string,
    blockId: string,
    block: ContentBlockState['type'],
    formatMessage: IntlShape['formatMessage']
  ) => string;
  initializeCustomParticipantsCount: (
    id: string,
    blockId: string,
    count: number
  ) => void;
  setError: (id: string, errors: FlowBuilderState['errors']) => void;
  getBlockIndex: (id: string, blockId: string) => number;
  setFlowBuilderState: <K extends FlowBuilderStateKey>(
    id: string,
    key: K,
    value: FlowBuilderStateKeys[K]
  ) => void;
};

type FlowBuilderStore = { flows: Record<string, FlowBuilderState | undefined> };

export const useFlowBuilderStore = create<FlowBuilderStore & Actions>()(
  immer((set, get) => ({
    flows: {},
    createFlow(id, owner, formatMessage, editFlow) {
      let createdBlockId = '';

      set((state) => {
        if (id in state.flows) {
          return;
        }

        state.flows[id] = {
          flowId: window.crypto.randomUUID(),
          blockData: getDefaultEditorState(formatMessage, editFlow),
          currentSchedule: undefined,
          isFlowDataChangeConfirmed: false,
          showDataChangeConfirm: false,
          description: '',
          emoji: {
            id: '+1',
            skin: 1,
            name: ':+1:',
            shortcodes: ':+1:',
            unified: '1f44d',
            emoticons: [],
            native: mapHexCodeToEmoticon('1f44d'),
          },
          owner: [
            {
              email: owner.email ?? '',
              name: owner.name,
              image: owner.image ?? '',
              memberID: owner.memberID,
              state: owner.memberState,
            },
          ],
          collaborators: [],
          flowName: '',
          templateId: '',
          templateName: '',
          inEditMode: false,
          deadlineType: 'schedule',
          remindersType: 'automate',
          flowResponseType: 'anytime',
          responseFrequencyType: 'recurring',
          responseTimeUnit: 'weeks',
          responseTimeValue: 1,
          numberOfResponses: 1,
          errors: null,
          canValidate: false,
          dueDate: getNextCalendarDate(new Date()),
          endDate: null,
          dueTime: null,
          repeatFrequency: FlowFrequencyOptions.Weekly,
          responseFrequencyTimeZone: null,
          remindersCount: 1,
          remindersFrequency: FlowFrequencyOptions.Weekly,
          remindersDueTime: defaultReminderAndStartTime,
          remindersTimeZone: null,
          reminderSchedule: null,
          newUsersToInvite: [],
          skipWeekend: true,
          remindersStartDate: getNextCalendarDate(new Date()),
          allowMemberLevelOccurrence: false,
          numberOfResponseForMilestone: 1,
          selectedMilestoneFrequency: 'everyDay',
          selectedMilestone: 'startDate',
        };

        createdBlockId =
          state.flows[id].blockData.CONTENT.contentBlocks[0]?.id || '';
      });

      return createdBlockId;
    },
    setFlow(id, flow) {
      let createdBlockId = '';

      set((state) => {
        state.flows[id] = flow;

        createdBlockId = flow.blockData.CONTENT.contentBlocks[0].id;
      });

      return createdBlockId;
    },
    createBlock(id, blockType, formatMessage) {
      let createdBlockId = '';

      set((state) => {
        const flow = state.flows[id];

        if (!flow) {
          return;
        }

        const commonBlockData = getDefaultBlock();

        flow.blockData.CONTENT.contentBlocks = [
          ...flow.blockData.CONTENT.contentBlocks,
          ...createBlockData(commonBlockData, blockType, formatMessage),
        ];

        createdBlockId =
          flow.blockData.CONTENT.contentBlocks[
            flow.blockData.CONTENT.contentBlocks.length - 1
          ].id;
      });

      return createdBlockId;
    },
    replaceBlock(id, blockId, blockType, formatMessage) {
      let createdBlockId = '';

      set((state) => {
        const flow = state.flows[id];

        if (!flow) {
          return createdBlockId;
        }

        let blockIndex = flow.blockData.CONTENT.contentBlocks.findIndex(
          (block) => block.id === blockId
        );

        if (blockIndex < 0) {
          return createdBlockId;
        }

        const block = flow.blockData.CONTENT.contentBlocks[blockIndex];

        if (isLinkedBlock(block)) {
          const givePointsBlockIndex =
            flow.blockData.CONTENT.contentBlocks.findIndex(
              (block) => block.type === 'GIVE_POINTS_STACK'
            );
          flow.blockData.CONTENT.contentBlocks.splice(givePointsBlockIndex, 1);

          blockIndex = flow.blockData.CONTENT.contentBlocks.findIndex(
            (block) => block.id === blockId
          );
          if (blockIndex < 0) {
            return createdBlockId;
          }
        }

        if (isGivePointsBlock(block)) {
          const personSelectBlockIndex =
            flow.blockData.CONTENT.contentBlocks.findIndex((block) =>
              isLinkedBlock(block)
            );
          flow.blockData.CONTENT.contentBlocks.splice(
            personSelectBlockIndex,
            1
          );

          blockIndex = flow.blockData.CONTENT.contentBlocks.findIndex(
            (block) => block.id === blockId
          );
          if (blockIndex < 0) {
            return createdBlockId;
          }
        }

        if (blockType === 'GIVE_POINTS_STACK') {
          const [personSelectBlock, givePointsBlock] = createBlockData(
            getDefaultBlock(),
            blockType,
            formatMessage
          );
          flow.blockData.CONTENT.contentBlocks[blockIndex] = {
            ...personSelectBlock,
            title: block.title,
            description: block.description,
          };
          flow.blockData.CONTENT.contentBlocks.push(givePointsBlock);
          createdBlockId = personSelectBlock.id;
        } else {
          const [ceratedBlock] = createBlockData(
            getDefaultBlock(),
            blockType,
            formatMessage
          );
          flow.blockData.CONTENT.contentBlocks[blockIndex] = {
            ...ceratedBlock,
            title: block.title,
            description: block.description,
            isRequired: block.isRequired,
          };
          createdBlockId = ceratedBlock.id;
        }
      });

      return createdBlockId;
    },
    duplicateBlock(id, blockId) {
      let createdBlockId = '';

      set((state) => {
        const flow = state.flows[id];

        if (!flow) {
          return;
        }

        const blockIndex = flow.blockData.CONTENT.contentBlocks.findIndex(
          (block) => block.id === blockId
        );

        if (
          blockIndex < 0 ||
          flow.blockData.CONTENT.contentBlocks[blockIndex].isLinkedBlock ||
          isGivePointsBlock(flow.blockData.CONTENT.contentBlocks[blockIndex])
        ) {
          return;
        }

        flow.blockData.CONTENT.contentBlocks.splice(blockIndex + 1, 0, {
          ...flow.blockData.CONTENT.contentBlocks[blockIndex],
          id: window.crypto.randomUUID(),
        });

        createdBlockId =
          flow.blockData.CONTENT.contentBlocks[blockIndex + 1].id;
      });

      return createdBlockId;
    },
    setBlockTitle(id, blockId, updatedTitle) {
      set((state) => {
        const flow = state.flows[id];

        if (!flow) {
          return;
        }

        const block = flow.blockData.CONTENT.contentBlocks.find(
          (block) => block.id === blockId
        );

        if (!block) {
          return;
        }

        block.title = updatedTitle;
      });
    },
    setBlockDescription(id, blockId, updatedDescription) {
      set((state) => {
        const flow = state.flows[id];

        if (!flow) {
          return;
        }

        const block = flow.blockData.CONTENT.contentBlocks.find(
          (block) => block.id === blockId
        );

        if (!block) {
          return;
        }

        block.description = updatedDescription;
      });
    },
    setSelectableOptionType(id, blockId, selection) {
      set((state) => {
        const flow = state.flows[id];

        if (!flow) {
          return;
        }

        const block = flow.blockData.CONTENT.contentBlocks.find(
          (block) => block.id === blockId
        );

        if (
          !block ||
          !(isPersonSelectorBlock(block) || isMultiChoiceOrDropdownBlock(block))
        ) {
          return;
        }

        block.optionType = selection;
        block.optionSelectObject = {
          type: 'EXACT_NUMBER',
          exactOptions: 1,
        };
      });
    },
    setSelectableOptionDetails(id, blockId, mutate) {
      set((state) => {
        const flow = state.flows[id];

        if (!flow) {
          return;
        }

        const block = flow.blockData.CONTENT.contentBlocks.find(
          (block) => block.id === blockId
        );

        if (
          !block ||
          !(isPersonSelectorBlock(block) || isMultiChoiceOrDropdownBlock(block))
        ) {
          return;
        }

        block.optionSelectObject = mutate(block.optionSelectObject);
      });
    },
    setContentLimits(id, blockId, type, mutate) {
      set((state) => {
        const flow = state.flows[id];

        if (!flow) {
          return;
        }

        let block = flow.blockData.CONTENT.contentBlocks.find(
          (block) => block.id === blockId
        );

        if (!block || !isOpenEndedBlock(block)) {
          return;
        }

        const value = mutate({
          maximumCharacters: block.maximumCharacters,
          minimumCharacters: block.minimumCharacters,
        });

        if (type === 'minimumCharacters') {
          if ((value ?? 0) > 0) {
            block.isRequired = true;
          }
        }

        block[type] = value;
      });
    },
    setFormattingOptions(id, blockId, type) {
      set((state) => {
        const flow = state.flows[id];

        if (!flow) {
          return;
        }

        let block = flow.blockData.CONTENT.contentBlocks.find(
          (block) => block.id === blockId
        );

        if (!block || !isOpenEndedBlock(block)) {
          return;
        }

        if (type === 'all') {
          const isAllSelected = Object.entries(block.openEndedOptions).every(
            ([, value]) => value
          );
          if (!isAllSelected) {
            block.openEndedOptions = {
              attachments: true,
              emojis: true,
              gifs: true,
              mentions: true,
              tasks: true,
            };
          } else {
            block.openEndedOptions = {
              attachments: false,
              emojis: false,
              gifs: false,
              mentions: false,
              tasks: false,
            };
          }
          return;
        }

        block.openEndedOptions[type] = !block.openEndedOptions[type];
      });
    },
    setBlockParticipants(id, blockId, selection) {
      set((state) => {
        const flow = state.flows[id];

        if (!flow) {
          return;
        }

        const block = flow.blockData.CONTENT.contentBlocks.find(
          (block) => block.id === blockId
        );

        if (!block || !isPersonSelectorBlock(block)) {
          return;
        }

        block.selectedBlockParticipants = selection;
        block.customPersonSelectorCount = 0;
        block.criteriaGroups = undefined;
      });
    },
    setCustomParticipants(
      id,
      blockId,
      { criteria, customSelectionCount, newUsersToInvite }
    ) {
      set((state) => {
        const flow = state.flows[id];

        if (!flow) {
          return;
        }

        const block = flow.blockData.CONTENT.contentBlocks.find(
          (block) => block.id === blockId
        );

        if (!block || !isPersonSelectorBlock(block)) {
          return;
        }

        block.selectedBlockParticipants = 'CUSTOM';
        block.customPersonSelectorCount = customSelectionCount;
        block.criteriaGroups = criteria;
        flow.newUsersToInvite = newUsersToInvite;

        if (block.optionSelectObject.type === 'RANGE') {
          block.optionSelectObject.maxOptions =
            block.optionSelectObject.maxOptions > customSelectionCount
              ? customSelectionCount
              : block.optionSelectObject.maxOptions;
          block.optionSelectObject.minOptions =
            block.optionSelectObject.minOptions > customSelectionCount
              ? customSelectionCount
              : block.optionSelectObject.minOptions;
        }
        if (block.optionSelectObject.type === 'EXACT_NUMBER') {
          block.optionSelectObject.exactOptions =
            block.optionSelectObject.exactOptions > customSelectionCount
              ? customSelectionCount
              : block.optionSelectObject.exactOptions;
        }
      });
    },
    initializeCustomParticipantsCount(id, blockId, count) {
      set((state) => {
        const flow = state.flows[id];

        if (!flow) {
          return;
        }

        const block = flow.blockData.CONTENT.contentBlocks.find(
          (block) => block.id === blockId
        );

        if (!block || !isPersonSelectorBlock(block)) {
          return;
        }

        block.customPersonSelectorCount = count;
        block.initialized = true;
      });
    },
    setGivePointsBlockData(id, blockId, mutate) {
      set((state) => {
        const flow = state.flows[id];

        if (!flow) {
          return;
        }

        let block = flow.blockData.CONTENT.contentBlocks.find(
          (block) => block.id === blockId
        );

        if (block && isGivePointsBlock(block)) {
          block = mutate(block);
        }
      });
    },
    setScaleBlockData(id, blockId, mutate) {
      set((state) => {
        const flow = state.flows[id];

        if (!flow) {
          return;
        }

        let block = flow.blockData.CONTENT.contentBlocks.find(
          (block) => block.id === blockId
        );

        if (block && isScaleBlock(block)) {
          block = mutate(block);
          return;
        }
      });
    },
    setFlowBuilderState(id, key, value) {
      set((state) => {
        const flow = state.flows[id];

        if (!flow || !(key in flow)) {
          return;
        }

        flow[key] =
          value as unknown as WritableDraft<FlowBuilderState>[typeof key];
      });
    },
    setBlockIsRequired(id, blockId, isRequired) {
      set((state) => {
        const flow = state.flows[id];

        if (!flow) {
          return;
        }

        const block = flow.blockData.CONTENT.contentBlocks.find(
          (block) => block.id === blockId
        );

        if (!block) {
          return;
        }

        if (isOpenEndedBlock(block) && (block.minimumCharacters ?? 0) >= 1) {
          block.isRequired = true;
          return;
        }

        block.isRequired = isRequired;
      });
    },
    setFlowEmoji(id, emoji) {
      set((state) => {
        const flow = state.flows[id];

        if (!flow) {
          return;
        }

        flow.emoji = {
          shortcodes: emoji.shortcodes,
          emoticons: emoji.emoticons,
          id: emoji.id,
          name: emoji.name,
          native: emoji.native,
          skin: emoji.skin,
          unified: emoji.unified,
        };
      });
    },
    setFlowName(id, updatedName) {
      set((state) => {
        const flow = state.flows[id];

        if (!flow) {
          return;
        }

        flow.flowName = updatedName;
      });
    },
    setFlowDescription(id, updatedDescription) {
      set((state) => {
        const flow = state.flows[id];

        if (!flow) {
          return;
        }

        flow.description = updatedDescription;
      });
    },
    setFlowPermissions(id, mutate) {
      set((state) => {
        const flow = state.flows[id];

        if (!flow) {
          return;
        }

        const updatedPermissions = mutate(flow);
        flow.owner = updatedPermissions.owner;
        flow.collaborators = updatedPermissions.collaborators;
      });
    },
    setOptionLabel(props, updatedLabel) {
      set((state) => {
        const block = state.flows[
          props.id
        ]?.blockData.CONTENT.contentBlocks.find(
          (block) => block.id === props.blockId
        );

        if (
          !block ||
          (block.type !== 'DROPDOWN' && block.type !== 'MULTI_CHOICE')
        ) {
          return;
        }

        const option = block.options.find(
          ({ value }) => value === props.optionId
        );

        if (option) {
          option.label = updatedLabel;
        }
      });
    },
    createOption(id, blockId, otherOption) {
      set((state) => {
        const block = state.flows[id]?.blockData.CONTENT.contentBlocks.find(
          (block) => block.id === blockId
        );

        if (
          !block ||
          (block.type !== 'DROPDOWN' && block.type !== 'MULTI_CHOICE')
        ) {
          return;
        }

        let otherOptionExists =
          block.type === 'MULTI_CHOICE' &&
          block.options.some(({ value }) => value === 'other');

        if (otherOption && otherOptionExists) {
          return;
        }

        const addOtherOption = otherOption && !otherOptionExists;

        const option: OptionItemProps = {
          ...(addOtherOption
            ? {
                value: 'other',
                autoFocus: true,
              }
            : {
                value: window.crypto.randomUUID(),
              }),
          label: '',
          defaultLabel: `Option ${block.options.length + 1}`,
        };

        if (addOtherOption) {
          block.options.push(option);
        } else {
          block.options.splice(
            otherOptionExists ? block.options.length - 1 : block.options.length,
            0,
            option
          );
        }
      });
    },
    duplicateOption(props) {
      set((state) => {
        const block = state.flows[
          props.id
        ]?.blockData.CONTENT.contentBlocks.find(
          (block) => block.id === props.blockId
        );

        if (
          !block ||
          (block.type !== 'DROPDOWN' && block.type !== 'MULTI_CHOICE')
        ) {
          return;
        }

        const option = block.options.find(
          ({ value }) => value !== 'other' && value === props.optionId
        );

        if (!option) {
          return;
        }

        const index = block.options.findIndex(
          ({ value }) => value === props.optionId
        );

        block.options.splice(index + 1, 0, {
          ...option,
          value: window.crypto.randomUUID(),
        });
      });
    },
    removeOption(props) {
      set((state) => {
        const block = state.flows[
          props.id
        ]?.blockData.CONTENT.contentBlocks.find(
          (block) => block.id === props.blockId
        );

        if (
          !block ||
          (block.type !== 'DROPDOWN' && block.type !== 'MULTI_CHOICE')
        ) {
          return;
        }

        const index = block.options.findIndex(
          ({ value }) => value === props.optionId
        );

        if (index === -1) {
          return;
        }

        block.options.splice(index, 1);
      });
    },
    deleteFlow(id) {
      set((state) => {
        if (id in state.flows) {
          state.flows = Object.fromEntries(
            Object.entries(state.flows).filter(([key]) => key !== id)
          );
        }
      });
    },
    deleteBlock(id, blockId) {
      set((state) => {
        const flow = state.flows[id];

        if (!flow) {
          return;
        }

        const block = flow.blockData.CONTENT.contentBlocks.find(
          (block) => block.id === blockId
        );

        if (!block) {
          return;
        }

        if (isLinkedBlock(block)) {
          flow.blockData.CONTENT.contentBlocks =
            flow.blockData.CONTENT.contentBlocks.filter(
              (block) => !isGivePointsBlock(block)
            );
        } else if (isGivePointsBlock(block)) {
          flow.blockData.CONTENT.contentBlocks =
            flow.blockData.CONTENT.contentBlocks.filter(
              (block) => !isLinkedBlock(block)
            );
        }

        flow.blockData.CONTENT.contentBlocks =
          flow.blockData.CONTENT.contentBlocks.filter(
            (block) => block.id !== blockId
          );
      });
    },
    insertBlock(id, blockType, index, formatMessage) {
      let createdBlockId = '';

      set((state) => {
        const flow = state.flows[id];

        if (!flow) {
          return;
        }

        const commonBlockData = getDefaultBlock();

        flow.blockData.CONTENT.contentBlocks.splice(
          index + 1,
          0,
          ...createBlockData(commonBlockData, blockType, formatMessage)
        );

        createdBlockId = flow.blockData.CONTENT.contentBlocks[index + 1].id;
      });

      return createdBlockId;
    },
    moveBlock(id, blockId, data) {
      set((state) => {
        const flow = state.flows[id];

        if (!flow) {
          return;
        }

        const blockIndex = flow.blockData.CONTENT.contentBlocks.findIndex(
          (block) => block.id === blockId
        );

        if (blockIndex < 0) {
          return;
        }

        const block = flow.blockData.CONTENT.contentBlocks[blockIndex];
        flow.blockData.CONTENT.contentBlocks.splice(blockIndex, 1);
        flow.blockData.CONTENT.contentBlocks.splice(data.toIndex, 0, block);
      });
    },
    setError(id, errors) {
      set((state) => {
        const flow = state.flows[id];

        if (!flow) {
          return;
        }

        flow.errors = errors;
        flow.canValidate = true;
      });
    },
    getBlockIndex(id, blockId) {
      const { flows } = get();

      const flow = flows[id];

      if (!flow) {
        return -1;
      }

      return flow.blockData.CONTENT.contentBlocks.findIndex(
        (block) => block.id === blockId
      );
    },
  }))
);

export function getDefaultEditorState(
  formatMessage: IntlShape['formatMessage'],
  editFlow?: boolean
): BlockDataType {
  return {
    ACTION: null,
    TRIGGER: {
      triggerType: undefined,
      endTimeInMinutes: defaultEndTimeInMinutes,
      shortcut: false,
      schedule: undefined,
      selectedCustomRecurrenceTypes: undefined,
      isSchedulerTouched: false,
      errors: [],
    },
    PARTICIPANTS: {
      participantsCriteria: undefined,
      errors: null,
    },
    VISIBILITY: {
      type: 'EVERYONE',
      everyone: true,
      onlyParticipants: false,
      onlyOwnersAndCollaborators: false,
      custom: false,
    },
    CONTENT: {
      contentBlocks: editFlow
        ? []
        : [...createBlockData(getDefaultBlock(), 'OPEN_ENDED', formatMessage)],
      errors: null,
    },
  };
}

function getDefaultBlock(): ContentBlockBaseState {
  const id = window.crypto.randomUUID();
  return {
    id: id,
    type: 'OPEN_ENDED',
    description: '',
    isLinkedBlock: false,
    isRequired: false,
    title: '',
  };
}

function createBlockData(
  defaultBlockData: ContentBlockBaseState,
  blockType: BlockType,
  formatMessage: IntlShape['formatMessage']
): ContentBlockState[] {
  if (blockType === 'PERSON_SELECTOR') {
    return [
      {
        ...defaultBlockData,
        type: 'PERSON_SELECTOR',
        optionType: 'SINGLE',
        optionSelectObject: {
          type: 'EXACT_NUMBER',
          exactOptions: 1,
        },
        selectedBlockParticipants: 'EVERYONE',
        initialized: true,
      },
    ];
  }
  if (blockType === 'DROPDOWN' || blockType === 'MULTI_CHOICE') {
    return [
      {
        ...defaultBlockData,
        type: blockType,
        choices: [],
        optionSelectObject: {
          type: 'EXACT_NUMBER',
          exactOptions: 1,
        },
        maximumSelectableOptions: 1,
        options: [],
        optionType: 'SINGLE',
      },
    ];
  }
  if (blockType === 'SCALE') {
    return [
      {
        ...defaultBlockData,
        type: 'SCALE',
        maximumRange: 10,
        minimumRange: 0,
        lowLabel: '',
        highLabel: '',
        middleLabel: '',
      },
    ];
  }
  if (blockType === 'NPS') {
    return [
      {
        ...defaultBlockData,
        type: 'NPS',
      },
    ];
  }
  if (blockType === 'GIF') {
    return [
      {
        ...defaultBlockData,
        type: 'GIF',
      },
    ];
  }
  if (blockType === 'FILE_UPLOAD') {
    return [
      {
        ...defaultBlockData,
        type: 'FILE_UPLOAD',
      },
    ];
  }
  if (blockType === 'GIVE_POINTS_STACK') {
    const title = defineMessage({
      defaultMessage: 'Who would you like to celebrate?',
      id: '2iD0/h',
    });
    return [
      {
        ...createBlockData(
          { ...defaultBlockData },
          'PERSON_SELECTOR',
          formatMessage
        )[0],
        isLinkedBlock: true,
        title: formatMessage(title),
      },
      {
        ...defaultBlockData,
        id: window.crypto.randomUUID(),
        type: 'GIVE_POINTS_STACK',
        limitAmountDetails: undefined,
        hideCurrencyValues: false,
        isLinkedBlock: true,
      },
    ];
  }
  return [
    {
      ...defaultBlockData,
      type: 'OPEN_ENDED',
      openEndedOptions: {
        attachments: true,
        emojis: true,
        gifs: true,
        mentions: true,
        tasks: true,
      },
      maximumCharacters: undefined,
      minimumCharacters: undefined,
    },
  ];
}

export const useCreateFlow = () => {
  const { formatMessage } = useIntl();
  const createFlow = useFlowBuilderStore((state) => state.createFlow);
  const setExpandedBlockId = useSetExpandedBlockId();
  const owner = useCurrentUserDetail();

  return useCallback(
    (id: string, editFlow?: boolean) => {
      return new Promise<string>((resolve) => {
        const createdBlockId = createFlow(id, owner, formatMessage, editFlow);
        setExpandedBlockId(id, createdBlockId);
        resolve(createdBlockId);
      });
    },
    [createFlow, owner, formatMessage, setExpandedBlockId]
  );
};

export const useSetFlow = () => {
  const setFlow = useFlowBuilderStore((state) => state.setFlow);
  const setExpandedBlockId = useSetExpandedBlockId();

  return useCallback(
    (...params: Parameters<typeof setFlow>) => {
      const createdBlockId = setFlow(...params);
      setExpandedBlockId(params[0], createdBlockId);
    },
    [setFlow, setExpandedBlockId]
  );
};

export const useGetStateForValidation = (id: string) => {
  return useFlowBuilderStore(
    useShallow((state) => ({
      flowTitle: state.flows[id]?.flowName,
      blockData: state.flows[id]?.blockData,
    }))
  );
};

export const useGetCanValidate = (id: string) => {
  return useFlowBuilderStore(
    useShallow((state) => state.flows[id]?.canValidate ?? false)
  );
};

export const useCreateBlock = (id: string) => {
  const { formatMessage } = useIntl();
  const createBlock = useFlowBuilderStore((state) => state.createBlock);
  const setExpandedBlockId = useSetExpandedBlockId(id);

  return useCallback(
    (blockType: BlockType) => {
      const createdBlockId = createBlock(id, blockType, formatMessage);
      setExpandedBlockId(createdBlockId);
    },
    [createBlock, formatMessage, id, setExpandedBlockId]
  );
};

export const useReplaceBlock = (id: string, blockId: string) => {
  const { formatMessage } = useIntl();
  const replaceBlock = useFlowBuilderStore((state) => state.replaceBlock);
  const setExpandedBlockId = useSetExpandedBlockId(id);

  return useCallback(
    (blockType: ContentBlockState['type']) => {
      const expandedBlockId =
        useEditorMiscState.getState().flows[id]?.expandedBlockId ?? null;
      const createdBlockId = replaceBlock(
        id,
        blockId,
        blockType,
        formatMessage
      );
      if (expandedBlockId === blockId) {
        setExpandedBlockId(createdBlockId);
      }
    },
    [replaceBlock, id, blockId, formatMessage, setExpandedBlockId]
  );
};

export const useDuplicateBlock = (id: string, blockId: string) => {
  const duplicateBlock = useFlowBuilderStore((state) => state.duplicateBlock);
  const setExpandedBlockId = useSetExpandedBlockId(id);

  return useCallback(() => {
    const createdBlockId = duplicateBlock(id, blockId);
    setExpandedBlockId(createdBlockId);
  }, [duplicateBlock, id, blockId, setExpandedBlockId]);
};

export const useInsertBlock = (id: string, index: number) => {
  const { formatMessage } = useIntl();
  const insertBlock = useFlowBuilderStore((state) => state.insertBlock);
  const setExpandedBlockId = useSetExpandedBlockId(id);

  return useCallback(
    (blockType: BlockType) => {
      const createdBlockId = insertBlock(id, blockType, index, formatMessage);
      setExpandedBlockId(createdBlockId);
    },
    [insertBlock, id, index, formatMessage, setExpandedBlockId]
  );
};

export const useMoveBlock = (id: string, blockId: string) => {
  const moveBlock = useFlowBuilderStore((state) => state.moveBlock);

  return useCallback(
    (data: Parameters<typeof moveBlock>['2']) => moveBlock(id, blockId, data),
    [moveBlock, id, blockId]
  );
};

export const useCanMoveBlock = (id: string, blockId: string) => {
  const listBlocks = useListBlocks(id);
  const blockIndex = useBlockIndex(id, blockId);

  return {
    moveUp: blockIndex > 0,
    moveDown: blockIndex < listBlocks.length - 1,
  };
};

export const useSetFlowEmoji = (id: string) => {
  const setFlowEmoji = useFlowBuilderStore((state) => state.setFlowEmoji);

  return useCallback(
    (emoji: Parameters<typeof setFlowEmoji>['1']) => {
      setFlowEmoji(id, emoji);
    },
    [setFlowEmoji, id]
  );
};

export const useSetBlockTitle = (id: string, blockId: string) => {
  const setTitle = useFlowBuilderStore((state) => state.setBlockTitle);

  return useCallback(
    (updatedTitle: string) => setTitle(id, blockId, updatedTitle),
    [setTitle, id, blockId]
  );
};

export const useSetBlockDescription = (id: string, blockId: string) => {
  const setDescription = useFlowBuilderStore(
    (state) => state.setBlockDescription
  );

  return useCallback(
    (updatedDescription: string) =>
      setDescription(id, blockId, updatedDescription),
    [setDescription, id, blockId]
  );
};

export const useSetSelectableOption = (id: string, blockId: string) => {
  const setSelectableOption = useFlowBuilderStore(
    (state) => state.setSelectableOptionType
  );

  return useCallback(
    (selection: SelectableOptionType) =>
      setSelectableOption(id, blockId, selection),
    [setSelectableOption, id, blockId]
  );
};

export const useSetSelectableOptionDetails = (id: string, blockId: string) => {
  const setSelectableOptionDetails = useFlowBuilderStore(
    (state) => state.setSelectableOptionDetails
  );

  return useCallback(
    (mutate: (state: OptionsSelectObject) => OptionsSelectObject) =>
      setSelectableOptionDetails(id, blockId, mutate),
    [setSelectableOptionDetails, id, blockId]
  );
};

export const useSetBlockParticipants = (id: string, blockId: string) => {
  const setBlockParticipants = useFlowBuilderStore(
    (state) => state.setBlockParticipants
  );

  return useCallback(
    (selection: SelectablePeopleTypes) =>
      setBlockParticipants(id, blockId, selection),
    [setBlockParticipants, id, blockId]
  );
};

export const useSetCustomParticipants = (id: string, blockId: string) => {
  const setCustomParticipants = useFlowBuilderStore(
    (state) => state.setCustomParticipants
  );

  return useCallback(
    (data: {
      criteria: ShareCriteria;
      customSelectionCount: number;
      newUsersToInvite: string[];
    }) => setCustomParticipants(id, blockId, data),
    [setCustomParticipants, id, blockId]
  );
};

export const useGetDeadlineType = (id: string) => {
  return useFlowBuilderStore(
    useShallow((state) => state.flows[id]?.deadlineType ?? 'schedule')
  );
};

export const useGetRemindersType = (id: string) => {
  return useFlowBuilderStore(
    useShallow((state) => state.flows[id]?.remindersType ?? 'automate')
  );
};

export const useGetFlowResponseType = (id: string) => {
  return useFlowBuilderStore(
    useShallow((state) => state.flows[id]?.flowResponseType ?? 'anytime')
  );
};

export const useGetResponseFrequencyType = (id: string) => {
  return useFlowBuilderStore(
    useShallow((state) => state.flows[id]?.responseFrequencyType ?? 'recurring')
  );
};

export const useGetResponseTimeUnit = (id: string) => {
  return useFlowBuilderStore(
    useShallow((state) => state.flows[id]?.responseTimeUnit ?? 'weeks')
  );
};

export const useGetResponseTimeValue = (id: string) => {
  return useFlowBuilderStore(
    useShallow((state) => state.flows[id]?.responseTimeValue ?? 1)
  );
};

export const useGetDueDate = (id: string) => {
  return useFlowBuilderStore(
    useShallow(
      (state) => state.flows[id]?.dueDate ?? getCalendarDate(new Date())
    )
  );
};

export const useGetRemindersStartDate = (id: string) => {
  return useFlowBuilderStore(
    useShallow(
      (state) =>
        state.flows[id]?.remindersStartDate ?? getCalendarDate(new Date())
    )
  );
};

export const useGetRepeatFrequency = (id: string) => {
  return useFlowBuilderStore(
    useShallow(
      (state) => state.flows[id]?.repeatFrequency ?? FlowFrequencyOptions.Weekly
    )
  );
};

export const useGetResponseFrequencyTimeZone = (id: string) => {
  return useFlowBuilderStore(
    useShallow((state) => state.flows[id]?.responseFrequencyTimeZone ?? null)
  );
};

export const useInitializeCustomParticipantsCount = (
  id: string,
  blockId: string
) => {
  const initializeCustomParticipantsCount = useFlowBuilderStore(
    (state) => state.initializeCustomParticipantsCount
  );

  return useCallback(
    (count: number) => initializeCustomParticipantsCount(id, blockId, count),
    [initializeCustomParticipantsCount, id, blockId]
  );
};

export const useDataExists = (id: string) => {
  return useFlowBuilderStore(
    useShallow((state) => (state.flows[id]?.flowName?.length ?? 0) > 0)
  );
};

export const useListBlocks = (id: string) => {
  return useFlowBuilderStore(
    useShallow(
      (state) =>
        state.flows[id]?.blockData.CONTENT.contentBlocks.map(
          (block) => block.id
        ) ?? []
    )
  );
};

export const useBlockIndex = (id: string, blockId: string) => {
  return useFlowBuilderStore(
    useShallow((state) => state.getBlockIndex(id, blockId))
  );
};

export const useBlockType = (id: string, blockId: string) => {
  return useFlowBuilderStore(
    useShallow(
      (state) =>
        state.flows[id]?.blockData.CONTENT.contentBlocks.find(
          (block) => block.id === blockId
        )?.type ?? null
    )
  );
};

export const useCanShowInsertBlockMenu = (id: string, blockId: string) => {
  const listBlocks = useListBlocks(id);
  const index = useBlockIndex(id, blockId);

  return index !== listBlocks.length - 1;
};

export const useIsRequiredBlock = (id: string, blockId: string) => {
  return useFlowBuilderStore(
    useShallow(
      (state) =>
        state.flows[id]?.blockData.CONTENT.contentBlocks.find(
          (block) => block.id === blockId
        )?.isRequired ?? false
    )
  );
};

export const useSetIsRequiredBlock = (id: string, blockId: string) => {
  const setBlockIsRequired = useFlowBuilderStore(
    (state) => state.setBlockIsRequired
  );

  return useCallback(
    (isRequired: boolean) => setBlockIsRequired(id, blockId, isRequired),
    [setBlockIsRequired, id, blockId]
  );
};

export const useSetFlowName = (id: string) => {
  const setFlowName = useFlowBuilderStore((state) => state.setFlowName);

  return useCallback(
    (updatedName: string) => setFlowName(id, updatedName),
    [setFlowName, id]
  );
};

export const useSetFlowDescription = (id: string) => {
  const setFlowDescription = useFlowBuilderStore(
    (state) => state.setFlowDescription
  );

  return useCallback(
    (updatedDescription: string) => setFlowDescription(id, updatedDescription),
    [setFlowDescription, id]
  );
};

export const useSetFlowPermissions = (id: string) => {
  const setFlowPermissions = useFlowBuilderStore(
    (state) => state.setFlowPermissions
  );

  return useCallback(
    (mutate: Parameters<typeof setFlowPermissions>[1]) =>
      setFlowPermissions(id, mutate),
    [setFlowPermissions, id]
  );
};

export const useDeleteBlock = (id: string, blockId: string) => {
  const deleteBlock = useFlowBuilderStore((state) => state.deleteBlock);

  return useCallback(
    () => deleteBlock(id, blockId),
    [deleteBlock, id, blockId]
  );
};

export const useSetOptionLabel = ({
  blockId,
  id,
  optionId,
}: GetBlockOptionProps) => {
  const setBlockTitle = useFlowBuilderStore((state) => state.setOptionLabel);

  return useCallback(
    (updatedLabel: string) =>
      setBlockTitle({ id, optionId, blockId }, updatedLabel),
    [setBlockTitle, id, optionId, blockId]
  );
};

export const useCreateOption = (id: string, blockId: string) => {
  const createOption = useFlowBuilderStore((state) => state.createOption);

  return useCallback(
    (otherOption?: boolean) => createOption(id, blockId, otherOption),
    [createOption, id, blockId]
  );
};

export const useDuplicateOption = ({
  blockId,
  id,
  optionId,
}: GetBlockOptionProps) => {
  const duplicateOption = useFlowBuilderStore((state) => state.duplicateOption);

  return useCallback(
    () => duplicateOption({ blockId, id, optionId }),
    [duplicateOption, blockId, id, optionId]
  );
};

export const useRemoveOption = ({
  blockId,
  id,
  optionId,
}: GetBlockOptionProps) => {
  const removeOption = useFlowBuilderStore((state) => state.removeOption);

  return useCallback(
    () => removeOption({ blockId, id, optionId }),
    [removeOption, blockId, id, optionId]
  );
};

export const useSetScaleBlockData = (id: string, blockId: string) => {
  const setScaleBlockData = useFlowBuilderStore(
    (state) => state.setScaleBlockData
  );

  return useCallback(
    (mutate: (state: ContentScaleBlockState) => ContentScaleBlockState) =>
      setScaleBlockData(id, blockId, mutate),
    [setScaleBlockData, id, blockId]
  );
};

export const useSetGivePointsBlockData = (id: string, blockId: string) => {
  const setGivePointsBlockData = useFlowBuilderStore(
    (state) => state.setGivePointsBlockData
  );

  return useCallback(
    (
      mutate: (
        state: ContentGivePointsBlockState
      ) => ContentGivePointsBlockState
    ) => setGivePointsBlockData(id, blockId, mutate),
    [setGivePointsBlockData, id, blockId]
  );
};

export const useToggleGivePointsLimit = (id: string, blockId: string) => {
  const setGivePointsBlockData = useSetGivePointsBlockData(id, blockId);

  return useCallback(() => {
    setGivePointsBlockData((state) => {
      state.limitAmountDetails = state.limitAmountDetails
        ? undefined
        : {
            type: 'PERCENT',
            value: 20,
          };
      return state;
    });
  }, [setGivePointsBlockData]);
};

export const useToggleGivePointsCurrencyValue = (
  id: string,
  blockId: string
) => {
  const setGivePointsBlockData = useSetGivePointsBlockData(id, blockId);

  return useCallback(() => {
    setGivePointsBlockData((state) => {
      state.hideCurrencyValues = !state.hideCurrencyValues;
      return state;
    });
  }, [setGivePointsBlockData]);
};

export const useSetGivePointsLimitType = (id: string, blockId: string) => {
  const setGivePointsBlockData = useSetGivePointsBlockData(id, blockId);

  return useCallback(
    (type: 'PERCENT' | 'EXACT') => {
      setGivePointsBlockData((state) => {
        state.limitAmountDetails = {
          type,
          value: type === 'PERCENT' ? 20 : 100,
        };
        return state;
      });
    },
    [setGivePointsBlockData]
  );
};

export const useSetGivePointsPercentLimit = (id: string, blockId: string) => {
  const setGivePointsBlockData = useSetGivePointsBlockData(id, blockId);

  return useCallback(
    (value: number) => {
      setGivePointsBlockData((state) => {
        state.limitAmountDetails = {
          type: 'PERCENT',
          value,
        };
        return state;
      });
    },
    [setGivePointsBlockData]
  );
};

export const useSetGivePointsExactLimit = (id: string, blockId: string) => {
  const setGivePointsBlockData = useSetGivePointsBlockData(id, blockId);

  return useCallback(
    (value: number) => {
      setGivePointsBlockData((state) => {
        state.limitAmountDetails = {
          type: 'EXACT',
          value,
        };
        return state;
      });
    },
    [setGivePointsBlockData]
  );
};

export const useGetBlockType = (id: string, blockId: string) => {
  return useFlowBuilderStore(
    useShallow(
      (state) =>
        state.flows[id]?.blockData.CONTENT.contentBlocks.find(
          (block) => block.id === blockId
        )?.type ?? null
    )
  );
};

export const useSetContentLimit = (
  id: string,
  blockId: string,
  type: Parameters<Actions['setContentLimits']>['2']
) => {
  const setContentLimits = useFlowBuilderStore(
    (state) => state.setContentLimits
  );

  return useCallback(
    (mutate: Parameters<Actions['setContentLimits']>['3']) =>
      setContentLimits(id, blockId, type, mutate),
    [setContentLimits, id, blockId, type]
  );
};

export const useSetFormattingOptions = (
  id: string,
  blockId: string,
  type: Parameters<Actions['setFormattingOptions']>['2']
) => {
  const setFormattingOptions = useFlowBuilderStore(
    (state) => state.setFormattingOptions
  );

  return useCallback(
    () => setFormattingOptions(id, blockId, type),
    [setFormattingOptions, id, blockId, type]
  );
};

export const useSetErrors = (id: string) => {
  const setError = useFlowBuilderStore((state) => state.setError);

  return useCallback(
    (errors: FlowBuilderState['errors']) => setError(id, errors),
    [setError, id]
  );
};

export const useGetBlockTitle = (id: string, blockId: string) => {
  return useFlowBuilderStore(
    useShallow((state) => {
      const block = state.flows[id]?.blockData.CONTENT.contentBlocks.find(
        (block) => block.id === blockId
      );
      return block?.title ?? '';
    })
  );
};

export const useGetBlockTitleError = (id: string, blockId: string) => {
  return useFlowBuilderStore(
    useShallow((state) => state.flows[id]?.errors?.[blockId] ?? null)
  );
};

export const useGetBlockDescription = (id: string, blockId: string) => {
  return useFlowBuilderStore(
    useShallow((state) => {
      const block = state.flows[id]?.blockData.CONTENT.contentBlocks.find(
        (block) => block.id === blockId
      );
      return block?.description ?? '';
    })
  );
};

export const useGetBlockParticipants = (id: string, blockId: string) => {
  return useFlowBuilderStore(
    useShallow((state) => {
      const block = state.flows[id]?.blockData.CONTENT.contentBlocks.find(
        (block) => block.id === blockId && block.type === 'PERSON_SELECTOR'
      );
      if (block && isPersonSelectorBlock(block)) {
        return block.selectedBlockParticipants;
      }
      return 'EVERYONE';
    })
  );
};

export const useGetSelectableOptionType = (id: string, blockId: string) => {
  return useFlowBuilderStore(
    useShallow((state) => {
      const block = state.flows[id]?.blockData.CONTENT.contentBlocks.find(
        (block) => block.id === blockId
      );
      if (!block) {
        return 'SINGLE';
      }
      if (isPersonSelectorBlock(block) || isMultiChoiceOrDropdownBlock(block)) {
        return block.optionType;
      }
      return 'SINGLE';
    })
  );
};

export const useAtLeastOneBlock = (id: string) => {
  return useFlowBuilderStore(
    useShallow(
      (state) =>
        (state.flows[id]?.blockData.CONTENT.contentBlocks.length ?? 0) > 0
    )
  );
};

export const useGetFlowName = (id: string) => {
  return useFlowBuilderStore(
    useShallow((state) => state.flows[id]?.flowName ?? '')
  );
};

export const useGetFlowNameError = (id: string) => {
  return useFlowBuilderStore(
    useShallow(
      (state) => state.flows[id]?.errors?.[KnownErrorKeys.FlowName] ?? null
    )
  );
};

export const useGetFlowDescription = (id: string) => {
  return useFlowBuilderStore(
    useShallow((state) => state.flows[id]?.description ?? '')
  );
};

export const useGetBlockOptions = (id: string, blockId: string) => {
  return useFlowBuilderStore(
    useShallow((state) => {
      const block = state.flows[id]?.blockData.CONTENT.contentBlocks.find(
        (block) => block.id === blockId
      );
      if (block?.type === 'DROPDOWN' || block?.type === 'MULTI_CHOICE') {
        return block.options.map(({ value }) => value);
      }
      return [];
    })
  );
};

export const useGetBlockOptionIndex = ({
  blockId,
  id,
  optionId,
}: GetBlockOptionProps) => {
  return useFlowBuilderStore(
    useShallow((state) => {
      const block = state.flows[id]?.blockData.CONTENT.contentBlocks.find(
        (block) => block.id === blockId
      );
      if (block?.type === 'DROPDOWN' || block?.type === 'MULTI_CHOICE') {
        return block.options.findIndex(({ value }) => value === optionId);
      }
      return -1;
    })
  );
};

export const useGetBlockOptionDetail = <T>({
  blockId,
  id,
  optionId,
  selector,
}: GetBlockOptionProps & {
  selector: (state: OptionItemProps) => T;
}) => {
  return useFlowBuilderStore(
    useShallow((state) => {
      const block = state.flows[id]?.blockData.CONTENT.contentBlocks.find(
        (block) => block.id === blockId
      );
      if (block?.type === 'DROPDOWN' || block?.type === 'MULTI_CHOICE') {
        const option = block.options.find(({ value }) => value === optionId);
        if (!option) {
          return null;
        }
        return selector(option);
      }
      return null;
    })
  );
};

export const useGetOtherBlockOptionExist = (id: string, blockId: string) => {
  return useFlowBuilderStore(
    useShallow((state) => {
      const block = state.flows[id]?.blockData.CONTENT.contentBlocks.find(
        (block) => block.id === blockId
      );
      if (block?.type === 'MULTI_CHOICE') {
        return block.options.some(({ value }) => value === 'other');
      }
      return false;
    })
  );
};

export const useIsOtherOption = ({
  blockId,
  id,
  optionId,
}: GetBlockOptionProps) => {
  return useFlowBuilderStore(
    useShallow((state) => {
      const block = state.flows[id]?.blockData.CONTENT.contentBlocks.find(
        (block) => block.id === blockId
      );
      if (block?.type === 'MULTI_CHOICE') {
        return block.options.some(
          ({ value }) => value === optionId && value === 'other'
        );
      }
      return false;
    })
  );
};

export const useGetSelectableOptionDetails = <T>(
  id: string,
  blockId: string,
  selector: (state: OptionsSelectObject) => T
) => {
  return useFlowBuilderStore(
    useShallow((state) => {
      const block = state.flows[id]?.blockData.CONTENT.contentBlocks.find(
        (block) => block.id === blockId
      );

      if (
        block &&
        (isPersonSelectorBlock(block) || isMultiChoiceOrDropdownBlock(block))
      ) {
        return selector(block.optionSelectObject);
      }

      return null;
    })
  );
};

export const useGetOpenEndedBlockDetails = <T>(
  id: string,
  blockId: string,
  selector: (state: ContentOpenEndedBlockState) => T
) => {
  return useFlowBuilderStore(
    useShallow((state) => {
      const block = state.flows[id]?.blockData.CONTENT.contentBlocks.find(
        (block) => block.id === blockId
      );
      if (block && isOpenEndedBlock(block)) {
        return selector(block);
      }

      return null;
    })
  );
};

export const useGetScaleRange = (
  id: string,
  blockId: string,
  type: 'min' | 'max'
) => {
  return useFlowBuilderStore(
    useShallow((state) => {
      const block = state.flows[id]?.blockData.CONTENT.contentBlocks.find(
        (block) => block.id === blockId
      );

      if (block && block.type === 'SCALE') {
        return type === 'min' ? block.minimumRange : block.maximumRange;
      }

      return 0;
    })
  );
};

export const useGetScaleLabel = (
  id: string,
  blockId: string,
  type: keyof Pick<
    ContentScaleBlockState,
    'lowLabel' | 'middleLabel' | 'highLabel'
  >
) => {
  return useFlowBuilderStore(
    useShallow((state) => {
      const block = state.flows[id]?.blockData.CONTENT.contentBlocks.find(
        (block) => block.id === blockId
      );

      if (block && block.type === 'SCALE') {
        return block[type];
      }

      return '';
    })
  );
};

export const useGetBlockIsLinked = (id: string, blockId: string) => {
  return useFlowBuilderStore(
    useShallow((state) => {
      const block = state.flows[id]?.blockData.CONTENT.contentBlocks.find(
        (block) => block.id === blockId
      );
      return block?.isLinkedBlock ?? false;
    })
  );
};

export const useGetBlockSummary = (id: string, blockId: string) => {
  const { formatMessage } = useIntl();

  return useFlowBuilderStore(
    useShallow((state) => {
      const block = state.flows[id]?.blockData.CONTENT.contentBlocks.find(
        (block) => block.id === blockId
      );

      if (!block) {
        return null;
      }

      if (block.type === 'DROPDOWN' || block.type === 'MULTI_CHOICE') {
        return block.options
          .map((option) =>
            option.value === 'other'
              ? formatMessage(
                  defineMessage({ defaultMessage: 'Other', id: '/VnDMl' })
                )
              : option.label
          )
          .filter(Boolean)
          .join(', ');
      }

      if (block.type === 'SCALE') {
        return [block.minimumRange, block.maximumRange].join(' - ');
      }

      if (block.type === 'NPS') {
        return [0, 10].join(' - ');
      }

      return null;
    })
  );
};

export const useGetBlockConfigurationError = (id: string, blockId: string) => {
  return useFlowBuilderStore(
    useShallow((state) => state.flows[id]?.errors?.[blockId] ?? null)
  );
};

export const useGivePointsLimitCurrencyEnabled = (
  id: string,
  blockId: string
) => {
  return useFlowBuilderStore(
    useShallow((state) => {
      const block = state.flows[id]?.blockData.CONTENT.contentBlocks.find(
        (block) => block.id === blockId
      );

      return block && isGivePointsBlock(block)
        ? block.limitAmountDetails !== undefined
        : false;
    })
  );
};

export const useGivePointsCurrencyHidden = (id: string, blockId: string) => {
  return useFlowBuilderStore(
    useShallow((state) => {
      const block = state.flows[id]?.blockData.CONTENT.contentBlocks.find(
        (block) => block.id === blockId
      );

      return block && isGivePointsBlock(block)
        ? block.hideCurrencyValues
        : false;
    })
  );
};

export const useGivePointsLimitType = (id: string, blockId: string) => {
  return useFlowBuilderStore(
    useShallow((state) => {
      const block = state.flows[id]?.blockData.CONTENT.contentBlocks.find(
        (block) => block.id === blockId
      );

      return block && isGivePointsBlock(block)
        ? (block.limitAmountDetails?.type ?? 'PERCENT')
        : 'PERCENT';
    })
  );
};

export const useGetGivePointsPercentLimit = (id: string, blockId: string) => {
  return useFlowBuilderStore(
    useShallow((state) => {
      const block = state.flows[id]?.blockData.CONTENT.contentBlocks.find(
        (block) => block.id === blockId
      );

      return block && isGivePointsBlock(block)
        ? block.limitAmountDetails?.type === 'PERCENT'
          ? block.limitAmountDetails.value
          : 0
        : 0;
    })
  );
};

export const useGetGivePointsExactLimit = (id: string, blockId: string) => {
  return useFlowBuilderStore(
    useShallow((state) => {
      const block = state.flows[id]?.blockData.CONTENT.contentBlocks.find(
        (block) => block.id === blockId
      );

      return block && isGivePointsBlock(block)
        ? block.limitAmountDetails?.type === 'EXACT'
          ? block.limitAmountDetails.value
          : 0
        : 0;
    })
  );
};

export const useCanDuplicate = (id: string, blockId: string) => {
  return useFlowBuilderStore(
    useShallow((state) => {
      const block = state.flows[id]?.blockData.CONTENT.contentBlocks.find(
        (block) => block.id === blockId
      );

      return (
        (block && !block.isLinkedBlock && block.type !== 'GIVE_POINTS_STACK') ??
        false
      );
    })
  );
};

export const useInEditFlow = (id: string) => {
  return useFlowBuilderStore(
    useShallow((state) => state.flows[id]?.inEditMode ?? false)
  );
};

export const useGetFlowEmoji = (id: string) => {
  return useFlowBuilderStore(useShallow((state) => state.flows[id]?.emoji));
};

export const useGetFlowOwner = (id: string) => {
  return useFlowBuilderStore(
    useShallow((state) => {
      const flow = state.flows[id];
      return flow?.owner[0] ?? null;
    })
  );
};

export const useGetFlowCollaborators = (id: string) => {
  return useFlowBuilderStore(
    useShallow((state) => {
      const flow = state.flows[id];
      return flow?.collaborators ?? null;
    })
  );
};

export const useGetNumberOfResponses = (id: string) => {
  return useFlowBuilderStore(
    useShallow((state) => state.flows[id]?.numberOfResponses ?? 1)
  );
};

export const useGetRemindersTimeZone = (id: string) => {
  const defaultTimeZone = getBrowserTimeZone();

  return useFlowBuilderStore(
    useShallow((state) => state.flows[id]?.remindersTimeZone ?? defaultTimeZone)
  );
};

export function useGetFlowBuilderState<K extends FlowBuilderStateKey>(
  id: string,
  key: K
) {
  return useFlowBuilderStore(
    useShallow((state) => state.flows[id]?.[key] ?? null)
  );
}

export function useSetFlowBuilderState<K extends FlowBuilderStateKey>(
  id: string,
  key: K
) {
  const setFlowBuilderState = useFlowBuilderStore(
    (state) => state.setFlowBuilderState
  );

  return useCallback(
    (value: FlowBuilderStateKeys[K]) => {
      setFlowBuilderState(id, key, value);
    },
    [id, key, setFlowBuilderState]
  );
}

export const useGetOwnerAndCollaborators = (id: string): ShareCriteria => {
  return useFlowBuilderStore(
    useShallow((state) => {
      const flow = state.flows[id];

      if (!flow) {
        return { exclude: [], include: [] };
      }

      const ownerId = flow.owner[0].memberID;

      return {
        exclude: [],
        include: [
          {
            field: CriteriaRuleType.Member,
            values: [
              formatFlowCollaboratorToCriteriaAPIResponse(
                flow.owner[0],
                ownerId
              ),
              ...flow.collaborators.map((user) =>
                formatFlowCollaboratorToCriteriaAPIResponse(user, ownerId)
              ),
            ],
          },
        ],
      };
    })
  );
};

export const useGetCustomCriteria = (
  id: string,
  blockId: string
): ShareCriteria => {
  return useFlowBuilderStore(
    useShallow((state) => {
      const block = state.flows[id]?.blockData.CONTENT.contentBlocks.find(
        (block) => block.id === blockId
      );

      return (
        (block && isPersonSelectorBlock(block) && block.criteriaGroups) || {
          include: [],
          exclude: [],
        }
      );
    })
  );
};

export const useGetCustomPersonSelectorCountInitialized = (
  id: string,
  blockId: string
) => {
  return useFlowBuilderStore(
    useShallow((state) => {
      const block = state.flows[id]?.blockData.CONTENT.contentBlocks.find(
        (block) => block.id === blockId
      );

      return (
        (block && isPersonSelectorBlock(block) && block.initialized) || false
      );
    })
  );
};

export const useGetCustomPersonSelectorCount = (
  id: string,
  blockId: string
) => {
  return useFlowBuilderStore(
    useShallow((state) => {
      const block = state.flows[id]?.blockData.CONTENT.contentBlocks.find(
        (block) => block.id === blockId
      );

      return (
        (block &&
          isPersonSelectorBlock(block) &&
          block.customPersonSelectorCount) ||
        0
      );
    })
  );
};

export const useGivePointsBlockExists = (id: string) => {
  return useFlowBuilderStore(
    useShallow((state) => {
      return (
        state.flows[id]?.blockData.CONTENT.contentBlocks.some(
          (block) => block.type === 'GIVE_POINTS_STACK'
        ) ?? false
      );
    })
  );
};
