import type { FlowBlockContent } from '@assembly-web/services';
import { BlockTypes } from '@assembly-web/services';
import { BarChart, TextStyle } from '@assembly-web/ui';
import { defineMessages, useIntl } from 'react-intl';

import { useGetBlockInsights } from '../hooks/useGetBlockInsights';
import type { NPSInsights } from '../types';
import { MetricCard } from './MetricCard';

const messages = defineMessages({
  npsScoreHeader: {
    defaultMessage: 'NPS Score',
    id: 'k9bplO',
  },
  npsScoreTooltip: {
    defaultMessage:
      'Net Promoter Score is a measure of customer satisfaction and loyalty. It is calculated by subtracting the percentage of detractors from the percentage of promoters.',
    id: 'G06Lyl',
  },
  promotersHeader: {
    defaultMessage: 'Promoters',
    id: '1s1klV',
  },
  promotersTooltip: {
    defaultMessage: 'Promoters are users who voted 9 or 10 on the scale.',
    id: '6w0hie',
  },
  passivesHeader: {
    defaultMessage: 'Passives',
    id: 'mPHuTb',
  },
  passivesTooltip: {
    defaultMessage: 'Passives are users who voted 7 or 8 on the scale.',
    id: 'IS1YKO',
  },
  detractorsHeader: {
    defaultMessage: 'Detractors',
    id: 'S0A/fx',
  },
  detractorsTooltip: {
    defaultMessage: 'Detractors are users who voted 0 to 6 on the scale.',
    id: 'vfFL3B',
  },
  scaleLabel: {
    defaultMessage: 'Scale Value',
    id: 'khqKaH',
  },
  selectionCountLabel: {
    defaultMessage: 'Selection Count',
    id: 'sr+SL8',
  },
});

function NPSInsights({ npsInsights }: { npsInsights?: NPSInsights }) {
  const { formatMessage } = useIntl();

  if (!npsInsights) return null;

  return (
    <div className="my-5 flex w-full flex-col gap-4 min-[525px]:flex-row [&>*]:flex-1">
      <MetricCard
        title={formatMessage(messages.npsScoreHeader)}
        value={npsInsights.npsScore}
        tooltipText={formatMessage(messages.npsScoreTooltip)}
      />
      <MetricCard
        title={formatMessage(messages.promotersHeader)}
        value={npsInsights.promoters}
        tooltipText={formatMessage(messages.promotersTooltip)}
      />
      <MetricCard
        title={formatMessage(messages.passivesHeader)}
        value={npsInsights.passives}
        tooltipText={formatMessage(messages.passivesTooltip)}
      />
      <MetricCard
        title={formatMessage(messages.detractorsHeader)}
        value={npsInsights.detractors}
        tooltipText={formatMessage(messages.detractorsTooltip)}
      />
    </div>
  );
}

export function BlockInsights({
  flowId,
  block,
}: {
  flowId: string;
  block: FlowBlockContent;
}) {
  const { formatMessage } = useIntl();

  const { data: blockInsights } = useGetBlockInsights({
    params: {
      flowId,
      blockId: block.blockId ?? '',
    },
  });

  return (
    <div className="mb-4">
      <h4>
        <TextStyle variant="lg-bold" as="span" className="line-clamp-2">
          {block.title}
        </TextStyle>
      </h4>
      <div>
        {(blockInsights?.blockType === BlockTypes.Dropdown ||
          blockInsights?.blockType === BlockTypes.MultiChoice) && (
          <BarChart
            data={blockInsights.selectionDistribution}
            indexBy="selectionValue"
            keys={['count']}
            layout="horizontal"
            chartLabel={`"${block.title}" Selection Distribution Bar Chart`}
            bottomAxisLabel={formatMessage(messages.selectionCountLabel)}
          />
        )}
        {blockInsights?.blockType === BlockTypes.PersonSelector && (
          <BarChart
            data={blockInsights.selectionDistribution}
            indexBy="selectionValue"
            keys={['count']}
            layout="horizontal"
            chartLabel={`"${block.title}" Selection Distribution Bar Chart`}
            bottomAxisLabel={formatMessage(messages.selectionCountLabel)}
          />
        )}
        {blockInsights?.blockType === BlockTypes.Scale &&
          Boolean(blockInsights.scaleInsights.npsInsights) && (
            <NPSInsights
              npsInsights={blockInsights.scaleInsights.npsInsights}
            />
          )}
        {blockInsights?.blockType === BlockTypes.Scale && (
          <BarChart
            data={blockInsights.selectionDistribution}
            indexBy="selectionValue"
            keys={['count']}
            layout="vertical"
            chartLabel={`"${block.title}" Scale Distribution Bar Chart`}
            bottomAxisLabel={`${formatMessage(messages.scaleLabel)} (${blockInsights.scaleInsights.lowLabel} - ${blockInsights.scaleInsights.highLabel})`}
            leftAxisLabel={formatMessage(messages.selectionCountLabel)}
          />
        )}
      </div>
    </div>
  );
}
