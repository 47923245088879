import {
  generateCriteriaRules,
  type GroupOrUserChipData,
  MemberStatus,
  useCriteriaCount,
  useSuspenseUserDetails,
} from '@assembly-web/services';
import {
  GroupOrUserChipList,
  PersonBlockContainer,
  PersonSelector as PersonSelectorImpl,
} from '@assembly-web/ui';
import isEqual from 'lodash/isEqual';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { useAwardDetail } from '../../Awards';
import { useTrackAction } from '../hooks/useTrackAction';

const messages = defineMessages({
  label: {
    defaultMessage: 'Add recipients',
    id: 'ZGYPiR',
  },
  requiredValidation: {
    defaultMessage: 'You are required to add at least one recipient.',
    id: 'N4+mQ3',
  },
  removePeople: {
    defaultMessage: 'Not sufficient points, please remove recipients',
    id: 'iJ+vr6',
  },
  recipientTooltip: {
    defaultMessage: 'Recipients can’t be edited.',
    id: 'dO2NpW',
  },
});

function Selector() {
  const { formatMessage } = useIntl();

  const { control, watch, setValue, getValues } = useFormContext();
  const { trackAwardAction } = useTrackAction();
  const {
    data: { member: user },
  } = useSuspenseUserDetails();

  const { settings, awardPostId } = useAwardDetail();

  const recipients = watch('recipients', []) as GroupOrUserChipData[];
  const groupedCriteria = generateCriteriaRules(recipients);

  const {
    data: criteriaData,
    status,
    fetchStatus,
  } = useCriteriaCount({
    criteria: groupedCriteria,
    for: 'recognition',
  });

  const memberCount = watch('recipientsCount');

  useEffect(() => {
    if (status === 'success' && fetchStatus === 'idle') {
      const count = criteriaData.data.count;
      setValue('recipientsCount', count, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });
    }
  }, [criteriaData, status, fetchStatus, setValue]);

  const remainingPoints = settings?.budget?.remainingPoints || 0;
  const points = watch('points');

  useEffect(() => {
    const oldValue = getValues('recipients');
    const { unsubscribe } = watch((values) => {
      if (
        !isEqual(values.recipients, oldValue) &&
        values.recipients?.length > oldValue?.length
      ) {
        trackAwardAction('recipientSelected');
      }
    });
    return () => {
      unsubscribe();
    };
  }, [control, watch, getValues, trackAwardAction]);
  return awardPostId ? (
    <div className="flex flex-wrap gap-2 text-gray-9">
      <GroupOrUserChipList
        data={control._defaultValues.recipients ?? []}
        disabled={true}
        tooltipText={formatMessage(messages.recipientTooltip)}
      />
    </div>
  ) : (
    <Controller
      name="recipients"
      control={control}
      rules={{
        validate: {
          required: (value?: GroupOrUserChipData[]) => {
            if (!value || !Array.isArray(value)) {
              return true;
            }
            if (!value.length) {
              return formatMessage(messages.requiredValidation);
            }
            return true;
          },
          maxValue: () => {
            if (memberCount * points > remainingPoints) {
              return formatMessage(messages.removePeople);
            }
            return true;
          },
        },
      }}
      render={({ field: { onBlur, onChange }, fieldState: { error } }) => (
        <PersonSelectorImpl
          onChange={onChange}
          defaultRecipients={control._defaultValues.recipients}
          error={error}
          onBlur={onBlur}
          label={formatMessage(messages.label)}
          customFilterCallback={(member) =>
            member.memberID !== user.memberId &&
            (member.status === MemberStatus.Receiver ||
              member.status === MemberStatus.Normal)
          }
        />
      )}
    />
  );
}

function Label() {
  return <FormattedMessage defaultMessage="Add recipients" id="ZGYPiR" />;
}

export function PersonSelector() {
  return (
    <PersonBlockContainer label={<Label />}>
      <Selector />
    </PersonBlockContainer>
  );
}
