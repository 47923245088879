import type { LegacyPostTypes } from '@assembly-web/services';
import { deriveEnumForValue, RewardType } from '@assembly-web/services';

import type { PostData } from '../../../types/postsAndReplies';
import type {
  CreateDrawerPayload,
  DrawerData,
} from '../components/Drawers/types';

const flowOrPostParamsArray = ['flowId', 'responseId', 'commentId', 'postId'];

export function getIsFlowOrRecognitionPost(urlPath: string) {
  return (
    urlPath.includes('post/flow/flows') ||
    urlPath.includes('post/flow/recognition')
  );
}

export function getShouldOpenPostDrawer() {
  const searchParams = new URLSearchParams(location.search);
  const flowId = searchParams.get('flowId');
  const postId = searchParams.get('postId');
  const responseId = searchParams.get('responseId');

  const isFlowPost = Boolean(flowId && responseId);
  const isLegacyFlowPost = Boolean(postId && !isFlowPost);

  return isFlowPost || isLegacyFlowPost;
}

export function generatePostDrawerPayload({
  commentId,
  flowId,
  responseId,
  postId,
  onClose,
  editSelectedCommentId,
  postType,
}: {
  commentId: string | null;
  flowId: string | null;
  responseId: string | null;
  postId: string | null;
  onClose?: () => void;
  editSelectedCommentId?: string;
  postType?: LegacyPostTypes;
}): CreateDrawerPayload {
  let drawerData: DrawerData;
  let title: string;
  if (commentId) {
    const replyData: PostData =
      flowId && responseId
        ? { type: 'flow', flowId, responseId, commentId }
        : { type: 'post', postId: postId ?? '', commentId, postType };
    drawerData = replyData;
    title = `Reply in ${
      replyData.type === 'flow' ? replyData.flowId : replyData.postId
    }`;
  } else {
    const postData: PostData =
      flowId && responseId
        ? { type: 'flow', flowId, responseId }
        : { type: 'post', postId: postId ?? '', postType };
    drawerData = postData;
    title = `Post in ${
      postData.type === 'flow' ? postData.flowId : postData.postId
    }`;
  }

  return {
    data: { ...drawerData, onClose, editSelectedCommentId },
    title,
    type: 'postsAndReplies',
  };
}

export function getUpdatedUrlForFlowOrRecognitionPost(): string {
  const searchParams = new URLSearchParams(location.search);

  const filteredParams = new URLSearchParams();

  flowOrPostParamsArray.forEach((param) => {
    const value = searchParams.get(param);
    if (value) filteredParams.set(param, value);
  });
  const newUrl = `/a?${filteredParams.toString()}`;
  return newUrl;
}

export function removeFlowOrPostParamsFromUrl(): string {
  const searchParams = new URLSearchParams(location.search);

  flowOrPostParamsArray.forEach((param) => {
    searchParams.delete(param);
  });

  const newUrl = `/a?${searchParams.toString()}`;
  return newUrl;
}

export function getFlowOrRecognitionPostLinkToCopy(data: PostData) {
  const baseUrl = '/a?';

  const commentIdParams =
    'commentId' in data && data.commentId ? `&commentId=${data.commentId}` : '';

  if (data.type === 'flow') {
    return `${baseUrl}flowId=${data.flowId}&responseId=${data.responseId}${commentIdParams}`;
  }

  return `${baseUrl}postId=${data.postId}${commentIdParams}`;
}

export function getDefaultRewardsType({
  hasCultureSetup,
  hasGiftCardsSetup,
  hasCharitiesSetup,
  isRewardsTabsDisabled,
}: {
  hasCultureSetup: boolean;
  hasCharitiesSetup: boolean;
  hasGiftCardsSetup: boolean;
  isRewardsTabsDisabled: boolean;
}) {
  const defaultRewardsType = () => {
    if (hasGiftCardsSetup) return RewardType.GiftCards;
    if (hasCharitiesSetup) return RewardType.Charities;
    if (hasCultureSetup) return RewardType.Culture;
    return RewardType.Swag;
  };
  if (!isRewardsTabsDisabled) {
    const searchParams = new URLSearchParams(location.search);
    const tabValue = searchParams.get('tab');
    let derivedValue =
      deriveEnumForValue(tabValue, RewardType) ?? defaultRewardsType();
    return Object.values(RewardType).includes(derivedValue)
      ? derivedValue
      : defaultRewardsType();
  }
  return null;
}
