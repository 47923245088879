import {
  AssemblyPlanType,
  getRoutePath,
  SplitNames,
  type ThemePreset,
  useFeatureSplit,
} from '@assembly-web/services';
import { AssemblyLoadingIndicator } from '@assembly-web/ui';
import { wrapCreateBrowserRouter } from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import camelCase from 'lodash/camelCase';
import { useEffect, useMemo } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  redirect,
  Route,
  RouterProvider,
} from 'react-router-dom';

import { AppWrapper } from './components/AppWrapper';
import { AppLayout } from './components/Layout/AppLayout';
import { NotFoundPage } from './components/NotFoundPage';
import { rootDataLoader } from './loaders/rootDataLoader';
import { connectionsLoader } from './modules/connections/loaders/connections';
import { ConnectionsRoute } from './modules/connections/pages/connections';
import { FullScreenPage } from './modules/discover/components/FullScreenPage';
import { useGetPlanFeaturesQuery } from './modules/discover/hooks/useGetPlanFeaturesQuery';
import { adminRoutesLoader } from './modules/discover/loaders/adminRoutesLoader';
import { authenticateSAMLLoader } from './modules/discover/loaders/authenticateSAMLLoader';
import { externalParticipationLoader } from './modules/discover/loaders/externalParticipationLoader';
import { LegacyRoute } from './modules/discover/pages/legacy-path';
import { signOutLoader, SignOutRoute } from './modules/discover/pages/signout';
import { FlowInsightsRoute } from './modules/flows/FlowInsightsRoute';
import { OnboardingLayout } from './modules/onboarding/components/OnboardingLayout';
import {
  createAccountAction,
  createAccountLoader,
  CreateAccountRoute,
} from './modules/onboarding/pages/create-account';
import {
  joinWorkspaceSlugAction,
  joinWorkspaceSlugLoader,
  JoinWorkspaceSlugRoute,
} from './modules/onboarding/pages/join/$workspaceSlug';
import {
  accountInviteLoader,
  AccountInviteRoute,
} from './modules/onboarding/pages/join/$workspaceSlug/account-invite/$inviteToken';
import {
  checkEmailAction,
  checkEmailLoader,
  CheckEmailRoute,
} from './modules/onboarding/pages/join/$workspaceSlug/check-email';
import {
  userAccountCreationSuccessLoader,
  UserAccountCreationSuccessRoute,
} from './modules/onboarding/pages/join/$workspaceSlug/success';
import {
  userDetailsAction,
  userDetailsLoader,
  UserDetailsRoute,
} from './modules/onboarding/pages/join/$workspaceSlug/user-details';
import {
  joinWithProviderLoader,
  JoinWithProviderRoute,
} from './modules/onboarding/pages/join/$workspaceSlug/validate/$provider';
import {
  loginAction,
  loginLoader,
  LoginRoute,
} from './modules/onboarding/pages/login';
import {
  loginProviderLoader,
  LoginProviderRoute,
} from './modules/onboarding/pages/login/$workspaceSlug/$provider';
import {
  MSTeamsAuth,
  msTeamsAuthLoader,
} from './modules/onboarding/pages/ms-teams/auth';
import {
  MSTeamsConfig,
  msTeamsConfigLoader,
} from './modules/onboarding/pages/ms-teams/config';
import {
  MSTeams,
  msTeamsAction,
  msTeamsLoader,
} from './modules/onboarding/pages/ms-teams/login';
import {
  ownerDetailsAction,
  ownerDetailsLoader,
  OwnerDetailsRoute,
} from './modules/onboarding/pages/onboarding/owner-details';
import { userDetailsLoader as userDetailsMagicLinkLoader } from './modules/onboarding/pages/onboarding/user-details';
import { WaitForAccountRoute } from './modules/onboarding/pages/onboarding/wait-for-account';
import {
  ssoAccountCreationLoader,
  SSOAccountCreationRoute,
} from './modules/onboarding/pages/sso-account-creation/$provider';
import {
  ssoLoginLoader,
  SSOLoginRoute,
} from './modules/onboarding/pages/sso-signin-in/$provider';
import {
  verifyEmailFlowAction,
  VerifyEmailFlowRoute,
} from './modules/onboarding/pages/verify-email/$flow';
import {
  workspacesAction,
  workspacesLoader,
  WorkspacesRoute,
} from './modules/onboarding/pages/workspaces';
import { RecognitionParticipationRoute } from './modules/participation/RecognitionParticipationRoute';
import { RecognitionRoute } from './modules/recognition/RecognitionRoute';
import {
  TemplatesRoute,
  templatesRouteLoader,
} from './modules/templates/pages/templates';
import { Root } from './Root';
import { RootErrorBoundary } from './RootErrorBoundary';

const redirectPath = getRoutePath('', { directUrl: true });
const currentPath = window.location.pathname;
const redirectPathNameArray = redirectPath.split('/');
const currentPathNameArray = currentPath.split('/');

export function RouteDefinitions({
  themePreset,
}: {
  themePreset: ThemePreset;
}) {
  const queryClient = useQueryClient();
  const { data: planFeatures } = useGetPlanFeaturesQuery();
  const { isTreatmentActive: isBlockInsightsEnabled } = useFeatureSplit(
    SplitNames.FlowBlockInsights
  );

  useEffect(() => {
    const $body = document.querySelector('body');

    const classesToRemove =
      $body?.classList
        .toString()
        .split(' ')
        .filter((className) => className.startsWith('theme-')) ?? [];

    classesToRemove.forEach((className) => $body?.classList.remove(className));

    $body?.classList.add(`theme-${themePreset}`);
  }, [themePreset]);

  const routeElementsWithoutPrefix = useMemo(
    () => (
      <Route
        path="/"
        loader={async () => {
          const searchParams = new URLSearchParams(window.location.search);

          if (
            redirectPathNameArray.includes('a') &&
            !currentPathNameArray.includes('a')
          ) {
            let finalPath = redirectPath;
            if (searchParams.toString()) {
              finalPath = `${redirectPath}?${decodeURIComponent(
                searchParams.toString()
              )}`;
            }
            return redirect(finalPath);
          }
          return null;
        }}
      >
        <Route path="/admin/*" loader={adminRoutesLoader} />
        <Route path="/auth/saml" loader={authenticateSAMLLoader} />
        <Route path="/s/auth/saml" loader={authenticateSAMLLoader} />
        <Route
          key="external"
          path="e/flows/:flowId"
          loader={externalParticipationLoader}
        />
        <Route
          element={
            <Root>
              <OnboardingLayout>
                <Outlet />
              </OnboardingLayout>
            </Root>
          }
          errorElement={
            <OnboardingLayout shouldShowFooter={false}>
              <RootErrorBoundary />
            </OnboardingLayout>
          }
        >
          <Route index loader={() => redirect('/login')} />

          {/* To handle redirect from BE in some cases */}
          <Route path="/signin" loader={() => redirect('/login')} />

          <Route path="/ms-teams">
            <Route
              path="login"
              element={<MSTeams />}
              action={msTeamsAction}
              loader={msTeamsLoader(queryClient)}
            />
            <Route
              path="auth"
              element={<MSTeamsAuth />}
              loader={msTeamsAuthLoader}
            />
            <Route
              path="config"
              element={<MSTeamsConfig />}
              loader={msTeamsConfigLoader}
            />
          </Route>
          <Route path="/login">
            <Route
              index
              element={<LoginRoute />}
              action={loginAction}
              loader={loginLoader(queryClient)}
            />
            <Route
              path="ms-teams"
              element={<LoginRoute isMSTeamsPage={true} />}
              action={loginAction}
              loader={loginLoader(queryClient, true)}
            />
            <Route
              path=":workspaceSlug/:provider"
              loader={loginProviderLoader}
              element={<LoginProviderRoute />}
            />
          </Route>
          <Route
            path="/create-account"
            element={<CreateAccountRoute />}
            action={createAccountAction}
            loader={createAccountLoader}
          />
          <Route
            path="/verify-email/:flow"
            action={verifyEmailFlowAction}
            element={<VerifyEmailFlowRoute />}
          />
          <Route
            path="/workspaces"
            action={workspacesAction}
            loader={workspacesLoader(queryClient)}
            element={<WorkspacesRoute />}
          />
          <Route path="/onboarding">
            <Route index element={<Navigate to="/create-account" />} />

            <Route
              path="owner-details"
              element={<OwnerDetailsRoute />}
              loader={ownerDetailsLoader(queryClient)}
              action={ownerDetailsAction}
            />
            <Route path="wait-for-account" element={<WaitForAccountRoute />} />
            <Route
              path="user-details"
              loader={userDetailsMagicLinkLoader(queryClient)}
              element={<AssemblyLoadingIndicator />}
            />
          </Route>
          <Route path="/sso-account-creation">
            <Route index element={<Navigate to="/create-account" />} />
            <Route
              path=":provider"
              element={<SSOAccountCreationRoute />}
              loader={ssoAccountCreationLoader}
            />
          </Route>
          <Route path="/sso-sign-in">
            <Route index element={<Navigate to="/login" />} />

            <Route
              path=":provider"
              element={<SSOLoginRoute />}
              loader={ssoLoginLoader}
            />
          </Route>
          <Route path="/join">
            <Route index element={<Navigate to="/login" />} />

            <Route
              path=":workspaceSlug"
              loader={joinWorkspaceSlugLoader}
              id="join-workspace-root"
            >
              <Route
                index
                action={joinWorkspaceSlugAction}
                element={<JoinWorkspaceSlugRoute />}
              />
              <Route
                path="check-email"
                loader={checkEmailLoader}
                action={checkEmailAction}
                element={<CheckEmailRoute />}
              />
              <Route path="validate">
                <Route index element={<Navigate to="/login" />} />
                <Route
                  path=":provider"
                  loader={joinWithProviderLoader}
                  element={<JoinWithProviderRoute />}
                />
              </Route>
              <Route
                path="user-details"
                element={<UserDetailsRoute />}
                action={userDetailsAction}
                loader={userDetailsLoader(queryClient)}
              />
              <Route
                path="success"
                loader={userAccountCreationSuccessLoader(queryClient)}
                element={<UserAccountCreationSuccessRoute />}
              />
              <Route
                path="account-invite/:inviteToken"
                loader={accountInviteLoader}
                element={<AccountInviteRoute />}
              />
            </Route>
          </Route>
        </Route>
        <Route
          element={
            <Root>
              <OnboardingLayout>
                <Outlet />
              </OnboardingLayout>
            </Root>
          }
          errorElement={
            <OnboardingLayout shouldShowFooter={false}>
              <RootErrorBoundary />
            </OnboardingLayout>
          }
        >
          <Route
            path="signout"
            element={<SignOutRoute />}
            loader={signOutLoader(queryClient)}
          />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Route>
    ),
    [queryClient]
  );

  const loggedInRoutes = useMemo(
    () => (
      <Route path="/a">
        <Route
          path="templates"
          loader={templatesRouteLoader(queryClient)}
          element={<TemplatesRoute />}
          errorElement={<RootErrorBoundary />}
        />
        <Route
          element={<ConnectionsRoute />}
          errorElement={<RootErrorBoundary />}
          loader={() => connectionsLoader(queryClient)}
          path="connections"
        />
        {(planFeatures?.name === AssemblyPlanType.Starter ||
          planFeatures?.name === AssemblyPlanType.Lite) && (
          <Route
            path=":workspaceSlug/flows/editor"
            element={<Navigate to="/a" />}
          />
        )}
        <Route
          path={`:workspaceSlug/flows/recognition/fullscreen`}
          element={<FullScreenPage />}
          errorElement={<RootErrorBoundary />}
        />
        <Route
          path={`:workspaceSlug/flows/:flowId/fullscreen`}
          element={<FullScreenPage />}
        />
        <Route
          id="root"
          loader={rootDataLoader(queryClient)}
          element={
            <Root>
              <AppWrapper>
                <AppLayout>
                  <Outlet />
                </AppLayout>
              </AppWrapper>
            </Root>
          }
          errorElement={<RootErrorBoundary />}
        >
          <Route
            index
            handle={{ route: 'home' }}
            lazy={() => import('./modules/discover/pages/base')}
          />

          <Route path="activity" element={<Navigate to="/a/notifications" />} />

          <Route
            path="notifications"
            handle={{ route: 'notifications' }}
            lazy={() => import('./modules/activity/ActivityRoute')}
          >
            <Route
              path="unreads"
              element={null}
              handle={{ route: 'unreadNotifications' }}
            />
          </Route>

          {['challenges', 'files', 'flows', 'people', 'rewards'].map((path) => (
            <Route
              path={path}
              key={path}
              lazy={() => import('./modules/discover/pages/base')}
              handle={{ route: path }}
            />
          ))}

          <Route
            path={`:workspaceSlug/flows/recognition`}
            element={<RecognitionRoute />}
            handle={{ route: 'recognition' }}
          />
          <Route
            path=":workspaceSlug/flows/recognition/answer"
            element={<RecognitionParticipationRoute />}
            handle={{ route: 'participation' }}
          />

          <Route
            path=":workspaceSlug/flows/recognition/edit/:postId"
            element={<RecognitionParticipationRoute />}
            handle={{ route: 'participation' }}
          />
          <Route
            path=":workspaceSlug/flows/:id/insights"
            element={
              isBlockInsightsEnabled ? <FlowInsightsRoute /> : <LegacyRoute />
            }
            handle={{
              hideSecondaryContent: true,
            }}
          />
          <Route
            path=":workspaceSlug/flows/:id/:tab?/:postId?"
            element={<LegacyRoute />}
            handle={{
              hideSecondaryContent: true,
            }}
          />
          <Route
            path=":workspaceSlug/user/:id/:tab?"
            element={<LegacyRoute />}
            handle={{
              hideSecondaryContent: true,
            }}
          />
          <Route
            path=":workspaceSlug/settings/general"
            element={<LegacyRoute />}
            handle={{
              hideSecondaryContent: true,
            }}
          />
          <Route
            path=":workspaceSlug/template/:id"
            element={<LegacyRoute />}
            handle={{
              hideSecondaryContent: true,
            }}
          />
        </Route>
        <Route
          path="discover"
          loader={({ request: { url } }) => {
            const { searchParams, search } = new URL(url);
            const filter = searchParams.get('filter');

            if (filter) {
              const otherParams = new URLSearchParams(searchParams);
              otherParams.delete('filter');
              if (filter === 'rewards') {
                const tab = otherParams.get('tab');
                if (tab) {
                  otherParams.set('tab', camelCase(tab));
                }
              }
              return redirect(`/a/${filter}?${otherParams.toString()}`);
            }
            return redirect(`/a${search}`);
          }}
        />
      </Route>
    ),
    [queryClient, planFeatures, isBlockInsightsEnabled]
  );

  const routeElements = useMemo(
    () =>
      createRoutesFromElements(
        <Route>
          {routeElementsWithoutPrefix}
          {loggedInRoutes}
        </Route>
      ),
    [loggedInRoutes, routeElementsWithoutPrefix]
  );

  const router = useMemo(
    () => wrapCreateBrowserRouter(createBrowserRouter)(routeElements),
    [routeElements]
  );

  return (
    <RouterProvider router={router} future={{ v7_startTransition: true }} />
  );
}
