import {
  cleanEditorState,
  generateCriteriaRules,
  logger,
  removeNewLinesAndTabs,
  sanitizeHtml,
  type SavePostAPIPayload,
  trackAdminAwardsEvent,
  trackParticipationAction,
  trackParticipationError,
  useEditPost,
  useGiveAwardMutation,
  useSuspenseUserDetails,
} from '@assembly-web/services';
import {
  Button,
  isFileUploadInProgress,
  LoadingSpinner,
  useToastStore,
} from '@assembly-web/ui';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { defineMessages, useIntl } from 'react-intl';

import { useAwardDetail, useCloseAwardsModal } from '../../Awards';
import { useTrackAction } from '../hooks/useTrackAction';

const messages = defineMessages({
  giveAwardLabel: {
    defaultMessage: 'Give award',
    id: 'tXj5MO',
  },
  save: {
    defaultMessage: 'Save',
    id: 'jvo0vs',
  },
  saving: {
    defaultMessage: 'Submitting...',
    id: 'txkW56',
  },
  success: {
    defaultMessage: 'Award given successfully',
    id: '9CTh80',
  },
  error: {
    defaultMessage: 'Something went wrong! Please try again later.',
    id: 'PPThGC',
  },
  editSuccessFull: {
    defaultMessage: 'Post Updated!',
    id: '2BW+sF',
  },
  editError: {
    defaultMessage: 'Sorry, error in editing. Please try again.',
    id: 'zqc0wD',
  },
  awardSubmitted: {
    defaultMessage: 'Award submitted for approval',
    id: 'I7Rl26',
  },
});

export function SubmitButton() {
  const { formatMessage } = useIntl();
  const closeHandler = useCloseAwardsModal();
  const awardDetail = useAwardDetail();
  const [disabled, setDisabled] = useState(false);
  const { trackAwardAction, trackAwardError } = useTrackAction();
  const { data: userDetails } = useSuspenseUserDetails();

  const { showSuccessToast, showErrorToast } = useToastStore();
  const { mutate: giveAward, isPending: isSubmitting } = useGiveAwardMutation({
    onSuccess: () => {
      if (awardDetail.settings?.autoApprove) {
        showSuccessToast(formatMessage(messages.success));
      } else {
        showSuccessToast(formatMessage(messages.awardSubmitted));
      }
      trackAwardAction('participationPosted', {
        pointsHidden:
          userDetails.assembly.settings.postPermission.canHidePoints.enabled,
      });
    },
    onError: (err, payload) => {
      showErrorToast(formatMessage(messages.error));
      const errorInfo = err instanceof Error ? err : undefined;

      logger.error(
        'Failed to give award',
        { ...payload, error: errorInfo },
        errorInfo
      );
      trackAwardError({
        errorType: 'createAwardPost',
        message: errorInfo?.message,
      });
      trackAdminAwardsEvent({
        errorType: errorInfo?.message,
      });
    },
    onSettled: () => {
      closeHandler();
    },
  });
  const { getValues, handleSubmit, watch, control } = useFormContext();

  useEffect(() => {
    return watch((values) => {
      if (values.attachments && values.attachments.length > 0) {
        const isInProgress = isFileUploadInProgress(values.attachments);
        setDisabled(isInProgress);
      }
    }).unsubscribe;
  }, [watch]);

  const { mutate: editPost, isPending: isSaving } = useEditPost({
    options: {
      onSuccess: () => {
        showSuccessToast(formatMessage(messages.editSuccessFull));
        trackParticipationAction('editPostSaved', {
          postType: 'award',
        });
      },
      onError: (err, payload) => {
        const errorInfo = err instanceof Error ? err : undefined;
        showErrorToast(formatMessage(messages.editError));

        logger.error('Failed to edit award post', payload, errorInfo);
        trackParticipationError({
          errorType: 'editAwardPost',
          message: errorInfo?.message,
        });
        trackAdminAwardsEvent({
          errorType: errorInfo?.message,
        });
      },
      onSettled: () => {
        closeHandler();
      },
    },
  });

  const getPayload = useCallback(() => {
    const {
      message: { html, json, plainText },
      points,
      coreValue,
      recipients,
      isPrivate,
      attachments,
    } = getValues();
    const groupedCriteria = generateCriteriaRules(recipients);
    const cleanedState = cleanEditorState({
      html,
      json,
      plainText,
    });
    return {
      message: removeNewLinesAndTabs(plainText),
      messageTokens: cleanedState.json,
      messageHtml: sanitizeHtml(cleanedState.html),
      pointsEach: Number(points) || 0,
      coreValue,
      isPrivate,
      recipient: {
        criteria: groupedCriteria,
      },
      attachments,
    } as SavePostAPIPayload;
  }, [getValues]);

  const onSubmit = useCallback(() => {
    if (awardDetail.awardPostId) {
      editPost({
        toServer: {
          payload: {
            ...getPayload(),
            postId: awardDetail.awardPostId,
          },
        },
        toCache: {
          recipient: control._defaultValues.recipients,
          postType: 'award',
        },
      });
      return;
    }
    giveAward({
      awardId: awardDetail.awardId,
      ...getPayload(),
    });
  }, [giveAward, getPayload, awardDetail, control, editPost]);

  return (
    <Button
      variation="primary"
      size="large"
      className="w-fit"
      isLoading={isSubmitting || isSaving}
      onClick={handleSubmit(onSubmit)}
      disabled={disabled}
    >
      {isSubmitting || isSaving ? (
        <>
          <LoadingSpinner />
          {formatMessage(messages.saving)}
        </>
      ) : (
        <>
          <CheckCircleIcon className="h-4 w-4" />
          {awardDetail.awardPostId
            ? formatMessage(messages.save)
            : formatMessage(messages.giveAwardLabel)}
        </>
      )}
    </Button>
  );
}
