import type { FolderColor } from '@assembly-web/services';
import type { ToolbarItem } from '@assembly-web/ui';
import {
  AssemblyLink,
  getFolderStyles,
  TextStyle,
  Toolbar,
  useDeviceInfo,
  useToolbarState,
  useTouchDevice,
} from '@assembly-web/ui';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import {
  Content,
  Root as CollapsibleRoot,
  Trigger,
} from '@radix-ui/react-collapsible';
import { motion } from 'framer-motion';
import type {
  FocusEventHandler,
  MouseEventHandler,
  ReactNode,
  RefObject,
} from 'react';
import { isValidElement } from 'react';
import { useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { messages } from './messages';

type URLTarget = { url: string };
type OnClickTarget = { onClick: () => void };

type CardProps = {
  cardId: string;
  children?: ReactNode;
  icon: ReactNode;
  text: string | TrustedHTML | ReactNode;
  subText?: string | TrustedHTML | ReactNode;
  connectedRight?: ReactNode;
  primaryToolbarItems?: ToolbarItem[];
  secondaryToolbarItems?: ToolbarItem[];
  onToolbarItemClick?: (args: ToolbarItem) => void;
  preview?: ReactNode;
  wrappedRef?:
    | RefObject<HTMLAnchorElement>
    | RefObject<HTMLDivElement>
    | RefObject<HTMLButtonElement>;
  detailsRef?: RefObject<HTMLDivElement>;
  onMenuToggle?: (isOpen: boolean) => void;
  collapsibleContent?: ReactNode;
  colorName?: FolderColor;
  target?: string;
  isCollectionItem?: boolean;
  isOpen?: boolean;
  archived?: boolean;
} & Partial<OnClickTarget> &
  Partial<URLTarget>;

type WrapperProps = {
  cardId: string;
  isActive: boolean;
  isOpen: boolean;
  isCollectionItem?: boolean;
  children: ReactNode;
  className?: string;
  onMouseOver: MouseEventHandler;
  onMouseLeave: MouseEventHandler;
  preview?: ReactNode;
  target: string;
  wrappedRef?:
    | RefObject<HTMLAnchorElement>
    | RefObject<HTMLDivElement>
    | RefObject<HTMLButtonElement>;
  collapsibleContent?: ReactNode;
  disableExitAnimation: boolean;
  onFocus: FocusEventHandler;
  onBlur: FocusEventHandler;
} & Partial<OnClickTarget> &
  Partial<URLTarget>;

const Wrapper = ({
  cardId,
  children,
  className,
  collapsibleContent,
  disableExitAnimation,
  isOpen,
  url,
  onClick,
  isActive,
  isCollectionItem,
  onMouseOver,
  onMouseLeave,
  preview,
  wrappedRef,
  target,
  onBlur,
  onFocus,
}: WrapperProps) => {
  const { deviceType } = useDeviceInfo();
  const touchDevice = useTouchDevice();

  const isMobile = deviceType === 'mobile';

  return (
    <motion.section
      role="presentation"
      key={cardId}
      className={twMerge(
        'relative rounded-2xl bg-gray-1 shadow-feed-post @container',
        isCollectionItem && 'last:pb-3'
      )}
      animate={{ height: 'auto', opacity: 1 }}
      exit={disableExitAnimation ? {} : { height: 0, opacity: 0 }}
      initial={false}
      transition={{
        duration: 0.6,
      }}
      onMouseLeave={onMouseLeave}
      onMouseOver={onMouseOver}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      {collapsibleContent ? (
        <CollapsibleRoot open={isOpen}>
          <div className="text-sm text-gray-9 transition-all">
            <Trigger asChild>
              <button
                className={twMerge(
                  !touchDevice &&
                    !isMobile &&
                    isActive &&
                    'rounded-lg bg-gray-3',
                  className
                )}
                onClick={() => onClick?.()}
                data-testid={`card-${cardId}`}
                ref={wrappedRef as RefObject<HTMLButtonElement>}
              >
                {children}
              </button>
            </Trigger>
            <Content>
              <div className="overflow-hidden rounded-b-2xl bg-gray-1 pl-9 pr-4">
                {collapsibleContent}
              </div>
            </Content>
          </div>
        </CollapsibleRoot>
      ) : (
        <>
          {url ? (
            <AssemblyLink
              to={url}
              className={className}
              onClick={() => onClick?.()}
              ref={wrappedRef as RefObject<HTMLAnchorElement>}
              target={target}
              data-testid={`card-${cardId}`}
            >
              {children}
            </AssemblyLink>
          ) : (
            <div
              className={twMerge(className, 'cursor-pointer')}
              onClick={() => onClick?.()}
              ref={wrappedRef as RefObject<HTMLDivElement>}
              data-testid={`card-${cardId}`}
              onKeyDown={(e) => {
                if (e.key === 'Space' || e.key === 'Enter') {
                  onClick?.();
                }
              }}
              role="button"
              tabIndex={0}
            >
              {children}
            </div>
          )}
          {preview}
        </>
      )}
    </motion.section>
  );
};

export function Card(props: CardProps) {
  const {
    cardId,
    url,
    icon,
    text,
    subText,
    onClick,
    connectedRight,
    onToolbarItemClick,
    primaryToolbarItems,
    secondaryToolbarItems,
    preview,
    wrappedRef,
    detailsRef,
    onMenuToggle,
    collapsibleContent,
    colorName,
    isCollectionItem,
    isOpen,
    target = '_self',
    archived,
  } = props;

  const isSubTextElement = isValidElement(subText);
  const { formatMessage } = useIntl();

  const { getContainerProps, getToolbarProps, isToolbarActive } =
    useToolbarState();

  return (
    <Wrapper
      url={url}
      onClick={onClick}
      preview={preview}
      isActive={isToolbarActive}
      wrappedRef={wrappedRef}
      isOpen={Boolean(isOpen)}
      isCollectionItem={isCollectionItem}
      collapsibleContent={collapsibleContent}
      className="block w-full text-left text-gray-9 max-md:px-2"
      target={target}
      cardId={cardId}
      disableExitAnimation={!colorName} // having a color signifies a collection or collection item
      {...getContainerProps()}
    >
      <div
        className="group grid w-full grid-cols-[max-content_minmax(0,1fr)_max-content_max-content] gap-x-2 px-4 py-2 text-left text-gray-9 focus-visible:rounded-2xl max-md:px-2"
        ref={detailsRef}
      >
        <div
          className={twMerge(
            'col-start-1 row-start-1 flex flex-row items-center justify-center',
            !isCollectionItem && 'px-1',
            archived && 'grayscale'
          )}
        >
          {isCollectionItem && colorName ? (
            <div
              className={twMerge(
                'mr-1.5 h-6 w-1 shrink-0 rounded',
                getFolderStyles(colorName).background
              )}
            />
          ) : null}
          {icon}
        </div>

        <div className="relative col-start-2 flex items-center py-1">
          {!isCollectionItem && colorName ? (
            isValidElement(text) ? (
              text
            ) : (
              <TextStyle
                variant="base-medium"
                className={twMerge(
                  'truncate rounded px-2',
                  getFolderStyles(colorName).background
                )}
                html={text as string | TrustedHTML}
              />
            )
          ) : (
            <>
              {isValidElement(text) ? (
                text
              ) : (
                <TextStyle
                  className={twMerge(
                    'truncate',
                    archived && 'text-gray-8 line-through'
                  )}
                  html={text as string | TrustedHTML}
                />
              )}
              {Boolean(archived) && (
                <TextStyle
                  html={`- ${formatMessage(messages.archivedText)}`}
                  className="mx-2 text-gray-8"
                />
              )}
            </>
          )}
          {Boolean(collapsibleContent) && (
            <ChevronDownIcon
              className={twMerge(
                'ml-2 inline h-4 w-4',
                isOpen && 'rotate-180 transform'
              )}
            />
          )}
        </div>

        {subText ? (
          isSubTextElement ? (
            subText
          ) : (
            <TextStyle
              className="col-start-2 -mt-1 line-clamp-2"
              variant="sm-regular"
              html={
                typeof text === 'number' || typeof text === 'boolean'
                  ? text.toString()
                  : (text as string | TrustedHTML)
              }
              subdued
            />
          )
        ) : null}

        <div
          className={twMerge(
            'col-start-3 row-start-1 row-end-4 h-[34px]',
            !isToolbarActive && 'max-md:hidden'
          )}
        >
          {onToolbarItemClick &&
          secondaryToolbarItems &&
          secondaryToolbarItems.length > 0 ? (
            <Toolbar
              {...getToolbarProps({
                onMenuItemClick(args) {
                  onToolbarItemClick(args);
                },
                onToggle: onMenuToggle,
              })}
              primaryToolbarItems={primaryToolbarItems}
              secondaryToolbarItems={secondaryToolbarItems}
            />
          ) : null}
        </div>
        {Boolean(connectedRight) && (
          <div className="col-start-4 row-start-1 row-end-4 justify-end max-sm:col-start-2 max-sm:row-start-auto max-sm:row-end-auto">
            {connectedRight}
          </div>
        )}
      </div>
    </Wrapper>
  );
}
