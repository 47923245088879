import { isTruthy } from '@assembly-web/services';
import {
  AwardsModal,
  GiveAwardLoader,
  useOpenEndedFileUpload,
} from '@assembly-web/ui';
import type { ElementRef, PropsWithChildren } from 'react';
import {
  createContext,
  Suspense,
  useCallback,
  useContext,
  useState,
} from 'react';
import invariant from 'tiny-invariant';

import { GiveAwardContainer } from './GiveAward/GiveAward';
import { SelectAward } from './SelectAward';
import type {
  AwardTriggerProps,
  GiveAwardForm,
  SelectedAwardDetail,
} from './types';

const AwardsModalContext = createContext<AwardTriggerProps | null>(null);
const AwardDetailContext = createContext<SelectedAwardDetail>({
  setAwardSettings: () => {},
  settings: null,
  defaultValue: null,
  setDefaultValue: () => {},
  awardId: '',
  setAward: () => {},
  awardPostId: '',
});
const AwardFooterRefContext = createContext<ElementRef<'div'> | null>(null);
const AwardCloseModalContext = createContext<() => void>(() => {});

export const useAwardDetail = () => {
  return useContext(AwardDetailContext);
};

export const useAwardFooterRef = () => {
  return useContext(AwardFooterRefContext);
};

export const useOpenAwardsModal = () => {
  const awardTriggerProps = useContext(AwardsModalContext);

  invariant(
    awardTriggerProps,
    'useOpenAwardsModal must be used within an Awards component'
  );

  return useCallback(
    (awardPostId?: string) => {
      awardTriggerProps.openModal(true);
      if (awardPostId) {
        awardTriggerProps.setAwardPost(awardPostId);
      }
    },
    [awardTriggerProps]
  );
};

export const useCloseAwardsModal = () => {
  const close = useContext(AwardCloseModalContext);

  invariant(
    close,
    'useCloseAwardsModal must be used within an Awards component'
  );

  return close;
};

export function Awards({ children }: PropsWithChildren) {
  const [footerRef, setFooterRef] = useState<ElementRef<'div'> | null>(null);
  const [open, setIsOpen] = useState(false);
  const [award, setAward] = useState<string | null>(null);
  const [defaultValue, setDefaultValue] = useState<GiveAwardForm | null>(null);
  const [id, setId] = useState<string>(crypto.randomUUID());
  const [awardPost, setAwardPost] = useState<string | null>(null);
  const [awardSettings, setAwardSettings] = useState<
    SelectedAwardDetail['settings'] | null
  >(null);
  const { fileUploadOptions } = useOpenEndedFileUpload({
    key: 'give-awards',
  });
  const reset = useCallback(() => setId(crypto.randomUUID()), []);

  const resetForm = useCallback(() => {
    setAwardSettings(null);
    setDefaultValue(null);
    reset();
  }, [reset]);

  const handleClose = useCallback(() => {
    setAward(null);
    setIsOpen(false);
    setAwardPost(null);
    resetForm();
    fileUploadOptions.clear();
  }, [fileUploadOptions, resetForm]);

  const openModal = useCallback(() => {
    setIsOpen(true);
    resetForm();
  }, [setIsOpen, resetForm]);

  return (
    <AwardsModalContext.Provider value={{ openModal, setAwardPost }}>
      <AwardCloseModalContext.Provider value={handleClose}>
        {children}
        <AwardDetailContext.Provider
          value={{
            setAwardSettings,
            settings: awardSettings,
            defaultValue,
            setDefaultValue,
            awardId: award || '',
            setAward,
            awardPostId: awardPost || '',
          }}
        >
          <AwardFooterRefContext.Provider value={footerRef}>
            <AwardsModal
              isOpen={open}
              onClose={handleClose}
              title={awardSettings?.name || ''}
              ctaSection={<div className="w-full" ref={setFooterRef} />}
              key={id}
            >
              {isTruthy(awardPost || award) ? (
                <Suspense fallback={<GiveAwardLoader />}>
                  <GiveAwardContainer />
                </Suspense>
              ) : (
                <SelectAward />
              )}
            </AwardsModal>
          </AwardFooterRefContext.Provider>
        </AwardDetailContext.Provider>
      </AwardCloseModalContext.Provider>
    </AwardsModalContext.Provider>
  );
}
