import { EditBanner } from '@assembly-web/ui';

import { useAwardDetail } from '../../Awards';

export function EditBadge() {
  const { awardPostId, settings } = useAwardDetail();
  return (
    <EditBanner
      show={Boolean(awardPostId)}
      isCoreValueEditable={Boolean(!settings?.coreValue)}
    />
  );
}
