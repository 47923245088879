import {
  APIEndpoints,
  assemblyAPI,
  type ListWorkspacesAPIResponse,
  type Workspace,
} from '@assembly-web/services';
import {
  queryOptions,
  useQuery,
  type UseQueryOptions,
  useSuspenseQuery,
} from '@tanstack/react-query';

export const getWorkspaceQueryOptions = <T = { workspaceIDs: string[] }>({
  select = ({ allowList, invited, partOf }) => {
    const workspaceIDs = new Set<Workspace['assemblyId']>();
    allowList.forEach((workspace) => workspaceIDs.add(workspace.assemblyId));
    invited.forEach((workspace) => workspaceIDs.add(workspace.assemblyId));
    partOf.forEach((workspace) => workspaceIDs.add(workspace.assemblyId));

    return { workspaceIDs: Array.from(workspaceIDs) } as T;
  },
  ...options
}: Omit<
  UseQueryOptions<ListWorkspacesAPIResponse, Error, T>,
  'queryKey' | 'queryFn'
> = {}) =>
  queryOptions({
    ...options,
    queryKey: ['workspaces'],
    queryFn: async () => {
      const { data } = await assemblyAPI.post<ListWorkspacesAPIResponse>(
        APIEndpoints.listWorkspaces
      );

      return data;
    },
    select,
  });

export function useWorkspaceQuery(
  { enabled }: { enabled: boolean } | undefined = { enabled: true }
) {
  return useQuery({
    ...getWorkspaceQueryOptions({ enabled }),
    enabled,
  });
}

export function useWorkspace() {
  return useSuspenseQuery(getWorkspaceQueryOptions());
}
