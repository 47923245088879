import type {
  AxomoShippingCountryAPIResponse,
  AxomoShippingStateAPIResponse,
  AxomoStoreInfoAPIResponse,
  AxomoSwagAPIResponse,
} from '@assembly-web/services';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

import {
  getAxomoShippingCountriesQuery,
  getAxomoShippingStatesQuery,
  getAxomoStoreInfo,
  getSwagQuery,
} from '../../queries/getSwagQuery';
import { useRedeemRewardDetailsQuery } from '../useRedeemRewardDetailsQuery';

export function useGetSwagDetailsQuery(
  swagId: string,
  storeId: string,
  options?: Partial<UseQueryOptions<AxomoSwagAPIResponse>>
) {
  return useQuery<AxomoSwagAPIResponse>(getSwagQuery(swagId, storeId, options));
}

export function useAxomoShippingCountriesQuery(
  options?: Partial<UseQueryOptions<AxomoShippingCountryAPIResponse[]>>
) {
  return useQuery<AxomoShippingCountryAPIResponse[]>(
    getAxomoShippingCountriesQuery(options)
  );
}

export function useAxomoStateFromCountryCodeQuery(countryCode: string) {
  return useQuery<AxomoShippingStateAPIResponse>(
    getAxomoShippingStatesQuery(countryCode, Boolean(countryCode))
  );
}

export function useAxomoStoreInfoQuery(
  options?: Partial<UseQueryOptions<AxomoStoreInfoAPIResponse>>
) {
  const [{ data, status, fetchStatus }] = useRedeemRewardDetailsQuery();
  return useQuery<AxomoStoreInfoAPIResponse>(
    getAxomoStoreInfo({
      ...options,
      enabled:
        (options?.enabled ?? true) &&
        status !== 'pending' &&
        fetchStatus !== 'fetching' &&
        data?.hasSwags,
    })
  );
}
