import {
  queryOptions,
  useQuery,
  type UseQueryOptions,
} from '@tanstack/react-query';

import { APIEndpoints } from '../../../../../../packages/services/lib/APIEndpoints';
import { assemblyAPI } from '../../../../../../packages/services/lib/assemblyAPI';
import type { BlockInsightsParams, BlockInsightsResponse } from '../types';

const getBlockInsightsQueryOptions = ({
  options,
  params,
}: {
  params: BlockInsightsParams;
  options?: Partial<UseQueryOptions<BlockInsightsResponse>>;
}) => {
  return queryOptions({
    ...options,
    queryKey: ['blockInsights', params],
    staleTime: 1000 * 60 * 3, // 3 minutes
    queryFn: async () => {
      const response = await assemblyAPI.get<BlockInsightsResponse>(
        APIEndpoints.getBlockInsights,
        {
          params,
        }
      );
      return response.data;
    },
  });
};

export function useGetBlockInsights({
  options,
  params,
}: {
  params: BlockInsightsParams;
  options?: Partial<UseQueryOptions<BlockInsightsResponse>>;
}) {
  return useQuery(getBlockInsightsQueryOptions({ params, options }));
}
