import { TextStyle, Tooltip } from '@assembly-web/ui';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

type MetricCardProps = {
  title: string;
  value: string | number;
  tooltipText?: string;
};

export function MetricCard({ title, value, tooltipText }: MetricCardProps) {
  return (
    <div className="flex flex-col items-center p-4">
      <div className="flex items-center gap-1">
        <TextStyle variant="lg-regular" as="span">
          {title}
        </TextStyle>
        {Boolean(tooltipText) && (
          <Tooltip tooltipText={tooltipText} showArrow>
            <InformationCircleIcon className="h-4 w-4 text-gray-6" />
          </Tooltip>
        )}
      </div>
      <TextStyle variant="3xl-regular" as="span">
        {value}
      </TextStyle>
    </div>
  );
}
