import { CollapsibleContent, QuickParticipation } from '@assembly-web/ui';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

export function DescriptionBlock() {
  const { control } = useFormContext();
  return (
    <CollapsibleContent
      content={control._defaultValues.description.message ?? ''}
    />
  );
}

export function Description() {
  const { control } = useFormContext();
  return (
    control._defaultValues.description?.message && (
      <div className="flex w-1/2 flex-col gap-2">
        <QuickParticipation.Label>
          <FormattedMessage defaultMessage="Description" id="Q8Qw5B" />
        </QuickParticipation.Label>
        <DescriptionBlock />
      </div>
    )
  );
}
