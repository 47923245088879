import {
  defaultCurrencyIcon,
  mapHexCodeToEmoticon,
} from '@assembly-web/services';
import {
  Button,
  LoadingSpinner,
  RadioGroup,
  useToastStore,
  V3Modal,
} from '@assembly-web/ui';
import { useContext, useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { z } from 'zod';

import { useGetPostAndRepliesDetails } from '../../../../hooks/useGetPostAndRepliesDetails';
import { useMultiDrawerStore } from '../../../../stores/useMultiDrawerStore';
import { ModalsContext } from '../../contexts/ModalsContext';
import { useDeleteReplyMutation } from '../../hooks/replies/useRepliesQueries';
import { useArchiveFlowPostMutation } from '../../hooks/useArchiveFlowPostMutation';
import { useArchiveRecognitionPostMutation } from '../../hooks/useArchiveRecognitionPostMutation';
import { isAdminMember } from '../../services/member';

const DELETE_POST_MODAL_MESSAGE = {
  OPEN_DELETE_POST_MODAL: 'OPEN_DELETE_POST_MODAL',
} as const;

const OpenDeletePostModalPayload = z.object({
  flowId: z.string(),
  replyId: z.string().nullable(),
  responseId: z.string(),
  hasPoints: z.boolean().optional(),
});

const messages = defineMessages({
  deleteReply: {
    defaultMessage: 'Delete reply?',
    id: 'boSaWS',
  },
  cancel: {
    defaultMessage: 'Cancel',
    id: '47FYwb',
  },
  delete: {
    defaultMessage: 'Delete',
    id: 'K3r6DQ',
  },
  deleteReplyContent: {
    defaultMessage:
      'When you delete this reply, it will disappear from the post. You will not be able to get it back.',
    id: 'E90C4r',
  },
  replyDeletedSuccessfully: {
    defaultMessage: 'Reply deleted successfully',
    id: 'Zn1RcZ',
  },
  replyFailedToDelete: {
    defaultMessage: 'Reply failed to delete. Please try again.',
    id: 'UAyFIF',
  },
  deletePostTitle: {
    defaultMessage: 'Delete post?',
    id: 'U3gQRc',
  },
  deletePostContent: {
    defaultMessage:
      'When you delete this post, it will disappear from the feed and all of your answers will be erased. You will be able to re-fill out this flow if the occurrence is still running, or if you can answer anytime.',
    id: 'QEOqKh',
  },
  deletePostWithPointsTitle: {
    defaultMessage: 'Delete post and {currencies}',
    id: 'za2ZMa',
  },
  deletePostWithPointsAdminContent: {
    defaultMessage:
      '<p>If you delete this post, it will no longer appear in the feed.</p> <p>{pointsWithCurrency} were given in this post — what would you like to do with them?</p>',
    id: 'EwcE2d',
  },
  deletePostWithPointsContent: {
    defaultMessage:
      '<p>When you delete this post, it will disappear from the feed and all of your answers will be erased.</p> <p>The {pointsWithCurrency} given in this post will be removed from the recipient(s) earned balance and returned to your giving balance.</p>',
    id: 'Hp38L7',
  },
  givePointsBack: {
    defaultMessage:
      "Return {currencies} back to giver, remove from recipient's balance",
    id: 'jvtbWC',
  },
  givePointsBackBotPost: {
    defaultMessage: "Remove {currencies} from recipient's balance",
    id: '/IId3c',
  },
  allowReceiverToKeepPoints: {
    defaultMessage: 'Allow the recipient to keep the {currencies}',
    id: 'iqJyVW',
  },
  selectAnOption: {
    defaultMessage: 'Select an option',
    id: 'tGAYL2',
  },
  postSuccessfullyDeleted: {
    defaultMessage: 'Post successfully deleted',
    id: 'jvikiW',
  },
  postFailedToDelete: {
    defaultMessage: 'Post failed to delete. Please try again.',
    id: 'RLYN8A',
  },
});

export function DeletePostOrReplyModal() {
  const { formatMessage } = useIntl();
  const { showSuccessToast, showErrorToast } = useToastStore();
  const {
    selectedPostOrReplyToDeleteDetails,
    onPostOrDeleteModalClose,
    onDeleteReplyOrPostClick,
  } = useContext(ModalsContext);

  const {
    flowId,
    replyId,
    responseId,
    hasPoints,
    challengeId,
    isLegacyReply,
    onReplyDeleteSuccess,
    onEditReplyCancel,
    noOfPoints,
    isBotPost,
  } = selectedPostOrReplyToDeleteDetails;

  const deleteDrawer = useMultiDrawerStore((store) => store.deleteDrawer);
  const closeDrawer = useMultiDrawerStore((store) => store.closeDrawer);

  const { currentUser } = useGetPostAndRepliesDetails({
    flowId,
    responseId,
    enabled: Boolean(flowId),
  });

  const [selectedOption, setSelectedOption] = useState<
    | {
        id: string;
        value: string;
        displayValue: string;
      }
    | undefined
  >(undefined);

  const handleDeletePostOrModalClose = () => {
    onPostOrDeleteModalClose();
    setSelectedOption(undefined);
  };

  const handleCloseButtonClick = () => {
    onEditReplyCancel?.();
    handleDeletePostOrModalClose();
  };

  const handleSuccessCallback = () => {
    showSuccessToast(
      formatMessage(
        replyId
          ? messages.replyDeletedSuccessfully
          : messages.postSuccessfullyDeleted
      )
    );
    onReplyDeleteSuccess?.();
    handleDeletePostOrModalClose();
    if (flowId && responseId && !replyId) {
      const postDrawerId =
        flowId === 'recognition' ? responseId : `${flowId}-${responseId}`;
      closeDrawer(postDrawerId);
      deleteDrawer(postDrawerId);
    }
  };
  const handleErrorCallback = () => {
    showErrorToast(
      formatMessage(
        replyId ? messages.replyFailedToDelete : messages.postFailedToDelete
      )
    );
    handleDeletePostOrModalClose();
  };

  const { mutate: deleteReply, isPending: isDeletingReply } =
    useDeleteReplyMutation(handleSuccessCallback, handleErrorCallback);

  const { mutate: deleteFlowPost, isPending: isDeletingFlowPost } =
    useArchiveFlowPostMutation(handleSuccessCallback, handleErrorCallback);

  const { mutate: deleteRecognitionPost, isPending: isDeletingLegacyPost } =
    useArchiveRecognitionPostMutation(
      handleSuccessCallback,
      handleErrorCallback
    );

  const isLoading =
    isDeletingReply || isDeletingFlowPost || isDeletingLegacyPost;

  const hasAdminPerms = isAdminMember(currentUser.member);

  const handleDeleteClick = () => {
    if (challengeId && replyId) {
      deleteReply({
        replyId,
        challengeId,
        returnPoints: selectedOption?.id === 'give-points-back',
      });
    } else if (replyId) {
      deleteReply({
        replyId,
        flowId,
        responseId,
        isLegacyReply,
        returnPoints: selectedOption?.id === 'give-points-back',
      });
    } else {
      if (flowId === 'recognition') {
        deleteRecognitionPost({
          postId: responseId,
          returnPoints: hasAdminPerms
            ? selectedOption?.id === 'give-points-back'
            : true,
        });
      } else {
        deleteFlowPost({
          flowId,
          responseId,
          returnPoints: hasAdminPerms
            ? selectedOption?.id === 'give-points-back'
            : true,
        });
      }
    }
  };

  const getTitleMessage = () => {
    if (replyId) {
      return messages.deleteReply;
    } else {
      return hasPoints
        ? messages.deletePostWithPointsTitle
        : messages.deletePostTitle;
    }
  };

  const getContentMessage = () => {
    if (replyId) {
      return messages.deleteReplyContent;
    } else {
      return hasPoints
        ? hasAdminPerms
          ? messages.deletePostWithPointsAdminContent
          : messages.deletePostWithPointsContent
        : messages.deletePostContent;
    }
  };

  const currencyValue = currentUser.assembly.currency.value;
  const icon = mapHexCodeToEmoticon(
    currencyValue ? currencyValue : defaultCurrencyIcon
  );
  const currencies = `${icon} ${currentUser.assembly.currency.pluralName}`;

  const pointsWithCurrency = `${icon} ${noOfPoints} ${
    noOfPoints === 1
      ? currentUser.assembly.currency.name
      : currentUser.assembly.currency.pluralName
  }`;

  const returnPointsOptions = [
    {
      id: 'give-points-back',
      value: formatMessage(
        isBotPost ? messages.givePointsBackBotPost : messages.givePointsBack,
        { currencies }
      ),
      displayValue: formatMessage(
        isBotPost ? messages.givePointsBackBotPost : messages.givePointsBack,
        { currencies }
      ),
    },
    {
      id: 'allow-receiver-to-keep-points',
      value: formatMessage(messages.allowReceiverToKeepPoints, {
        currencies,
      }),
      displayValue: formatMessage(messages.allowReceiverToKeepPoints, {
        currencies,
      }),
    },
  ];

  useEffect(() => {
    function handleMessage({
      data: { type, payload },
    }: MessageEvent<{
      type: keyof typeof DELETE_POST_MODAL_MESSAGE;
      payload: unknown;
    }>) {
      switch (type) {
        case DELETE_POST_MODAL_MESSAGE.OPEN_DELETE_POST_MODAL: {
          const openDeletePostModalPayload =
            OpenDeletePostModalPayload.safeParse(payload);
          if (!openDeletePostModalPayload.success) return;
          onDeleteReplyOrPostClick(openDeletePostModalPayload.data);
          break;
        }
        default: {
          return;
        }
      }
    }

    window.addEventListener('message', handleMessage);

    return () => window.removeEventListener('message', handleMessage);
  }, [onDeleteReplyOrPostClick]);

  return (
    <V3Modal
      closeButtonSize="large"
      isOpen={Boolean(replyId) || Boolean(flowId)}
      onClose={handleCloseButtonClick}
      headerClassName="items-center"
      title={formatMessage(getTitleMessage(), {
        currencies,
      })}
      ctaSection={
        <div className="flex w-full justify-between gap-2">
          <Button
            type="button"
            onClick={handleDeletePostOrModalClose}
            variation="secondaryLite"
            className="flex grow"
          >
            {formatMessage(messages.cancel)}
          </Button>
          <Button
            type="button"
            onClick={handleDeleteClick}
            variation="danger"
            className="flex grow"
            disabled={
              Boolean(hasPoints && hasAdminPerms) &&
              selectedOption === undefined
            }
          >
            {Boolean(isLoading) && (
              <LoadingSpinner className="text-gray-1 dark:text-primary-9" />
            )}
            {formatMessage(messages.delete)}
          </Button>
        </div>
      }
    >
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-4">
          {formatMessage(getContentMessage(), {
            currencies,
            pointsWithCurrency,
            p: (chunks) => <p>{chunks}</p>,
          })}
        </div>
        {Boolean(hasPoints && hasAdminPerms) && (
          <RadioGroup.Root
            className="p-2"
            value={selectedOption?.id}
            onValueChange={(value) => {
              setSelectedOption(
                returnPointsOptions.find((option) => option.id === value)
              );
            }}
          >
            <RadioGroup.Item
              value={returnPointsOptions[0].id}
              className="h-4 w-4"
            >
              <div className="text-center font-normal text-gray-9">
                {returnPointsOptions[0].displayValue}
              </div>
            </RadioGroup.Item>
            <RadioGroup.Item
              value={returnPointsOptions[1].id}
              className="h-4 w-4"
            >
              <div className="text-center font-normal text-gray-9">
                {returnPointsOptions[1].displayValue}
              </div>
            </RadioGroup.Item>
          </RadioGroup.Root>
        )}
      </div>
    </V3Modal>
  );
}
