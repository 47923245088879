import { useFetchRecognitionDraft, useGetPost } from '@assembly-web/services';
import { useEffect, useMemo, useRef } from 'react';

import { useRecognitionForm } from '../../hooks/useRecognitionForm';
import { useParticipationModalContext } from '../../Provider';

function EditComponent() {
  const { formState, setDefaultForm, reset } = useParticipationModalContext();
  const { getPostData } = useRecognitionForm();
  const defaultValueRef = useRef(false);
  const { data: postData } = useGetPost({
    postId: formState?.postId || '',
  });

  useEffect(() => {
    if (defaultValueRef.current) {
      return;
    }
    defaultValueRef.current = true;
    setDefaultForm(getPostData(postData.post));
    reset();
  }, [postData, setDefaultForm, getPostData, reset]);

  return null;
}

function DraftComponent() {
  const { setDefaultForm, reset } = useParticipationModalContext();
  const { getDraftData } = useRecognitionForm();
  const defaultValueRef = useRef(false);

  const { data } = useFetchRecognitionDraft();

  const draft = useMemo(
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    () => (data.draft && 'postData' in data.draft ? data.draft.postData : {}),
    [data.draft]
  );

  useEffect(() => {
    if (defaultValueRef.current) {
      return;
    }
    defaultValueRef.current = true;
    setDefaultForm(getDraftData(draft));
    reset();
  }, [draft, setDefaultForm, getDraftData, reset]);

  return null;
}

export function ParticipationWrapper() {
  const { formState } = useParticipationModalContext();
  const isEditFlow = Boolean(formState?.postId);

  return <>{isEditFlow ? <EditComponent /> : <DraftComponent />}</>;
}
