import { useNavigate } from 'react-router-dom';

import {
  generatePostDrawerPayload,
  removeFlowOrPostParamsFromUrl,
} from '../modules/discover/services/common';
import { useMultiDrawerStore } from '../stores/useMultiDrawerStore';
import type { PostData } from '../types/postsAndReplies';

export function usePosts() {
  const navigate = useNavigate();
  const createDrawer = useMultiDrawerStore((store) => store.createDrawer);
  const handlePostClicked = (postData: PostData) => {
    const createDrawerPayload = generatePostDrawerPayload({
      ...postData,
      flowId: postData.type === 'flow' ? postData.flowId : null,
      postId: postData.type === 'post' ? postData.postId : null,
      responseId: postData.type === 'flow' ? postData.responseId : null,
      commentId: postData.commentId ?? null,
      onClose: async () => {
        if (location.pathname.includes('/discover')) {
          const updatedUrl = removeFlowOrPostParamsFromUrl();
          await navigate(updatedUrl, { replace: true });
        }
      },
    });
    createDrawer(createDrawerPayload);
  };

  return { onPostClicked: handlePostClicked };
}
