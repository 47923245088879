import { LoadingSpinner } from '@assembly-web/ui';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { useIsDynamicTranslationsAvailable } from '../hooks/useIsDynamicTranslationsAvailable';

const messages = defineMessages({
  translateContent: {
    defaultMessage: 'See translation',
    id: 'Cy9aS4',
  },
  loading: {
    defaultMessage: 'Translating',
    id: 'tUlsq+',
  },
  showOriginalContent: {
    defaultMessage: 'See original',
    id: 'PqR5hW',
  },
});

export function ContentTranslationButton({
  variant = 'default',
  isTranslationInProgress,
  isShowingOriginalContent,
  onClick,
}: {
  variant?: 'compact' | 'default';
  isTranslationInProgress: boolean;
  isShowingOriginalContent: boolean;
  onClick: () => void;
}) {
  const { formatMessage } = useIntl();

  const isDynamicTranslationsAvailable = useIsDynamicTranslationsAvailable();

  if (!isDynamicTranslationsAvailable) {
    return null;
  }

  return (
    <>
      <span>•</span>
      <button
        className={twMerge(
          'flex cursor-pointer items-center gap-1 text-sm text-gray-8',
          variant === 'compact' && 'text-xs'
        )}
        disabled={isTranslationInProgress}
        onClick={onClick}
      >
        {Boolean(isTranslationInProgress) && (
          <LoadingSpinner className="size-4" />
        )}
        {formatMessage(
          isTranslationInProgress
            ? messages.loading
            : isShowingOriginalContent
              ? messages.translateContent
              : messages.showOriginalContent
        )}
      </button>
    </>
  );
}
