import { type AwardPostResponse, useGetPost } from '@assembly-web/services';
import { GiveAwardLoader, QuickParticipation } from '@assembly-web/ui';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { useAwardDetail } from '../Awards';
import { getAwardDetailData, getAwardPostData } from '../utils';
import { ApprovalProcessBanner } from './components/ApprovalProcessBanner';
import { AwardForm } from './components/AwardForm';
import { AwardInfo } from './components/AwardInfo';
import { EditBadge } from './components/EditBadge';
import { Footer } from './components/Footer';
import { useGetAward } from './hooks/useGetAward';
import { useTrackAction } from './hooks/useTrackAction';

function CreateAwardPost() {
  const { data } = useGetAward();
  const { setDefaultValue, setAwardSettings } = useAwardDetail();

  useEffect(() => {
    setDefaultValue(getAwardDetailData(data));
  }, [data, setDefaultValue]);

  useEffect(() => {
    setAwardSettings({
      autoApprove: data.approvals.autoApprove,
      coreValue: data.post.coreValue,
      name: data.name,
      budget: data.budget,
    });
  }, [data, setAwardSettings]);

  return null;
}

function EditAwardPost() {
  const { setDefaultValue, setAwardSettings, awardPostId } = useAwardDetail();

  const { data: postData } = useGetPost({
    postId: awardPostId || '',
  });

  useEffect(() => {
    setDefaultValue(getAwardPostData(postData as AwardPostResponse));
  }, [postData, setDefaultValue]);

  useEffect(() => {
    if (postData.post.type === 'award') {
      setAwardSettings({
        autoApprove: true, // award approved and posted
        coreValue: postData.post.award.coreValue,
        name: postData.post.award.name,
      });
    }
  }, [postData, setAwardSettings]);

  return null;
}

export function GiveAwardContainer() {
  const awardDetails = useAwardDetail();
  return (
    <>
      {awardDetails.awardPostId ? <EditAwardPost /> : <CreateAwardPost />}
      {awardDetails.defaultValue ? <GiveAward /> : <GiveAwardLoader />}
    </>
  );
}

export function GiveAward() {
  const awardDetails = useAwardDetail();
  const { trackAwardAction } = useTrackAction();

  const methods = useForm({
    reValidateMode: 'onChange',
    defaultValues: awardDetails.defaultValue || {},
  });

  useEffect(() => {
    trackAwardAction('participationStarted');
  }, [trackAwardAction]);

  return (
    <FormProvider {...methods}>
      <QuickParticipation.BlockList>
        <EditBadge />
        <AwardInfo />
        <AwardForm />
        {!awardDetails.settings?.autoApprove && <ApprovalProcessBanner />}
      </QuickParticipation.BlockList>
      <Footer />
    </FormProvider>
  );
}
