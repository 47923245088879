import { defaultAwardImage } from '@assembly-web/assets';
import { useFormContext } from 'react-hook-form';
import { defineMessage, useIntl } from 'react-intl';

import { Description } from './Description';

const altLabel = defineMessage({
  defaultMessage: 'Default Award image',
  id: 'fjU1fT',
});

function Image() {
  const { formatMessage } = useIntl();
  const { control } = useFormContext();
  return (
    <img
      src={
        control._defaultValues.image
          ? control._defaultValues.image.location
          : defaultAwardImage
      }
      alt={
        control._defaultValues.image
          ? control._defaultValues.image.name
          : formatMessage(altLabel)
      }
      className="aspect-video h-fit w-1/2 rounded-2xl border-0 object-cover"
    />
  );
}

export function AwardInfo() {
  return (
    <div className="flex gap-4">
      <Description />
      <Image />
    </div>
  );
}
