import {
  trackParticipationShow,
  useDebounce,
  useGetAwardsCatalog,
  useSuspenseUserDetails,
} from '@assembly-web/services';
import {
  AwardsList,
  Button,
  Portal,
  QuickParticipation,
  SelectAwardLoader,
  TextField,
  TextStyle,
} from '@assembly-web/ui';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { type ChangeEvent, useEffect, useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import {
  useAwardDetail,
  useAwardFooterRef,
  useCloseAwardsModal,
} from './Awards';

const messages = defineMessages({
  cancel: {
    defaultMessage: 'Cancel',
    id: '47FYwb',
  },
  next: {
    defaultMessage: 'Next',
    id: '9+Ddtu',
  },
  selectTitle: {
    defaultMessage: 'Select Award:',
    id: 'zqPfrA',
  },
  searchAward: {
    defaultMessage: 'Search Award',
    id: 'QNePl1',
  },
  noAwards: {
    defaultMessage: 'No awards found for this search',
    id: 'hHUZif',
  },
});
export function SelectAward() {
  const { formatMessage } = useIntl();
  const { data: userDetails } = useSuspenseUserDetails();

  const closeHandler = useCloseAwardsModal();

  const [selectedAward, setSelectedAward] = useState<string | undefined>();
  const { setAward } = useAwardDetail();

  const [search, setSearch] = useState<string>('');
  const debouncedAwardSearch = useDebounce(search, 500);

  const { data, isPending } = useGetAwardsCatalog({
    payload: {
      search: debouncedAwardSearch,
    },
  });

  const showSearch = useMemo(() => {
    return debouncedAwardSearch || (data?.total && data.total > 10);
  }, [data?.total, debouncedAwardSearch]);

  useEffect(() => {
    trackParticipationShow({
      postType: 'award',
    });
  }, []);

  return (
    <>
      <QuickParticipation.BlockList>
        <TextStyle variant="lg-medium" as="span">
          {formatMessage(messages.selectTitle)}
        </TextStyle>
        {Boolean(showSearch) && (
          <TextField
            type="text"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setSearch(e.target.value);
            }}
            connectedLeft={
              <MagnifyingGlassIcon className="h-4 w-4 text-gray-9" />
            }
            placeholder={formatMessage(messages.searchAward)}
            value={search}
            data-focus-on-first-mount
          />
        )}
        {isPending ? (
          <SelectAwardLoader />
        ) : (
          <>
            {data?.total && data.total > 0 ? (
              <AwardsList
                selectedAwardId={selectedAward}
                awards={data.data}
                onChange={(id) => setSelectedAward(id)}
                timeZone={userDetails.assembly.timeZone}
              />
            ) : (
              <TextStyle variant="sm-regular">
                {formatMessage(messages.noAwards)}
              </TextStyle>
            )}
          </>
        )}
      </QuickParticipation.BlockList>
      <Portal asChild container={useAwardFooterRef()}>
        <div className="flex w-full gap-6">
          <Button
            variation="secondaryLite"
            size="large"
            className="w-full"
            onClick={closeHandler}
          >
            {formatMessage(messages.cancel)}
          </Button>
          <Button
            variation="primary"
            size="large"
            onClick={() => selectedAward && setAward(selectedAward)}
            className="w-full"
            disabled={!selectedAward}
          >
            {formatMessage(messages.next)}
          </Button>
        </div>
      </Portal>
    </>
  );
}
